import React from "react";
import styles from "./testimonialsB.module.scss";

function TestimonialsB({ design, padding, Testimonials }) {
  return (
    <div
      className={`container-fluid ${!!padding ? styles.testPadding : ""} ${
        styles.testmonials
      } d-flex justify-content-center`}
    >
      <div
        className={`container-fluid ${styles.body} d-flex justify-content-around flex-column align-items-center text-center`}
        style={{
          backgroundColor: design?.background ? design?.background : "",
        }}
      >
        <p
          className={`${styles.testimonialsTag}`}
          style={{
            color: design?.tag ? design?.tag : "",
            border: design?.border ? design?.border : "",
          }}
        >
          {!!Testimonials?.tag && Testimonials?.tag}
        </p>
        <h2
          className={`${styles.header}`}
          style={{
            color: design?.header ? design?.header : "",
          }}
        >
          {!!Testimonials?.header && Testimonials?.header}
        </h2>

        <div className={styles.profileBody}>
          <img
            loading="lazy"
            src={
              !!Testimonials?.profile_image
                ? process.env.REACT_APP_CLOUDFLARE_URL +
                  Testimonials?.profile_image
                : ""
            }
            alt="avatar"
            className=""
          />
          <p
            className={`mb-1 ${styles.profileName}`}
            style={{
              color: design?.name ? design?.name : "",
            }}
          >
            {!!Testimonials?.profile_name && Testimonials?.profile_name}
          </p>
          <p
            className={`${styles.profileDesignation}`}
            style={{
              color: design?.designation ? design?.designation : "",
            }}
          >
            {!!Testimonials?.profile_designation &&
              Testimonials?.profile_designation}
          </p>
        </div>
        <div
          className={`w-100 ${styles.brandsBox} d-flex justify-content-around flex-row`}
        >
          {!!Testimonials?.products &&
            Testimonials?.products?.map((value, index) => (
              <div key={index} className="d-flex justify-content-center">
                <p
                  className={`${styles.brands} d-flex flex-row align-items-center`}
                  style={{
                    color: design?.brand ? design?.brand : "",
                  }}
                >
                  {
                    <img
                      loading="lazy"
                      src={
                        !!value?.icon
                          ? process.env.REACT_APP_CLOUDFLARE_URL + value?.icon
                          : ""
                      }
                      alt="icon"
                    />
                  }
                  {value?.name}
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default TestimonialsB;
