import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FooterDataList } from "../../store/actions/Footer/FooterData";
import styles from "./footer.module.scss";

function Footer() {
  const { isLoading, FooterData, errorMessage } = useSelector(
    (state) => state?.FooterReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FooterDataList());
  }, [dispatch]);

  return (
    <>
      {!isLoading && (
        <div className={`navbar ${styles.body} px-0`}>
          <div className={`container-fluid ${styles.innerBody} w-100`}>
            <div className={`row w-100 ${styles.top}`}>
              <div
                className={`col-md-6 col-8 d-flex ${styles.footerList} flex-column ${styles.leftCol}`}
              >
                <img
                  loading="lazy"
                  src={
                    !!FooterData?.logo
                      ? process.env.REACT_APP_CLOUDFLARE_URL + FooterData?.logo
                      : ""
                  }
                  alt="img"
                  className=""
                />
                <p>{!!FooterData?.about && FooterData?.about}</p>
              </div>

              <div className={`col-md-3 col-4 ${styles.footerList}`}>
                <p className={styles.footerHeading}>
                  {!!FooterData?.socialMedia && FooterData?.socialMedia}
                </p>
                <div
                  className={`d-flex justify-content-between w-75 ${styles.icon}`}
                >
                  {!!FooterData?.socialMediaIcons &&
                    FooterData?.socialMediaIcons?.map((value, index) => (
                      <img
                        loading="lazy"
                        key={index}
                        alt="icon"
                        src={
                          value?.icon
                            ? process.env.REACT_APP_CLOUDFLARE_URL + value?.icon
                            : ""
                        }
                      />
                    ))}
                </div>
              </div>

              <div className={`col-md-3 col-12 ${styles.rightCol}`}>
                <p className={styles.footerHeading}>
                  {!!FooterData?.label && FooterData?.label}
                </p>
                <div className="d-flex justify-content-between w-100">
                  <input
                    className="form-control form-control-lg footer-input"
                    type="text"
                    placeholder={!!FooterData?.email ? FooterData?.email : ""}
                  />
                  <button className="btn btn-primary footer-btn" type="submit">
                    {!!FooterData?.button && FooterData?.button}
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`row mx-0 mt-3 w-100 align-items-start ${styles.top}`}
            >
              <div
                className={`col-md-3 px-0 col-6 ${styles.footerList} d-flex flex-column justify-content-end`}
              >
                <p className={styles.footerHeading2}>
                  {!!FooterData?.links?.[0] && FooterData?.links?.[0]?.header}
                </p>
                {!!FooterData?.links?.[0]?.page &&
                  FooterData?.links?.[0]?.page?.map((value, index) => (
                    <Link
                      key={index}
                      className={styles.footerText}
                      to={!!value?.to ? value?.to : "#"}
                    >
                      {!!value?.name && value?.name}
                    </Link>
                  ))}

                <br />
              </div>

              <div
                className={`col-md-3 px-0 col-6 ${styles.footerList} d-flex flex-column justify-content-end`}
              >
                <p className={styles.footerHeading2}>
                  {!!FooterData?.links?.[1] && FooterData?.links?.[1]?.header}
                </p>
                {!!FooterData?.links?.[1]?.page &&
                  FooterData?.links?.[1]?.page?.map((value, index) => (
                    <Link
                      key={index}
                      className={styles.footerText}
                      to={!!value?.to ? value?.to : "#"}
                    >
                      {!!value?.name && value?.name}
                    </Link>
                  ))}

                <br />

                <p className={styles.footerHeading2}>
                  {!!FooterData?.links?.[2] && FooterData?.links?.[2]?.header}
                </p>
                {!!FooterData?.links?.[2]?.page &&
                  FooterData?.links?.[2]?.page?.map((value, index) => (
                    <Link
                      key={index}
                      className={styles.footerText}
                      to={!!value?.to ? value?.to : "#"}
                    >
                      {!!value?.name && value?.name}
                    </Link>
                  ))}

                <br />
              </div>

              <div
                className={`col-md-3 px-0  col-6 ${styles.footerList} d-flex flex-column justify-content-end`}
              >
                <p className={styles.footerHeading2}>
                  {!!FooterData?.links?.[3] && FooterData?.links?.[3]?.header}
                </p>
                {!!FooterData?.links?.[3]?.page &&
                  FooterData?.links?.[3]?.page?.map((value, index) => (
                    <Link
                      key={index}
                      className={styles.footerText}
                      to={!!value?.to ? value?.to : "#"}
                    >
                      {!!value?.name && value?.name}
                    </Link>
                  ))}

                <br />
              </div>

              <div
                className={`col-md-3 px-0  col-6 ${styles.footerList} d-flex flex-column justify-content-end`}
              >
                <p className={styles.footerHeading2}>
                  {!!FooterData?.links?.[4] && FooterData?.links?.[4]?.header}
                </p>
                {!!FooterData?.links?.[4]?.page &&
                  FooterData?.links?.[4]?.page?.map((value, index) => (
                    <Link
                      key={index}
                      className={styles.footerText}
                      to={!!value?.to ? value?.to : "#"}
                    >
                      {!!value?.name && value?.name}
                    </Link>
                  ))}

                <br />
              </div>
            </div>
            <hr
              className="m-0"
              style={{ borderColor: "#475467", width: "100%" }}
            />

            <div className={`row w-100 ${styles.bottom}`}>
              <div className={`col-md col-7 ${styles.footerText}`}>
                © 2022 Startem. All rights reserved.
              </div>

              <div className="col-md col-5 text-right px-0">
                <div className="d-flex w-100 justify-content-end flex-nowrap">
                  <div className={`${styles.footerText} ${styles.marginSet}`}>
                    Terms
                  </div>
                  <div className={`${styles.footerText} ${styles.marginSet}`}>
                    Privacy
                  </div>
                  <div className={styles.footerText}>Cookies</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Footer;
