import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../extra/loader/Loader";
import ContactA from "../../modules/contact/ContactA";
import Quote from "../../modules/contact/Quote";
import Portfolio from "../../modules/portfolio/Portfolio";
import Description from "../../modules/seo/Description";
import IncludedSeo from "../../modules/seo/IncludedSeo";
import IntroSeo from "../../modules/seo/IntroSeo";
import Seo from "../../modules/seo/Seo";
import TargetedSeo from "../../modules/seo/TargetedSeo";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import { NycUiUxServicesData } from "../../store/actions/City/NycUiUxServicesData";
import Page404 from "../placeholders/404Page";

function NYCUiUxServices() {
  const { isLoading, NycUiUxServicesPageData, errorMessage } = useSelector(
    (state) => state?.NycUiUxServicesReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(NycUiUxServicesData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "intro":
        return (
          <>
            {" "}
            {!!NycUiUxServicesPageData?.intro?.show && (
              <IntroSeo Intro={NycUiUxServicesPageData?.intro} />
            )}
          </>
        );

      case "our_services":
        return (
          <>
            {!!NycUiUxServicesPageData?.our_services?.show && (
              <IncludedSeo Services={NycUiUxServicesPageData?.our_services} />
            )}
          </>
        );

      case "targeted_services":
        return (
          <>
            {" "}
            {!!NycUiUxServicesPageData?.targeted_services?.show && (
              <TargetedSeo
                Targeted={NycUiUxServicesPageData?.targeted_services}
              />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!NycUiUxServicesPageData?.portfolio?.show && (
              <Portfolio portfolioData={NycUiUxServicesPageData?.portfolio} />
            )}
          </>
        );

      case "description":
        return (
          <>
            {!!NycUiUxServicesPageData?.description?.show && (
              <Description
                Description={NycUiUxServicesPageData?.description}
                design={{
                  background: "#53389E",
                  header: "#FFFFFF",
                  text1: "#EFEDF0",
                  text2: "#EFEDF0",
                }}
                content={{
                  box1: "city-sd/nyc/box1.png",
                  box2: "city-sd/nyc/box2.png",
                }}
              />
            )}
          </>
        );

      case "importance":
        return (
          <>
            {(!!NycUiUxServicesPageData?.importance?.show ||
              !!NycUiUxServicesPageData?.feature?.show) && (
              <Seo
                Importance={NycUiUxServicesPageData?.importance}
                feature={{
                  design: {
                    background: "#101828",
                    text: "#FAF8FC",
                    content: "#FAF8FC",
                    header: "#FFFFFF",
                  },
                  structure: 4,
                }}
                Feature={NycUiUxServicesPageData?.feature}
              />
            )}
          </>
        );

      case "testimonials":
        return (
          <>
            {!!NycUiUxServicesPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={NycUiUxServicesPageData?.testimonials}
                design={{
                  background: "#E4E8EF",
                  tag: "#7F56D9",
                  border: "1.5px solid #7F56D9",
                  header: "#101828",
                  name: "#101828",
                  designation: "#383E4A",
                  brand: "#101828",
                }}
                padding
              />
            )}
          </>
        );

      case "contact":
        
        return (
          <>
            {!!NycUiUxServicesPageData?.contact?.show && (
              <Quote
                design={{
                  background: "#53389E",
                  header: "#F0EBFB",
                  text: "#FFFFFF",
                }}
                content={{
                  header:
                    !!NycUiUxServicesPageData?.contact?.qHeader &&
                    NycUiUxServicesPageData?.contact?.qHeader,
                  text:
                    !!NycUiUxServicesPageData?.contact?.qText &&
                    NycUiUxServicesPageData?.contact?.qText,
                  button:
                    !!NycUiUxServicesPageData?.contact?.qButton &&
                    NycUiUxServicesPageData?.contact?.qButton,
                }}
              />
            )}

            {!!NycUiUxServicesPageData?.contact?.show && (
              <ContactA Contact={NycUiUxServicesPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>NYC UI/UX Design Services | Boost Your CRO | Startem</title>
            <meta
              name="description"
              content="Looking for captivating UI/UX design services in NYC? Startem offers specialized packages to suit your needs. Our empathetic approach, stunning designs, and focus on CRO can help improve your online presence. Contact us now to learn more."
            />
            <link
              rel="canonical"
              href="https://www.startem.io/nyc-uiux-services"
            />
          </Helmet>

          {!!NycUiUxServicesPageData?.sequence &&
            NycUiUxServicesPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*
          {!!NycUiUxServicesPageData?.intro?.show && (
            <IntroSeo Intro={NycUiUxServicesPageData?.intro} />
          )}

          {!!NycUiUxServicesPageData?.our_services?.show && (
            <IncludedSeo Services={NycUiUxServicesPageData?.our_services} />
          )}

          {!!NycUiUxServicesPageData?.targeted_services?.show && (
            <TargetedSeo
              Targeted={NycUiUxServicesPageData?.targeted_services}
            />
          )}

          {!!NycUiUxServicesPageData?.portfolio?.show && (
            <Portfolio portfolioData={NycUiUxServicesPageData?.portfolio} />
          )}

          {!!NycUiUxServicesPageData?.description?.show && (
            <Description
              Description={NycUiUxServicesPageData?.description}
              design={{
                background: "#101828",
                header: "#FFFFFF",
                text1: "#EFEDF0",
                text2: "#EFEDF0",
              }}
              content={{
                box1: "city-uiux/nyc/box1.png",
                box2: "city-uiux/nyc/box2.png",
              }}
            />
          )}

          {(!!NycUiUxServicesPageData?.importance?.show ||
            !!NycUiUxServicesPageData?.feature?.show) && (
            <Seo
              Importance={NycUiUxServicesPageData?.importance}
              feature={{
                design: {
                  background: "#121515",
                  text: "#FAF8FC",
                  content: "#EFF1F4",
                  header: "#FFFFFF",
                },
                structure: 2,
              }}
              Feature={NycUiUxServicesPageData?.feature}
            />
          )}

          {!!NycUiUxServicesPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={NycUiUxServicesPageData?.testimonials}
              design={{
                background: "#53389E",
                tag: "#7F56D9",
                border: "1.5px solid #7F56D9",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#B9B9B9",
                brand: "#FFFFFF",
              }}
              padding
            />
          )}

          {!!NycUiUxServicesPageData?.contact?.show && (
            <Quote
              design={{
                background: "#F2F4F8",
                header: "#1F2739",
                text: "#101828",
              }}
              content={{
                header:
                  !!NycUiUxServicesPageData?.contact?.qHeader &&
                  NycUiUxServicesPageData?.contact?.qHeader,
                text:
                  !!NycUiUxServicesPageData?.contact?.qText &&
                  NycUiUxServicesPageData?.contact?.qText,
                button:
                  !!NycUiUxServicesPageData?.contact?.qButton &&
                  NycUiUxServicesPageData?.contact?.qButton,
              }}
            />
          )}

          {!!NycUiUxServicesPageData?.contact?.show && (
            <ContactA Contact={NycUiUxServicesPageData?.contact} />
          )}
        */}
        </>
      )}
    </>
  );
}

export default NYCUiUxServices;
