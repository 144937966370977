import React, { useEffect } from "react";
import Contact from "../../modules/contact/Contact";
import FAQs from "../../modules/faq/FAQs";
import IdeasA from "../../modules/ideas/IdeasA";
import Portfolio from "../../modules/portfolio/Portfolio";
import ProcessB from "../../modules/process/ProcessB";
import Services from "../../modules/services/Services";
import WhyStartemA from "../../modules/why startem/WhyStartemA";
import Arrow from "../../assets/images/services/webdev-services/Vector.svg";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import { useDispatch, useSelector } from "react-redux";
import { WebDevServicesData } from "../../store/actions/Services/WebDevServicesData";
import { Loader } from "../../extra/loader/Loader";
import Page404 from "../placeholders/404Page";
import { Helmet } from "react-helmet";

function WebDevServices() {
  const { isLoading, WebDevServicesPageData, errorMessage } = useSelector(
    (state) => state?.WebDevServicesReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(WebDevServicesData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "intro":
        return (
          <>
            {!!WebDevServicesPageData?.intro?.show && (
              <IdeasA
                Intro={WebDevServicesPageData?.intro}
                imageContainer={{
                  position: "left",
                }}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
                btmImg
              />
            )}
          </>
        );

      case "why_startem":
        return (
          <>
            {!!WebDevServicesPageData?.why_startem?.show && (
              <WhyStartemA
                WhyStartem={WebDevServicesPageData?.why_startem}
                imageContainer={{
                  position: "left",
                }}
              />
            )}
          </>
        );

      case "our_services":
        return (
          <>
            {!!WebDevServicesPageData?.our_services?.show && (
              <Services
                ourServices={WebDevServicesPageData?.our_services}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
              />
            )}
          </>
        );

      case "process":
        return (
          <>
            {!!WebDevServicesPageData?.process?.show && (
              <ProcessB
                process={WebDevServicesPageData?.process}
                bottomBar={{
                  structure: 1,
                  design: "bottomBar1",
                }}
              />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!WebDevServicesPageData?.portfolio?.show && (
              <Portfolio portfolioData={WebDevServicesPageData?.portfolio} />
            )}
          </>
        );

      case "testimonials":
        return (
          <>
            {!!WebDevServicesPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={WebDevServicesPageData?.testimonials}
                design={{
                  background: "#1D2939",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#96A2B2",
                  brand: "#FFFFFF",
                }}
              />
            )}
          </>
        );

      case "faq":
        return (
          <>
            {!!WebDevServicesPageData?.faq?.show && (
              <FAQs faqData={WebDevServicesPageData?.faq} />
            )}
          </>
        );

      case "contact":
        return (
          <>
            {!!WebDevServicesPageData?.contact?.show && (
              <Contact data={WebDevServicesPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>
              Premium Web Development Services by Startem - Turn Your Ideas into
              Reality
            </title>
            <meta
              name="description"
              content="Looking for top-notch web development services? Startem is a startup studio that provides custom website development services using market-leading frameworks and clean code. Our process includes product UX discovery, user empathy analysis, wireframing, prototyping and designing, production, evaluation, and launch. Contact us today to take your user journey to the next level."
            />
            <link rel="canonical" href="https://www.startem.io/web-services" />
          </Helmet>

          {!!WebDevServicesPageData?.sequence &&
            WebDevServicesPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}
          {/*
          {!!WebDevServicesPageData?.intro?.show && (
            <IdeasA
              Intro={WebDevServicesPageData?.intro}
              imageContainer={{
                position: "left",
              }}
              bottomBar={{ structure: 2, design: "bottomBar5" }}
              Arrow={Arrow}
            />
          )}

          {!!WebDevServicesPageData?.why_startem?.show && (
            <WhyStartemA
              WhyStartem={WebDevServicesPageData?.why_startem}
              imageContainer={{
                position: "left",
              }}
              heading={"Why Our Website Development Services?"}
            />
          )}

          {!!WebDevServicesPageData?.our_services?.show && (
            <Services
              ourServices={WebDevServicesPageData?.our_services}
              bottomBar={{ structure: 2, design: "bottomBar4" }}
            />
          )}

          {!!WebDevServicesPageData?.process?.show && (
            <ProcessB
              process={WebDevServicesPageData?.process}
              bottomBar={{ structure: 2, design: "bottomBar3" }}
            />
          )}

          {!!WebDevServicesPageData?.portfolio?.show && (
            <Portfolio portfolioData={WebDevServicesPageData?.portfolio} />
          )}

          {!!WebDevServicesPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={WebDevServicesPageData?.testimonials}
              design={{
                background: "#121515",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#E9D7FE",
                brand: "#FFFFFF",
              }}
            />
          )}

          {!!WebDevServicesPageData?.faq?.show && (
            <FAQs faqData={WebDevServicesPageData?.faq} />
          )}

          {!!WebDevServicesPageData?.contact?.show && (
            <Contact data={WebDevServicesPageData?.contact} />
          )}
        */}
        </>
      )}
    </>
  );
}

export default WebDevServices;
