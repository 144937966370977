import React, { useEffect } from "react";
import ContactA from "../../modules/contact/ContactA";
import Quote from "../../modules/contact/Quote";
import Technology from "../../modules/services/Technology";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import Portfolio from "../../modules/portfolio/Portfolio";
import Healthcare from "../../modules/healthcare/Healthcare";
import HealthcareIntro from "../../modules/healthcare/HealthcareIntro";
import FAQsB from "../../modules/faq/FAQsB";
import WhyHealthcare from "../../modules/healthcare/WhyHealthcare";
import { useDispatch, useSelector } from "react-redux";
import { IndustryWebDevData } from "../../store/actions/Industries/IndustryWebDevData";
import { Loader } from "../../extra/loader/Loader";
import Page404 from "../placeholders/404Page";

function IndustriesWebServices() {
  const { isLoading, IndustryWebDevPageData, errorMessage } = useSelector(
    (state) => state?.IndustryWebDevReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(IndustryWebDevData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "healthcareIntro":
        return (
          <>
            {!!IndustryWebDevPageData?.healthcareIntro?.show && (
              <HealthcareIntro
                Intro={IndustryWebDevPageData?.healthcareIntro}
                moreRow
              />
            )}
          </>
        );

      case "healthcareBenefits":
        return (
          <>
            {!!IndustryWebDevPageData?.healthcareBenefits?.show && (
              <Healthcare
                Benefits={IndustryWebDevPageData?.healthcareBenefits}
              />
            )}
          </>
        );

      case "startemHealthcare":
        return (
          <>
            {!!IndustryWebDevPageData?.startemHealthcare?.show && (
              <WhyHealthcare
                startem={IndustryWebDevPageData?.startemHealthcare}
              />
            )}
          </>
        );

      case "faq":
        return (
          <>
            {!!IndustryWebDevPageData?.faq?.show && (
              <FAQsB faqData={IndustryWebDevPageData?.faq} />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!IndustryWebDevPageData?.portfolio?.show && (
              <Portfolio portfolioData={IndustryWebDevPageData?.portfolio} />
            )}
            <div style={{ marginBottom: "5vw" }}></div>
          </>
        );

      case "testimonials":
        return (
          <>
            {!!IndustryWebDevPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={IndustryWebDevPageData?.testimonials}
                design={{
                  background: "#101828",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#E9D7FE",
                  brand: "#FFFFFF",
                }}
                padding
              />
            )}

            <div style={{ marginBottom: "8vw" }}></div>
          </>
        );

      case "technology":
        return (
          <>
            {" "}
            {!!IndustryWebDevPageData?.technology?.show && (
              <Technology
                technology={IndustryWebDevPageData?.technology}
                containerClass={"body3"}
              />
            )}
          </>
        );

      case "contact":
        return (
          <>
            {!!IndustryWebDevPageData?.contact?.show && (
              <Quote
                design={{
                  background: "#2D2D2D",
                  header: "#FFFFFF",
                  text: "#E9D7FE",
                  botton: "#7F56D9",
                  bottom: 1,
                  row: true,
                }}
                content={{
                  header:
                    !!IndustryWebDevPageData?.contact?.qHeader &&
                    IndustryWebDevPageData?.contact?.qHeader,
                  text:
                    !!IndustryWebDevPageData?.contact?.qText &&
                    IndustryWebDevPageData?.contact?.qText,
                  button:
                    !!IndustryWebDevPageData?.contact?.qButton &&
                    IndustryWebDevPageData?.contact?.qButton,
                }}
              />
            )}

            {!!IndustryWebDevPageData?.contact?.show && (
              <ContactA Contact={IndustryWebDevPageData?.contact} reverse />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          {!!IndustryWebDevPageData?.sequence &&
            IndustryWebDevPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*

          {!!IndustryWebDevPageData?.healthcareIntro?.show && (
            <HealthcareIntro
              Intro={IndustryWebDevPageData?.healthcareIntro}
              moreRow
            />
          )}

          {!!IndustryWebDevPageData?.healthcareBenefits?.show && (
            <Healthcare Benefits={IndustryWebDevPageData?.healthcareBenefits} />
          )}

          {!!IndustryWebDevPageData?.startemHealthcare?.show && (
            <WhyHealthcare
              startem={IndustryWebDevPageData?.startemHealthcare}
            />
          )}

          {!!IndustryWebDevPageData?.faq?.show && (
            <FAQsB faqData={IndustryWebDevPageData?.faq} />
          )}

          {!!IndustryWebDevPageData?.portfolio?.show && (
            <Portfolio portfolioData={IndustryWebDevPageData?.portfolio} />
          )}
          <div style={{ marginBottom: "5vw" }}></div>

          {!!IndustryWebDevPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={IndustryWebDevPageData?.testimonials}
              design={{
                background: "#101828",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#E9D7FE",
                brand: "#FFFFFF",
              }}
              padding
            />
          )}

          <div style={{ marginBottom: "8vw" }}></div>

          {!!IndustryWebDevPageData?.technology?.show && (
            <Technology
              technology={IndustryWebDevPageData?.technology}
              containerClass={"body3"}
            />
          )}

          {!!IndustryWebDevPageData?.contact?.show && (
            <Quote
              design={{
                background: "#2D2D2D",
                header: "#FFFFFF",
                text: "#E9D7FE",
                botton: "#7F56D9",
                bottom: 1,
                row: true,
              }}
              content={{
                header:
                  !!IndustryWebDevPageData?.contact?.qHeader &&
                  IndustryWebDevPageData?.contact?.qHeader,
                text:
                  !!IndustryWebDevPageData?.contact?.qText &&
                  IndustryWebDevPageData?.contact?.qText,
                button:
                  !!IndustryWebDevPageData?.contact?.qButton &&
                  IndustryWebDevPageData?.contact?.qButton,
              }}
            />
          )}

          {!!IndustryWebDevPageData?.contact?.show && (
            <ContactA Contact={IndustryWebDevPageData?.contact} reverse />
          )}
        */}
        </>
      )}
    </>
  );
}

export default IndustriesWebServices;
