import {
  FOOTER_LOADER,
  FOOTER_SUCCESS,
  FOOTER_FAILURE,
  FOOTER_RESET,
} from "../../constants/Footer/FooterConstants";

const initialState = {
  isLoading: false,
  FooterData: {},
  errorMessage: "",
};

export const FooterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FOOTER_LOADER:
      return { ...state, isLoading: true };

    case FOOTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        FooterData: action.payload,
        errorMessage: "",
      };

    case FOOTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case FOOTER_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
