import React from "react";
import styles from "./challenges.module.scss";

function Challenges({ description }) {
  return (
    <div className={`container-fluid ${styles.body}`}>
      <div className="position-relative">
        <div
          className={`w-100 position-absolute ${styles.top} d-flex flex-column justify-content-center text-center`}
        >
          <h1 className={styles.header}>
            {!!description?.hedaer
              ? description?.header
              : "Challenges of IoT in healthcare"}
          </h1>
          <p className={styles.text}>
            {!!description?.text1 ? (
              description?.text1
            ) : (
              <>
                Data Security and Privacy are one of the biggest difficulties
                that IoT faces. Real-time data collection is possible with
                IoT-enabled
                <br />
                mobile devices. However, most of them do not comply to data
                standards and protocols.
              </>
            )}
          </p>
          <p className={styles.text}>
            {!!description?.text2 ? (
              description?.text2
            ) : (
              <>
                Regarding ownership and control of data, there is a lot of
                confusion. As a result, the data saved in IoT-enabled devices
                are vulnerable
                <br /> to data theft and are, therefore, more accessible to
                hackers who want to compromise personal health data. False
                health claims and
                <br /> the fabrication of phony IDs for prescription purchases
                and sales are two instances of misusing IoT device data.
              </>
            )}
          </p>
          <div>
            <button className={`btn ${styles.btn}`}>
              {!!description?.button ? (
                <>
                  {description?.button.split("|")?.[0]}
                  <br />
                  {description?.button.split("|")?.[1]}
                </>
              ) : (
                <>
                  Interested in IoT?
                  <br />
                  Tell Us More
                </>
              )}
            </button>
          </div>
        </div>
        <div className={`w-100 d-flex justify-content-center ${styles.bottom}`}>
          <img
            loading="lazy"
            src={
              !!description?.image
                ? process.env.REACT_APP_CLOUDFLARE_URL + description?.image
                : require("../../assets/images/services/IoT/challenge.png")
            }
            alt="img"
          />
        </div>
      </div>
    </div>
  );
}

export default Challenges;
