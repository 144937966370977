import React from "react";
import styles from "./quote.module.scss";

function Quote({ design, content }) {
  return (
    <div
      className={`${styles.body} ${
        !!design?.bottom ? styles.marginBottom : ""
      }`}
    >
      <div
        className={`w-100 ${styles.container} ${
          !!design?.bottom ? styles.bottom : ""
        } d-flex flex-column align-items-center text-center`}
        style={{
          backgroundColor: design?.background ? design?.background : "",
        }}
      >
        <h1
          className={styles.header}
          style={{
            color: design?.header ? design?.header : "",
          }}
        >
          {content?.header}
        </h1>
        {!!content?.text && (
          <p
            className={styles.text}
            style={{
              color: design?.text ? design?.text : "",
            }}
          >
            {content?.text}
          </p>
        )}
        <div>
          <button
            className={`btn ${styles.btn}`}
            style={{ background: design?.botton ? design?.botton : "" }}
          >
            {content?.button}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Quote;
