import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../components/home/Home";
import ContentServices from "../components/services/ContentServices";
import MobAppDev from "../components/services/MobAppDev";
import OurServices from "../components/services/OurServices";
import ProductManagementServices from "../components/services/ProductManagementServices";
import SeoServices from "../components/services/SeoServices";
import SocialMediaMarketingServices from "../components/services/SocialMediaMarketingServices";
import SoftwareDev from "../components/services/SoftwareDev";
import SupportMaintenenceServices from "../components/services/SupportMaintenenceServices";
import TorontoSeoServices from "../components/seo services/TorontoSeoServices";
import UIUXServices from "../components/services/UIUXServices";
import WebDevServices from "../components/services/WebDevServices";
import Body from "./Body";
import NYCSeoServices from "../components/seo services/NYCSeoServices";
import TorontoUiUxServices from "../components/UIUX-services/TorontoUiUxServices";
import NYCUiUxServices from "../components/UIUX-services/NYCUiUxServices";
import TorontoWebServices from "../components/webdev-services/TorontoWebServices";
import NYCWebServices from "../components/webdev-services/NYCWebServices";
import TorontoSdServices from "../components/sd-services/TorontoSdServices";
import NYCSdServices from "../components/sd-services/NYCSdServices";
import IndustriesSeoServices from "../components/seo services/IndustriesSeoServices";
import IndustriesWebServices from "../components/webdev-services/IndustriesWebServices";
import IoTHealthcare from "../components/services/IoTHealthcare";
import AboutUs from "../components/about-us/AboutUs";
import NoCodeLanding from "../components/no-code/NoCodeLanding";
import NoCodeServices from "../components/no-code/NoCodeServices";
import Blog from "../components/Blogs/Blog";
import BlogSingle from "../components/Blogs/BlogSingle";
import Page404 from "../components/placeholders/404Page";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Body />}>
          <Route index element={<Home />}></Route>
          <Route path="our-services" element={<OurServices />}></Route>
          <Route path="content-services" element={<ContentServices />} />
          <Route path="seo-services" element={<SeoServices />} />
          <Route
            path="marketing-services"
            element={<SocialMediaMarketingServices />}
          ></Route>
          <Route path="UI-UX-services" element={<UIUXServices />}></Route>
          <Route path="web-services" element={<WebDevServices />}></Route>
          <Route
            path="product-services"
            element={<ProductManagementServices />}
          ></Route>
          <Route
            path="support-services"
            element={<SupportMaintenenceServices />}
          ></Route>
          <Route path="mobile-services" element={<MobAppDev />}></Route>
          <Route path="software-services" element={<SoftwareDev />}></Route>
          <Route
            path="toronto-seo-services"
            element={<TorontoSeoServices />}
          ></Route>
          <Route path="nyc-seo-services" element={<NYCSeoServices />}></Route>
          <Route
            path="toronto-uiux-services"
            element={<TorontoUiUxServices />}
          ></Route>
          <Route path="nyc-uiux-services" element={<NYCUiUxServices />}></Route>
          <Route
            path="toronto-web-services"
            element={<TorontoWebServices />}
          ></Route>
          <Route path="nyc-web-services" element={<NYCWebServices />}></Route>
          <Route
            path="toronto-sd-services"
            element={<TorontoSdServices />}
          ></Route>
          <Route path="nyc-sd-services" element={<NYCSdServices />}></Route>
          <Route
            path="industry-seo-services"
            element={<IndustriesSeoServices />}
          ></Route>
          <Route
            path="industry-web-services"
            element={<IndustriesWebServices />}
          ></Route>

          <Route path="iot-healthcare" element={<IoTHealthcare />}></Route>

          <Route path="about-us" element={<AboutUs />}></Route>
          <Route path="no-code" element={<NoCodeLanding />}></Route>
          <Route path="no-code-services" element={<NoCodeServices />}></Route>
          <Route path="blog" element={<Blog />}></Route>
          <Route path="/blog/:id" element={<BlogSingle />}></Route>
          <Route path="/error" element={<Page404 />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
