import React, { useEffect, useState } from "react";
import styles from "./process.module.scss";
import "react-step-progress/dist/index.css";

function Process({ processData }) {
  const [step, setStep] = useState(0);

  const stepHandler = () => {
    if (step < 4) {
      setStep(step + 1);
    }
  };

  return (
    <div
      className={`container-fluid ${styles.body} px-0 d-flex flex-column align-items-center`}
    >
      <div
        className={`${styles.top} w-100 d-flex flex-column align-items-center`}
      >
        <div className="w-75 d-flex flex-column align-items-center text-center">
          <p className={`border ${styles.processTag}`}>{processData?.tag}</p>
          <h2 className={styles.heading}>{processData?.header}</h2>
        </div>
        <div className="w-100">
          <div className={`${styles.stepperWrapper}`}>
            {processData?.processes &&
              processData?.processes?.map((value, index) => (
                <div
                  key={index}
                  className={`${styles.stepperItem} ${
                    step >= index + 1 ? styles.completedAfter : ""
                  }`}
                  onClick={() => setStep(index)}
                >
                  <div
                    className={`${styles.stepCounter} ${
                      step >= index ? styles.completed : ""
                    }`}
                  >
                    {index + 1}
                  </div>
                  <div className="w-75">
                    <p
                      className={`text-center ${styles.title} ${
                        step === index ? styles.active : ""
                      }`}
                    >
                      {value?.title}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div
        className={`w-100 ${styles.bottomBody} d-flex flex-row justify-content-between`}
      >
        <div className={`col ${styles.leftCol}`}>
          {processData?.processes && (
            <div className={styles.scroller}>
              {processData?.processes[step]?.fields?.map((value, index) => (
                <div
                  key={index}
                  className={`${styles.featuredBg} w-100 d-flex flex-row align-items-center`}
                  onClick={() => stepHandler()}
                >
                  <div
                    className={`d-flex align-items-center justify-content-center`}
                  >
                    <img
                      loading="lazy"
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}${value?.icon}`}
                      alt="featured icon"
                    />
                  </div>
                  <p className={`my-0 ${styles.text}`}>{value?.title}</p>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className={`col-7 px-0 ${styles.rightCol}`}>
          <img
            loading="lazy"
            className="w-100 h-100"
            src={`${process.env.REACT_APP_CLOUDFLARE_URL}${processData?.image}`}
            alt="img"
          />
        </div>
      </div>
    </div>
  );
}

export default Process;
