import {
  ABOUT_US_PAGE_LOADER,
  ABOUT_US_PAGE_SUCCESS,
  ABOUT_US_PAGE_FAILURE,
  ABOUT_US_PAGE_RESET,
} from "../../constants/About Us/AboutUsConstants";

const initialState = {
  isLoading: false,
  AboutUsPageData: {},
  errorMessage: "",
};

export const AboutUsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ABOUT_US_PAGE_LOADER:
      return { ...state, isLoading: true };

    case ABOUT_US_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        AboutUsPageData: action.payload,
        errorMessage: "",
      };

    case ABOUT_US_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case ABOUT_US_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
