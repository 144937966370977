import React, { useState } from "react";
import styles from "./stepProgressBar.module.scss";

function StepsProgressBar({ processes }) {
  const [step, setStep] = useState(0);

  return (
    <div className={`${styles.stepperWrapper}`}>
      {!!processes &&
        processes?.map((value, index) => (
          <div
            key={index}
            className={`${styles.stepperItem} ${
              step >= index + 1 ? "" : "" //replace "styles.completedAfter"
            }`}
            onClick={() => setStep(index)}
          >
            <div
              className={`${styles.stepCounter} ${
                step >= index ? styles.completed : styles.completed //replace ""
              }`}
            >
              <i
                className={`bi ${
                  index < step || step === processes?.length - 1
                    ? `bi-circle-fill ${styles.dotIcon}` //to Apply back active, replace "bi-check-circle" and "${styles.checkIcon}"
                    : `bi-circle-fill ${styles.dotIcon}`
                } text-white align-middle`}
              ></i>
            </div>
            <div className="w-75 mt-2">
              <p
                className={`text-center ${styles.title} ${
                  step === index ? styles.active : styles.active //replace ""
                }`}
              >
                {value?.title}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
}

export default StepsProgressBar;
