import React from "react";
import styles from "./seo.module.scss";
import CheckIcon from "../../assets/images/services/city-seo/check-icon.svg";

function Seo({ Importance, Feature, feature }) {
  const structure = [
    <div className={`row`}>
      {!!Feature?.list &&
        Feature?.list?.map((value, index) => (
          <div
            key={index}
            className={`col-md-4 col-6 ${styles.card1} d-flex flex-row`}
          >
            <img loading="lazy" src={CheckIcon} alt="icon" />
            <p
              className={styles.content}
              style={{
                color: !!feature?.design?.content
                  ? feature?.design?.content
                  : "",
              }}
            >
              {value?.text}
            </p>
          </div>
        ))}
      <div
        className={`col-md-4 col-12 ${styles.card1} ${styles.centerButton} d-flex flex-row`}
      >
        <button className={`btn w-100 ${styles.btn1}`}>
          {!!Feature?.button1 && Feature?.button1}
        </button>
      </div>
      <div
        className={`col-md-4 col-12 ${styles.card1} ${styles.centerButton} d-flex flex-row`}
      >
        <button className={`btn w-100 ${styles.btn2}`}>
          {!!Feature?.button2 && Feature?.button2}
        </button>
      </div>
    </div>,

    <div className={`row justify-content-center`}>
      {!!Feature?.list &&
        Feature?.list?.map((value, index) => (
          <div
            key={index}
            className={`col-md-3 col-6 ${styles.card2} d-flex flex-row`}
          >
            <img loading="lazy" src={CheckIcon} alt="icon" />
            <p
              className={styles.content}
              style={{
                color: !!feature?.design?.content
                  ? feature?.design?.content
                  : "",
              }}
            >
              {value?.text}
            </p>
          </div>
        ))}
      <div className={`col-md-4 col-6 ${styles.card2} px-0 d-flex flex-row`}>
        <button className={`btn w-100 ${styles.btn1}`}>
          {!!Feature?.button1 && Feature?.button1}
        </button>
      </div>
      <div className={`col-md-4 col-6 ${styles.card2} px-0 d-flex flex-row`}>
        <button className={`btn w-100 ${styles.btn2}`}>
          {!!Feature?.button2 && Feature?.button2}
        </button>
      </div>
    </div>,

    <div className={`row justify-content-center mx-0`}>
      {!!Feature?.list &&
        Feature?.list?.map((value, index) => (
          <div
            key={index}
            className={`col-md-6 col-12 ${styles.card2} d-flex flex-row`}
          >
            <img loading="lazy" src={CheckIcon} alt="icon" />
            <p
              className={styles.content}
              style={{
                color: !!feature?.design?.content
                  ? feature?.design?.content
                  : "",
              }}
            >
              {value?.text}
            </p>
          </div>
        ))}
      <div className={`col-md-3 col-12 ${styles.card2} px-0 d-flex flex-row`}>
        <button className={`btn w-100 h-50 ml-0 ${styles.btn1}`}>
          {!!Feature?.button1 && Feature?.button1}
        </button>
      </div>
      <div className={`col-md-3 col-12 ${styles.card2} px-0 d-flex flex-row`}>
        <button className={`btn w-100 h-50 mr-0 ${styles.btn2}`}>
          {!!Feature?.button2 && Feature?.button2}
        </button>
      </div>
    </div>,

    <div className={`row justify-content-center mx-0`}>
      {!!Feature?.list &&
        Feature?.list?.map((value, index) => (
          <div
            key={index}
            className={`col-md-6 col-12 ${styles.card2} d-flex flex-row`}
          >
            <img src={CheckIcon} alt="icon" />
            <p
              className={styles.content}
              style={{
                color: !!feature?.design?.content
                  ? feature?.design?.content
                  : "",
              }}
            >
              {value?.text}
            </p>
          </div>
        ))}
      <div className={`col-md-3 col-12 ${styles.card2} px-0 d-flex flex-row`}>
        <button className={`btn w-100 ml-0 ${styles.btn1}`}>
          {!!Feature?.button1 && Feature?.button1}
        </button>
      </div>
      <div className={`col-md-3 col-12 ${styles.card2} px-0 d-flex flex-row`}>
        <button className={`btn w-100 mr-0 ${styles.btn2}`}>
          {!!Feature?.button2 && Feature?.button2}
        </button>
      </div>
    </div>,
  ];

  return (
    <div className={`container-fluid ${styles.body} position-relative px-0`}>
      <div className={`w-100 ${styles.top} d-flex flex-row`}>
        <div className={`${styles.leftCol}`}>
          <h3 className={styles.header1}>
            {!!Importance?.header1 && Importance?.header1}
          </h3>
          <h2 className={styles.header2}>
            {!!Importance?.header2 && Importance?.header2}
          </h2>
          <p className={styles.text}>
            {!!Importance?.text && Importance?.text}
          </p>
        </div>
        <div className={`row ${styles.rightCol} mx-0`}>
          {!!Importance?.list ? (
            Importance?.list?.map((value, index) => (
              <div
                key={index}
                className={`col-md-4 col-6 ${styles.card} d-flex flex-row`}
              >
                <img loading="lazy" src={CheckIcon} alt="icon" />
                <p className={styles.content}>{value?.text}</p>
              </div>
            ))
          ) : (
            <div>
              {!!Importance?.content &&
                Importance?.content?.map((value, index) => (
                  <p key={index} className={styles.content}>
                    {value?.text}
                  </p>
                ))}
            </div>
          )}
        </div>
      </div>
      <div
        className={`w-100 ${styles.mid} d-flex flex-row justify-content-center position-relative`}
      >
        <span
          className="position-absolute h-50 w-100"
          style={{
            zIndex: "-1",
            top: "50%",
            backgroundColor: !!feature?.design?.background
              ? feature?.design?.background
              : "",
          }}
        ></span>
        <div
          className={`${styles.container} d-flex flex-row justify-content-between align-items-center`}
        >
          <h2 className={`${styles.header}`}>
            {!!Importance?.collab && Importance?.collab}
          </h2>
          <div>
            <button className={`btn ${styles.btn}`}>
              {!!Importance?.button && Importance?.button}
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${styles.bottom} d-flex flex-column align-items-center`}
        style={{
          backgroundColor: !!feature?.design?.background
            ? feature?.design?.background
            : "",
        }}
      >
        <h1
          dangerouslySetInnerHTML={{
            __html: !!Feature?.header && Feature?.header,
          }}
          style={{
            color: !!feature?.design?.header ? feature?.design?.header : "",
          }}
        ></h1>
        <p
          className={`${styles.text} text-center`}
          style={{
            color: !!feature?.design?.text ? feature?.design?.text : "",
          }}
        >
          {!!Feature?.text && Feature?.text}
        </p>

        {structure[feature?.structure - 1]}
      </div>
    </div>
  );
}

export default Seo;
