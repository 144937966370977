import React, { useEffect } from "react";
import styles from "./healthcareIntro.module.scss";

function HealthcareIntro({ Intro, content, moreRow }) {
  return (
    <div className={`container-fluid ${styles.body}`}>
      <div className={`row ${styles.rowContainer}`}>
        <div className={`col-md-7 col-12 ${styles.leftCol1}`}>
          <p className={`${styles.tag} text-center`}>
            {!!Intro?.tag && Intro?.tag}
          </p>
          <h2
            className={styles.header}
            dangerouslySetInnerHTML={{
              __html: !!Intro?.header ? Intro?.header : "",
            }}
          ></h2>
          <p
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: !!Intro?.description ? Intro?.description : "",
            }}
          ></p>
          <button className={styles.botton}>
            {Intro?.button && Intro?.button}
          </button>
        </div>
        <div className={`col-md-5 ${styles.rightCol1}`}>
          <img
            loading="lazy"
            alt="img"
            className="w-100 h-100"
            src={
              !!Intro?.image
                ? process.env.REACT_APP_CLOUDFLARE_URL + Intro?.image
                : ""
            }
          />
        </div>
      </div>

      {!!moreRow && (
        <>
          <div className={`row ${styles.rowContainer}`}>
            <div className={`col-md-7 col-6 ${styles.leftCol2}`}>
              <img
                loading="lazy"
                alt="img"
                className="h-100"
                src={
                  !!Intro?.opImage
                    ? process.env.REACT_APP_CLOUDFLARE_URL + Intro?.opImage
                    : ""
                }
              />
            </div>

            <div className={`col-md-5 col-6 ${styles.rightCol2}`}>
              <h2 className={styles.heading}>
                {!!Intro?.opHeader && Intro?.opHeader}
              </h2>
              <p className={styles.text}>
                {!!Intro?.opDesctiption1 && Intro?.opDesctiption1}
              </p>
              <p className={styles.text}>
                {!!Intro?.opDescription2 && Intro?.opDescription2}
              </p>
            </div>
          </div>

          <div className={`row ${styles.rowContainer}`}>
            {!!Intro?.opList &&
              Intro?.opList?.map((value, index) => (
                <div key={index} className="col-md-4 col-12 d-flex flex-row">
                  <div className="d-flex justify-content-start">
                    <img
                      loading="lazy"
                      className={styles.img}
                      src={require("../../assets/images/check-icon.png")}
                      alt="icon"
                    />
                  </div>
                  <p className={styles.content}>{value?.text}</p>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}

export default HealthcareIntro;
