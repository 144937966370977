import React from "react";
import styles from "./includedSeoB.module.scss";

function IncludedSeoB({ Services, seoServices }) {
  return (
    <div className={`contianer-fluid ${styles.body}`}>
      <div
        className={`w-100 d-flex flex-column align-items-center text-center`}
      >
        <h3 className={styles.heading}>
          {!!Services?.header ? Services?.header : seoServices?.header}
        </h3>
        <div className="w-100 row justify-content-center">
          {!!Services?.services
            ? Services?.services?.map((value, index) => (
                <div key={index} className={`col-md-3 col-4 ${styles.box}`}>
                  <div className={`${styles.card} h-100`}>
                    <img
                      loading="lazy"
                      className={styles.img}
                      src={
                        !!value?.icon
                          ? process.env.REACT_APP_CLOUDFLARE_URL + value?.icon
                          : ""
                      }
                      alt="icon"
                    />
                    <h4 className={styles.title}>{value?.title}</h4>
                  </div>
                </div>
              ))
            : seoServices?.list?.map((value, index) => (
                <div key={index} className={`col-3 ${styles.box}`}>
                  <div className={`${styles.card} h-100`}>
                    <img
                      loading="lazy"
                      className={styles.img}
                      src={require(`../../assets/images/services/${value?.image}`)}
                      alt="icon"
                    />
                    <h4 className={styles.title}>{value?.title}</h4>
                  </div>
                </div>
              ))}
        </div>
        <div className="w-100">
          <button className={`btn ${styles.btn1}`}>Like What You See?</button>
          <button className={`btn ${styles.btn2}`}>
            Checkout Our other Services
          </button>
        </div>
      </div>
    </div>
  );
}

export default IncludedSeoB;
