import React from "react";
import styles from "./description.module.scss";
import CheckIcon from "../../assets/images/services/city-web/check-icon.svg";

function Description({ Description, content, design, structure }) {
  return (
    <div className={`container-fluid ${styles.body}`}>
      <div
        className={`${styles.container1} d-flex flex-column align-items-center text-center position-relative`}
        style={{
          backgroundColor: !!design?.background ? design?.background : "",
        }}
      >
        {!!content?.box1 && (
          <img
            loading="lazy"
            src={require(`../../assets/images/services/${content?.box1}`)}
            alt="box1"
            className={`${styles.box1} position-absolute`}
          />
        )}
        {!!content?.box2 && (
          <img
            loading="lazy"
            src={require(`../../assets/images/services/${content?.box2}`)}
            alt="box2"
            className={`${styles.box2} position-absolute`}
          />
        )}

        <h1
          className={styles.header}
          style={{
            color: !!design?.header ? design?.header : "",
          }}
        >
          {!!Description?.header && Description?.header}
        </h1>

        <p
          className={styles.text1}
          style={{
            color: !!design?.text1 ? design?.text1 : "",
          }}
        >
          {!!Description?.text1 && Description?.text1}
        </p>

        {!!Description?.text2 ? (
          <>
            <p
              className={styles.text2}
              style={{
                color: !!design?.text2 ? design?.text2 : "",
              }}
            >
              {!!Description?.text2 && Description?.text2}
            </p>

            <div>
              <button className={styles.btn}>
                {!!Description?.button && Description?.button}
              </button>
            </div>
          </>
        ) : (
          <div
            className={`row ${styles.box} justify-content-between`}
          >
            {!!Description?.list &&
              Description?.list?.map((value, index) => (
                <div
                  key={index}
                  className={`${
                    index === 4 ? "col-md-5 col-12" : "col-md-3 col-6"
                  } ${styles.card}`}
                >
                  <p
                    style={{
                      color: !!design?.text2 ? design?.text2 : "",
                    }}
                  >
                    <img loading="lazy" src={CheckIcon} alt="icon" />
                    {value?.text}
                  </p>
                </div>
              ))}
            <div className="col-md-3 col-6">
              <button className="btn">
                {!!Description?.button && Description?.button}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Description;
