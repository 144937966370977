import {
  NAVBAR_LOADER,
  NAVBAR_SUCCESS,
  NAVBAR_FAILURE,
} from "../../constants/Navbar/NavbarConstants";
import { getPage } from "../../../API/Api";

const success = (payload) => ({
  type: NAVBAR_SUCCESS,
  payload,
});

const failure = (error) => ({
  type: NAVBAR_FAILURE,
  error,
});

export const NavbarDataList = () => (dispatch) => {
  dispatch({ type: NAVBAR_LOADER });
  getPage("Navbar", {
    limit: -1,
  })
    .then((response) => {
      dispatch(success(response?.data[0]));
    })
    .catch((err) => {
      dispatch(failure(err?.errors));
    });
};
