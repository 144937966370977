import React from "react";
import Connect from "./ConnectA";
import styles from "./services.module.scss";

function Services({ ourServices, bottomBar }) {
  return (
    <div
      className={`container-fluid mt-5 pb-5 ${styles.body} px-0 d-flex flex-column align-items-center`}
    >
      <div
        className={`w-100 d-flex flex-column align-items-center justify-content-center mb-5 ${styles.top}`}
      >
        <div className="w-75 d-flex flex-column align-items-center text-center">
          <p className={`border ${styles.processTag}`}>
            {!!ourServices && ourServices?.tag}
          </p>
          <h2 className={styles.heading}>
            {!!ourServices?.header && ourServices?.header}
          </h2>
        </div>
        <div
          className={`w-100 row align-items-center justify-content-between ${styles.cardBox}`}
        >
          {!!ourServices?.services &&
            ourServices?.services?.map((value, index) => (
              <div
                key={index}
                className={`${styles.card} col-md col-4 d-flex flex-column align-items-center text-center`}
              >
                <img
                  loading="lazy"
                  alt="img"
                  src={
                    !!value?.icon
                      ? process.env.REACT_APP_CLOUDFLARE_URL + value?.icon
                      : ""
                  }
                />
                <p className={`${styles.text}`}>{value?.title}</p>
              </div>
            ))}
        </div>
      </div>
      <Connect ourServices={ourServices} bottomBar={bottomBar} />
    </div>
  );
}

export default Services;
