import React from "react";
import styles from "./targetedSeo.module.scss";

function TargetedSeo({ Targeted }) {
  return (
    <div className={`contianer-fluid ${styles.body}`}>
      <div
        className={`w-100 d-flex flex-column align-items-center text-center position-relative`}
      >
        <img
          loading="lazy"
          src={
            !!Targeted?.image1
              ? process.env.REACT_APP_CLOUDFLARE_URL + Targeted?.image1
              : ""
          }
          alt="Left Frame"
          className={`position-absolute ${styles.frame1}`}
        />
        <img
          loading="lazy"
          src={
            !!Targeted?.image2
              ? process.env.REACT_APP_CLOUDFLARE_URL + Targeted?.image2
              : ""
          }
          alt="Right Frame"
          className={`position-absolute ${styles.frame2}`}
        />
        <h3
          className={styles.header1}
          dangerouslySetInnerHTML={{
            __html: !!Targeted?.header1 ? Targeted?.header1 : "",
          }}
        ></h3>
        <h2 className={styles.header2}>
          {!!Targeted?.header2 && Targeted?.header2}
        </h2>

        <h3 className={styles.header1}>
          {!!Targeted?.header3 && Targeted?.header3}
        </h3>
        <p className={styles.text}>{!!Targeted?.text && Targeted?.text}</p>

        <h4 className={styles.header3}>
          {!!Targeted?.header4 && Targeted?.header4}
        </h4>
        <div className="row mx-0 justify-content-center">
          {!!Targeted?.list &&
            Targeted?.list?.map((value, index) => (
              <div className={`col-md-4 col-6 ${styles.box}`} key={index}>
                <div className={`${styles.card} h-100`}>
                  <img
                    loading="lazy"
                    src={
                      !!value?.image
                        ? process.env.REACT_APP_CLOUDFLARE_URL + value?.image
                        : ""
                    }
                    alt="icon"
                  />
                  <h5 className={styles.title}>{value?.title}</h5>
                  <p className={styles.text2}>{value?.text}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default TargetedSeo;
