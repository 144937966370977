import React from "react";
import styles from "./price.module.scss";
import { PriceCheck } from "../../assets/images/price-check";

function Price({ subscription, price }) {
  return (
    <div className={`${styles.body} container-fluid px-0 position-relative`}>
      <div
        className={`${styles.top} position-absolute d-flex flex-column align-items-center w-100`}
      >
        <p className={styles.tag}>
          {!!subscription?.tag ? subscription?.tag : "Pricing"}
        </p>
        <h2 className={styles.header}>
          {!!subscription?.header ? subscription?.header : "Pricing Plans"}
        </h2>
        <p className={styles.text}>
          {!!subscription?.text
            ? subscription?.text
            : "Simple, transparent pricing that grows with you. Try any plan free for 14 days."}
        </p>
        <div className={`w-25 ${styles.box} border p-1`}>
          <button className="btn btn-primary w-50">
            {subscription?.buton1 ? subscription?.button : "Monthly"}
          </button>
          <button className="btn w-50">
            {!!subscription?.button2 ? subscription?.button2 : "Annual"}
          </button>
        </div>
      </div>
      <div className={`${styles.bottom} d-flex justify-content-center`}>
        <div className={`${styles.container} row justify-content-between`}>
          {!!subscription?.plans
            ? subscription?.plans?.map((value, index) => (
                <div className={`${styles.box}`} key={index}>
                  <div className="text-center d-flex flex-column align-items-center">
                    <p className={`${styles.tag}`}>{value?.tag}</p>
                    <h2 className={`${styles.header}`}>{value?.price}</h2>
                    <p className={`${styles.text}`}>{value?.text}</p>
                  </div>

                  <div className={styles.innerBox}>
                    {!!value?.features &&
                      value?.features?.map((value2, index2) => (
                        <div
                          key={index2}
                          className={`d-flex flex-row align-items-center ${styles.card}`}
                        >
                          <PriceCheck />
                          <p className={`${styles.content}`}>{value2?.text}</p>
                        </div>
                      ))}
                  </div>

                  <button className={`btn w-100 ${styles.btn}`}>
                    {!!value?.button ? value?.button : "Get started"}
                  </button>
                </div>
              ))
            : price?.map((value, index) => (
                <div className={`${styles.box}`} key={index}>
                  <div className="text-center d-flex flex-column align-items-center">
                    <p className={`${styles.tag}`}>{value?.planType}</p>
                    <h2 className={`${styles.header}`}>{value?.price}</h2>
                    <p className={`${styles.text}`}>{value?.feature}</p>
                  </div>

                  <div className={styles.innerBox}>
                    {!!value?.list &&
                      value?.list?.map((value2, index2) => (
                        <div
                          key={index2}
                          className={`d-flex flex-row ${styles.card}`}
                        >
                          <PriceCheck />
                          <p className={`${styles.content}`}>{value2}</p>
                        </div>
                      ))}
                  </div>

                  <button className={`btn w-100 ${styles.btn}`}>
                    Get started
                  </button>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}

export default Price;
