import React, { useEffect, useState } from "react";
import Card from "../../modules/card/Card";
import Select from "react-select";
import styles from "./blog.module.scss";
import { Loader } from "../../extra/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { BlogsDataList } from "../../store/actions/Blog/BlogsDataList";
import Page404 from "../placeholders/404Page";

function Blog() {
  const [category, setCategory] = useState([]);
  // const [filter, setFilter] = useState("");

  const { isLoading, BlogsData, errorMessage } = useSelector(
    (state) => state?.BlogsReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(BlogsDataList());
  }, [dispatch]);

  useEffect(() => {
    const temp = [];
    BlogsData?.forEach((val) => {
      if (!temp?.some((cat) => cat?.value === val?.category)) {
        temp.push({ value: val?.category, label: val?.category });
      }
    });
    setCategory([...temp]);
  }, [BlogsData]);

  // const filerCategory = (filterData) => {
  //   setLoader(true);

  //   if (!filterData?.length) {
  //     getAll("Blogs")
  //       .then((res) => {
  //         setBlogs([...res?.data]);
  //         setLoader(false);
  //       })
  //       .catch(() => {
  //         setLoader(false);
  //       });
  //   } else {
  //     getByCategory("Blogs", filterData)
  //       .then((res) => {
  //         setBlogs([...res?.data]);
  //         setLoader(false);
  //       })
  //       .catch(() => {
  //         setLoader(false);
  //       });
  //   }
  // };

  const style = {
    control: (base) => ({
      ...base,

      boxShadow: "none",
      height: "100%",
      fontSize: "1vw",
      "&:hover": {
        border: "1px solid black",
      },
    }),

    menu: (base) => ({
      ...base,
      fontSize: "1vw",
    }),
  };

  return (
    <div
      className={`container-fluid d-flex flex-column align-items-center ${styles.body}`}
    >
      <div
        className={`w-100 d-flex flex-row justify-content-between ${styles.top}`}
      >
        <div className={`input-group ${styles.inputBox}`}>
          <input
            className="form-control h-100 py-2 border-right-0 border"
            type="search"
            id="example-search-input"
            placeholder="Search"
            // onChange={(e) => setFilter(e?.target?.value?.toLowerCase())}
          />
          <span className="input-group-append h-100">
            <div className="input-group-text bg-transparent">
              <i className="bi bi-search"></i>
            </div>
          </span>
        </div>

        <div className={`filter-select ${styles.filterBox}`}>
          <Select
            className="filter-box h-100 w-100"
            isMulti
            defaultValue={null}
            placeholder={"Category..."}
            name="filter"
            options={category}
            // onChange={(e) => filerCategory(e.map((val) => val?.value))}
            styles={style}
          />
        </div>
      </div>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <Card header={""} blogs={BlogsData} />
      )}
    </div>
  );
}

export default Blog;
