import React, { useEffect } from "react";
import ContactA from "../../modules/contact/ContactA";
import Quote from "../../modules/contact/Quote";
import Technology from "../../modules/services/Technology";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import Portfolio from "../../modules/portfolio/Portfolio";
import HealthcareIntro from "../../modules/healthcare/HealthcareIntro";
import ApplicationBenefits from "../../modules/healthcare/ApplicationBenefits";
import Challenges from "../../modules/healthcare/Challenges";
import { useDispatch, useSelector } from "react-redux";
import { IoTData } from "../../store/actions/IoT/IoTData";
import { Loader } from "../../extra/loader/Loader";
import Page404 from "../placeholders/404Page";
import { Helmet } from "react-helmet";

function IoTHealthcare() {
  const { isLoading, IoTPageData, errorMessage } = useSelector(
    (state) => state?.IoTReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(IoTData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "healthcareIntro":
        return (
          <>
            {!!IoTPageData?.healthcareIntro?.show && (
              <HealthcareIntro Intro={IoTPageData?.healthcareIntro} />
            )}
          </>
        );

      case "IoT":
        return (
          <>
            {!!IoTPageData?.IoT?.show && (
              <ApplicationBenefits IoT={IoTPageData?.IoT} />
            )}
            <div style={{ marginBottom: "8vw" }}></div>
          </>
        );

      case "description":
        return (
          <>
            {!!IoTPageData?.description?.show && (
              <Challenges description={IoTPageData?.description} />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!IoTPageData?.portfolio?.show && (
              <Portfolio portfolioData={IoTPageData?.portfolio} />
            )}
            <div style={{ marginBottom: "4vw" }}></div>
          </>
        );

      case "testimonials":
        return (
          <>
            {!!IoTPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={IoTPageData?.testimonials}
                design={{
                  background: "#101828",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#E9D7FE",
                  brand: "#FFFFFF",
                }}
                padding
              />
            )}
            <div style={{ marginBottom: "8vw" }}></div>
          </>
        );

      case "technology":
        return (
          <>
            {!!IoTPageData?.technology?.show && (
              <Technology
                technology={IoTPageData?.technology}
                containerClass={"body3"}
              />
            )}
          </>
        );

      case "contact":
        return (
          <>
            {!!IoTPageData?.contact?.show && (
              <Quote
                design={{
                  background: "#53389E",
                  header: "#FFFFFF",
                  text: "#E9D7FE",
                  botton: "#7F56D9",
                  bottom: 1,
                }}
                content={{
                  header:
                    !!IoTPageData?.contact?.qHeader &&
                    IoTPageData?.contact?.qHeader,
                  text:
                    !!IoTPageData?.contact?.qText &&
                    IoTPageData?.contact?.qText,
                }}
              />
            )}

            {!!IoTPageData?.contact?.show && (
              <ContactA Contact={IoTPageData?.contact} reverse />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>
              IoT in Healthcare: Advantages and Challenges | Startem
            </title>
            <meta
              name="description"
              content="Startem’s IoT platforms, connections, and modules make real-time monitoring possible for healthcare applications, providing numerous benefits to the industry. However, challenges such as data security and privacy must be addressed."
            />
            <link
              rel="canonical"
              href="https://www.startem.io/iot-healthcare"
            />
          </Helmet>

          {!!IoTPageData?.sequence &&
            IoTPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/**

          {!!IoTPageData?.healthcareIntro?.show && (
            <HealthcareIntro Intro={IoTPageData?.healthcareIntro} />
          )}

          {!!IoTPageData?.IoT?.show && (
            <ApplicationBenefits IoT={IoTPageData?.IoT} />
          )}
          <div style={{ marginBottom: "8vw" }}></div>

          {!!IoTPageData?.description?.show && (
            <Challenges description={IoTPageData?.description} />
          )}

          {!!IoTPageData?.portfolio?.show && (
            <Portfolio portfolioData={IoTPageData?.portfolio} />
          )}

          <div style={{ marginBottom: "4vw" }}></div>

          {!!IoTPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={IoTPageData?.testimonials}
              design={{
                background: "#101828",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#E9D7FE",
                brand: "#FFFFFF",
              }}
              padding
            />
          )}
          <div style={{ marginBottom: "8vw" }}></div>

          {!!IoTPageData?.technology?.show && (
            <Technology
              technology={IoTPageData?.technology}
              containerClass={"body3"}
            />
          )}

          {!!IoTPageData?.contact?.show && (
            <Quote
              design={{
                background: "#53389E",
                header: "#FFFFFF",
                text: "#E9D7FE",
                botton: "#7F56D9",
                bottom: 1,
              }}
              content={{
                header:
                  !!IoTPageData?.contact?.qHeader &&
                  IoTPageData?.contact?.qHeader,
                text:
                  !!IoTPageData?.contact?.qText && IoTPageData?.contact?.qText,
              }}
            />
          )}

          {!!IoTPageData?.contact?.show && (
            <ContactA Contact={IoTPageData?.contact} reverse />
          )}
           */}
        </>
      )}
    </>
  );
}

export default IoTHealthcare;
