import React from "react";
import styles from "./whyHealthcare.module.scss";

function WhyHealthcare({ startem }) {
  return (
    <div className={`container-fluid ${styles.body}`}>
      <div>
        <img
          loading="lazy"
          className={styles.img}
          src={require("../../assets/images/services/city-seo/industry/healthcare-icon.png")}
          alt="icon"
        />
      </div>
      <div className="row mx-0">
        <div className="col-md-4 col-12 px-0">
          <h1 className={styles.header}>
            {!!startem?.header && startem?.header}
          </h1>
        </div>

        {!!startem?.list && (
          <div className="col-md-8 col-12 row mx-0 px-0">
            {startem?.list?.map((value, index) => (
              <div
                key={index}
                className={`${index === 2 ? "col-md-12 col-12 px-0" : "col-md-6 col-12 px-0"} ${
                  styles.box
                } d-flex flex-row`}
              >
                <div className="d-flex">
                  <img
                    loading="lazy"
                    className={styles.imgBox}
                    src={require("../../assets/images/check-icon.png")}
                    alt="icon"
                  />
                </div>
                <div>
                  <h3 className={styles.heading}>{value?.title}</h3>
                  <p className={styles.text}>{value?.text}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default WhyHealthcare;
