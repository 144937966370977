import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../extra/loader/Loader";
import Contact from "../../modules/contact/Contact";
import FAQs from "../../modules/faq/FAQs";
import IdeasA from "../../modules/ideas/IdeasA";
import Portfolio from "../../modules/portfolio/Portfolio";
import ProcessB from "../../modules/process/ProcessB";
import Services from "../../modules/services/Services";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import WhyStartemA from "../../modules/why startem/WhyStartemA";
import { SmmServicesData } from "../../store/actions/Services/SMMServicesData";
import Page404 from "../placeholders/404Page";

function SocialMediaMarketingServices() {
  const { isLoading, SmmServicesPageData, errorMessage } = useSelector(
    (state) => state?.SmmServicesReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(SmmServicesData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "intro":
        return (
          <>
            {!!SmmServicesPageData?.intro?.show && (
              <IdeasA
                Intro={SmmServicesPageData?.intro}
                imageContainer={{
                  position: "left",
                }}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
                btmImg
              />
            )}
          </>
        );

      case "why_startem":
        return (
          <>
            {!!SmmServicesPageData?.why_startem?.show && (
              <WhyStartemA
                WhyStartem={SmmServicesPageData?.why_startem}
                imageContainer={{
                  position: "left",
                }}
              />
            )}
          </>
        );

      case "our_services":
        return (
          <>
            {!!SmmServicesPageData?.our_services?.show && (
              <Services
                ourServices={SmmServicesPageData?.our_services}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
              />
            )}
          </>
        );

      case "process":
        return (
          <>
            {!!SmmServicesPageData?.process?.show && (
              <ProcessB
                process={SmmServicesPageData?.process}
                bottomBar={{
                  structure: 1,
                  design: "bottomBar1",
                }}
              />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!SmmServicesPageData?.portfolio?.show && (
              <Portfolio portfolioData={SmmServicesPageData?.portfolio} />
            )}
          </>
        );

      case "testimonials":
        return (
          <>
            {!!SmmServicesPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={SmmServicesPageData?.testimonials}
                design={{
                  background: "#1D2939",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#96A2B2",
                  brand: "#FFFFFF",
                }}
              />
            )}
          </>
        );

      case "faq":
        return (
          <>
            {!!SmmServicesPageData?.faq?.show && (
              <FAQs faqData={SmmServicesPageData?.faq} />
            )}
          </>
        );

      case "contact":
        return (
          <>
            {!!SmmServicesPageData?.contact?.show && (
              <Contact data={SmmServicesPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>
              Top-notch Social Media Marketing Services for Your Brand | Startem
            </title>
            <meta
              name="description"
              content="Turbo boost your brand's online presence with Startem's social media marketing services. We offer pay-per-click ads, video promotion, email marketing, and more. Our process includes competitor research, content planning, ad campaigns, and growth hacking."
            />
            <link
              rel="canonical"
              href="https://www.startem.io/marketing-services"
            />
          </Helmet>

          {!!SmmServicesPageData?.sequence &&
            SmmServicesPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*
          {!!SmmServicesPageData?.intro?.show && (
            <IdeasA
              Intro={SmmServicesPageData?.intro}
              imageContainer={{
                position: "left",
                width: "90%",
              }}
              bottomBar={{ structure: 2, design: "bottomBar3" }}
            />
          )}

          {!!SmmServicesPageData?.why_startem?.show && (
            <WhyStartemA
              WhyStartem={SmmServicesPageData?.why_startem}
              imageContainer={{
                path: "smm-services/mockup.png",
                position: "right",
              }}
              heading={"Why Our Social Media Services?"}
            />
          )}

          {!!SmmServicesPageData?.our_services?.show && (
            <Services
              ourServices={SmmServicesPageData?.our_services}
              bottomBar={{ structure: 2, design: "bottomBar3" }}
            />
          )}

          {!!SmmServicesPageData?.process?.show && (
            <ProcessB
              process={SmmServicesPageData?.process}
              bottomBar={{ structure: 2, design: "bottomBar3" }}
            />
          )}

          {!!SmmServicesPageData?.portfolio?.show && (
            <Portfolio portfolioData={SmmServicesPageData?.portfolio} />
          )}

          {!!SmmServicesPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={SmmServicesPageData?.testimonials}
              design={{
                background: "#636F73",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#B6C1C5",
                brand: "#FFFFFF",
              }}
            />
          )}

          {!!SmmServicesPageData?.faq?.show && (
            <FAQs faqData={SmmServicesPageData?.faq} />
          )}

          {!!SmmServicesPageData?.contact?.show && (
            <Contact data={SmmServicesPageData?.contact} />
          )}
        */}
        </>
      )}
    </>
  );
}

export default SocialMediaMarketingServices;
