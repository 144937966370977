import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../extra/loader/Loader";
import Contact from "../../modules/contact/Contact";
import FAQs from "../../modules/faq/FAQs";
import IdeasA from "../../modules/ideas/IdeasA";
import Portfolio from "../../modules/portfolio/Portfolio";
import ProcessB from "../../modules/process/ProcessB";
import Services from "../../modules/services/Services";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import WhyStartemA from "../../modules/why startem/WhyStartemA";
import { ContentServicesData } from "../../store/actions/Services/ContentServicesData";
import Page404 from "../placeholders/404Page";

function ContentServices() {
  const { isLoading, ContentServicesPageData, errorMessage } = useSelector(
    (state) => state?.ContentServicesReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(ContentServicesData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "intro":
        return (
          <>
            {!!ContentServicesPageData?.intro?.show && (
              <IdeasA
                Intro={ContentServicesPageData?.intro}
                imageContainer={{
                  position: "left",
                }}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
                btmImg
              />
            )}
          </>
        );

      case "why_startem":
        return (
          <>
            {!!ContentServicesPageData?.why_startem?.show && (
              <WhyStartemA
                WhyStartem={ContentServicesPageData?.why_startem}
                imageContainer={{
                  position: "left",
                }}
              />
            )}
          </>
        );

      case "our_services":
        return (
          <>
            {!!ContentServicesPageData?.our_services?.show && (
              <Services
                ourServices={ContentServicesPageData?.our_services}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
              />
            )}
          </>
        );

      case "process":
        return (
          <>
            {!!ContentServicesPageData?.process?.show && (
              <ProcessB
                process={ContentServicesPageData?.process}
                bottomBar={{
                  structure: 1,
                  design: "bottomBar1",
                }}
              />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!ContentServicesPageData?.portfolio?.show && (
              <Portfolio portfolioData={ContentServicesPageData?.portfolio} />
            )}
          </>
        );

      case "testimonials":
        return (
          <>
            {!!ContentServicesPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={ContentServicesPageData?.testimonials}
                design={{
                  background: "#1D2939",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#96A2B2",
                  brand: "#FFFFFF",
                }}
              />
            )}
          </>
        );

      case "faq":
        return (
          <>
            {!!ContentServicesPageData?.faq?.show && (
              <FAQs faqData={ContentServicesPageData?.faq} />
            )}
          </>
        );

      case "contact":
        return (
          <>
            {!!ContentServicesPageData?.contact?.show && (
              <Contact data={ContentServicesPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>
              SEO Content Writing Services | Engage, Captivate, and Retain
            </title>
            <meta
              name="description"
              content="At Startem, we provide top-notch SEO content writing services that engage seamlessly, captivate instantly, and retain eternally. Our services include ad copies, technical writing, product descriptions, web copies, and blogs. Our SOPs include keyword research, content research, first draft, and proofreading. Ready to convert your clicks to customers? An SEO-friendly copy awaits you!"
            />
            <link
              rel="canonical"
              href="https://www.startem.io/content-services"
            />
          </Helmet>

          {!!ContentServicesPageData?.sequence &&
            ContentServicesPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}
            
        {/*
          {!!ContentServicesPageData?.intro?.show && (
            <IdeasA
              Intro={ContentServicesPageData?.intro}
              imageContainer={{
                position: "left",
              }}
              bottomBar={{ structure: 1, design: "bottomBar1" }}
              btmImg
            />
          )}

          {!!ContentServicesPageData?.why_startem?.show && (
            <WhyStartemA
              WhyStartem={ContentServicesPageData?.why_startem}
              imageContainer={{
                position: "left",
              }}
            />
          )}

          {!!ContentServicesPageData?.our_services?.show && (
            <Services
              ourServices={ContentServicesPageData?.our_services}
              bottomBar={{ structure: 1, design: "bottomBar1" }}
            />
          )}

          {!!ContentServicesPageData?.process?.show && (
            <ProcessB
              process={ContentServicesPageData?.process}
              bottomBar={{
                structure: 1,
                design: "bottomBar1",
              }}
            />
          )}

          {!!ContentServicesPageData?.portfolio?.show && (
            <Portfolio portfolioData={ContentServicesPageData?.portfolio} />
          )}

          {!!ContentServicesPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={ContentServicesPageData?.testimonials}
              design={{
                background: "#1D2939",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#96A2B2",
                brand: "#FFFFFF",
              }}
            />
          )}

          {!!ContentServicesPageData?.faq?.show && (
            <FAQs faqData={ContentServicesPageData?.faq} />
          )}

          {!!ContentServicesPageData?.contact?.show && (
            <Contact data={ContentServicesPageData?.contact} />
          )}
        */}
        </>
      )}
    </>
  );
}

export default ContentServices;
