import React from "react";
import StepsProgressBar from "../step progress bar/StepsProgressBar";
import styles from "./processB.module.scss";

function ProcessB({ process, bottomBar }) {
  const bottomBarClass = (params) => {
    switch (params) {
      case "bottomBar1": {
        return styles.bottomBar1;
      }

      case "bottomBar2": {
        return styles.bottomBar2;
      }

      case "bottomBar3": {
        return styles.bottomBar3;
      }

      default: {
        return "";
      }
    }
  };

  return (
    <div
      className={`container-fluid mt-5 ${styles.body} px-0 d-flex flex-column align-items-center`}
    >
      <div className={`w-100 d-flex flex-column align-items-center`}>
        <div className="w-100 d-flex flex-column align-items-center text-center">
          <p className={`border ${styles.processTag}`}>
            {!!process?.tag && process?.tag}
          </p>
          <h2 className={styles.heading}>
            {!!process?.header && process?.header}
          </h2>
        </div>
        <div className="w-100">
          <StepsProgressBar processes={process?.processes} />
        </div>
        {!!bottomBar?.structure && bottomBar?.structure === 1 ? (
          <div
            className={`${bottomBarClass(
              bottomBar?.design
            )} d-flex flex-column justify-content-center align-items-center text-center`}
          >
            <h2 className={`${styles.header}`}>
              {!!process?.cHeader && process?.cHeader}
            </h2>
            <p className={styles.text}>{!!process?.cText && process?.cText}</p>
            {!!process?.cContact ? (
              <button className={`btn text-light ${styles.botton} px-4`}>
                <div className={styles.topText}>
                  {!!process?.cButton && process?.cButton}
                </div>
                <div className={styles.bottomText}>
                  {!!process?.cContact && process?.cContact}
                </div>
              </button>
            ) : (
              <button className={`btn text-light ${styles.botton}`}>
                {!!process?.cButton && process?.cButton}
              </button>
            )}
          </div>
        ) : (
          <div
            className={`${bottomBarClass(
              bottomBar?.design
            )} d-flex flex-row justify-content-between align-items-center`}
          >
            <div>
              <h2 className={`${styles.header}`}>
                {!!process?.cHeader && process?.cHeader}
              </h2>
              {!!process?.cText && (
                <p className={styles.text}>
                  {!!process?.cText && process?.cText}
                </p>
              )}
            </div>
            <div>
              <button className={`btn text-light ${styles.botton} px-4`}>
                <div className={styles.topText}>
                  {!!process?.cButton && process?.cButton}
                </div>
                <div className={styles.bottomText}>
                  {!!process?.cContact && process?.cContact}
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProcessB;
