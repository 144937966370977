import {
  SEO_SERVICES_PAGE_LOADER,
  SEO_SERVICES_PAGE_SUCCESS,
  SEO_SERVICES_PAGE_FAILURE,
  SEO_SERVICES_PAGE_RESET,
} from "../../constants/Services/SEOServicesConstants";

const initialState = {
  isLoading: false,
  SeoServicesPageData: {},
  errorMessage: "",
};

export const SeoServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEO_SERVICES_PAGE_LOADER:
      return { ...state, isLoading: true };

    case SEO_SERVICES_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        SeoServicesPageData: action.payload,
        errorMessage: "",
      };

    case SEO_SERVICES_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case SEO_SERVICES_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
