import React from "react";
import styles from "./faqB.module.scss";

function FAQsB({ faqData }) {
  return (
    <div
      className={`container-fluid ${styles.body} d-flex flex-column align-items-center`}
    >
      <div
        className={`w-100 ${styles.top} d-flex flex-column align-items-center`}
      >
        <p className={styles.tag}>{!!faqData?.tag && faqData?.tag}</p>
        <h2 className={styles.heading}>
          {!!faqData?.header && faqData?.header}
        </h2>
        <p className={styles.text}>
          {!!faqData?.description && faqData?.description}
        </p>
      </div>
      <div className={`w-100 ${styles.bottom} row mx-0`}>
        {!!faqData?.FAQs &&
          (faqData?.FAQs?.length === 2 ? (
            <>
              <div
                className={`col-md-6 col-12 ${styles.col1} d-flex flex-column`}
              >
                <div className={styles.icon}>
                  <img
                    loading="lazy"
                    className={styles.img}
                    src={
                      !!faqData?.FAQs?.[0]?.icon
                        ? process.env.REACT_APP_CLOUDFLARE_URL +
                          faqData?.FAQs?.[0]?.icon
                        : ""
                    }
                    alt="icon"
                  />
                </div>
                <h3 className={styles.header}>
                  {faqData?.FAQs?.[0]?.question}
                </h3>
                <p className={styles.text}>{faqData?.FAQs?.[0]?.answer}</p>
              </div>

              <div
                className={`col-md-6 ${styles.col2} px-0 d-flex flex-column`}
              >
                <div className={styles.icon}>
                  <img
                    loading="lazy"
                    className={styles.img}
                    src={
                      !!faqData?.FAQs?.[1]?.icon
                        ? process.env.REACT_APP_CLOUDFLARE_URL +
                          faqData?.FAQs?.[1]?.icon
                        : ""
                    }
                    alt="icon"
                  />
                </div>
                <h3 className={styles.header}>
                  {faqData?.FAQs?.[1]?.question}
                </h3>
                <p className={styles.text}>{faqData?.FAQs?.[1]?.answer}</p>
              </div>
            </>
          ) : (
            <>
              {faqData?.FAQs?.map((value, index) => (
                <div
                  key={index}
                  className={`col-md-4 col-12 ${styles.col} d-flex flex-column text-center`}
                >
                  <div>
                    <img
                      loading="lazy"
                      className={styles.img}
                      src={
                        !!value?.icon
                          ? process.env.REACT_APP_CLOUDFLARE_URL + value?.icon
                          : ""
                      }
                      alt="icon"
                    />
                  </div>
                  <h3 className={styles.header}>{value?.question}</h3>
                  <p className={styles.text}>{value?.answer}</p>
                </div>
              ))}
            </>
          ))}
      </div>
    </div>
  );
}

export default FAQsB;
