import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Loader } from "../../extra/loader/Loader.jsx";
import Contact from "../../modules/contact/Contact.js";
import FAQs from "../../modules/faq/FAQs.js";
import Portfolio from "../../modules/portfolio/Portfolio.js";
import TestimonialsB from "../../modules/testimonials/TestimonialsB.js";
import { OurServicesData } from "../../store/actions/Services/OurServicesData.js";
import Page404 from "../placeholders/404Page.jsx";
import styles from "./ourService.module.scss";

function OurServices() {
  const { isLoading, OurServicesPageData, errorMessage } = useSelector(
    (state) => state?.OurServicesReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(OurServicesData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "intro":
        return (
          <>
            {!!OurServicesPageData?.intro?.show && (
              <div className={`${styles.topServices} mt-5`}>
                <div className={`${styles.body} position-relative`}>
                  <div
                    className={`${styles.top} d-flex flex-column justify-content-start align-items-center position-absolute text-center px-5`}
                  >
                    <p className={`${styles.servicesTag}`}>
                      {OurServicesPageData?.intro?.Tag}
                    </p>
                    <h1 className={styles.header}>
                      {OurServicesPageData?.intro?.Header}
                    </h1>
                    <p className={`${styles.text}`}>
                      {OurServicesPageData?.intro?.description}
                    </p>
                  </div>
                  <div
                    className={`${styles.bottom} w-100 d-flex justify-content-center`}
                  >
                    <img
                      loading="lazy"
                      src={
                        !!OurServicesPageData?.intro?.image
                          ? process.env.REACT_APP_CLOUDFLARE_URL +
                            OurServicesPageData?.intro?.image
                          : ""
                      }
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        );

      case "our_services":
        return (
          <>
            {!!OurServicesPageData?.our_services?.show && (
              <div className={styles.ourServicesBody}>
                <div
                  className={`container-fluid ${styles.body} mt-5 d-flex flex-column justify-content-around align-items-center text-center`}
                >
                  <div
                    className={`row w-50 ${styles.top} flex-column align-items-center justify-content-center`}
                  >
                    <p className={`${styles.ourServicesTag}`}>
                      {OurServicesPageData?.our_services?.tag}
                    </p>
                    <h3 className={styles.header}>
                      {OurServicesPageData?.our_services?.header}
                    </h3>
                  </div>

                  <div className={`row w-100 ${styles.bottom}`}>
                    {!!OurServicesPageData?.our_services?.services &&
                      OurServicesPageData?.our_services?.services?.map(
                        (value, index) => (
                          <div
                            key={index}
                            className={`${index === 7 ? "col-8" : "col-4"}`}
                          >
                            <div
                              className={`${styles.cardSize} w-100 d-flex flex-row align-items-start`}
                            >
                              <img
                                loading="lazy"
                                className={styles.image}
                                src={
                                  !!value?.icon
                                    ? process.env.REACT_APP_CLOUDFLARE_URL +
                                      value?.icon
                                    : ""
                                }
                                alt="Web icon"
                              />
                              <div className="d-flex flex-column align-items-start pl-2 mb-3">
                                <Link
                                  className={`${styles.servicesFontWeight}`}
                                  to={!!value?.to ? value?.to : "#"}
                                >
                                  {value?.title}
                                </Link>

                                {!!value?.list ? (
                                  <>
                                    <div className="d-flex flex-row w-100 align-items-start">
                                      {value?.list?.map((value, index) => (
                                        <p
                                          key={index}
                                          className={`${styles.servicesFontSize}`}
                                        >
                                          <i className="bi bi-dot"></i>
                                          {value?.text}
                                        </p>
                                      ))}
                                      <p className={`${styles.servicesFont}`}>
                                        Learn More{" "}
                                        <i className="bi bi-arrow-right"></i>
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <p className={`${styles.servicesFont}`}>
                                    Learn More{" "}
                                    <i className="bi bi-arrow-right ml-1 align-middle"></i>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!OurServicesPageData?.portfolio?.show && (
              <Portfolio untag portfolioData={OurServicesPageData?.portfolio} />
            )}
          </>
        );

      case "testimonials":
        return (
          <>
            {!!OurServicesPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={OurServicesPageData?.testimonials}
                design={{
                  background: "#53389E",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#E9D7FE",
                  brand: "#FFFFFF",
                }}
              />
            )}
          </>
        );

      case "faq":
        return (
          <>
            {!!OurServicesPageData?.testimonials?.show && (
              <FAQs faqData={OurServicesPageData?.faq} />
            )}
          </>
        );

      case "contact":
        return (
          <>
            {!!OurServicesPageData?.testimonials?.show && (
              <Contact data={OurServicesPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>
              Comprehensive Startup Services | Web, Software, Mobile App
              Development and More
            </title>
            <meta
              name="description"
              content="Our startup services are designed to take your business from concept to launch and beyond. From web and software development to digital marketing and support, we offer a comprehensive suite of services to help your startup succeed. Learn more about our services today."
            />
            <link rel="canonical" href="https://www.startem.io/our-services" />
          </Helmet>

          {!!OurServicesPageData?.sequence &&
            OurServicesPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*

          {!!OurServicesPageData?.intro?.show && (
            <div className={`${styles.topServices} mt-5`}>
              <div className={`${styles.body} position-relative`}>
                <div
                  className={`${styles.top} d-flex flex-column justify-content-start align-items-center position-absolute text-center px-5`}
                >
                  <p className={`${styles.servicesTag}`}>
                    {OurServicesPageData?.intro?.Tag}
                  </p>
                  <h1 className={styles.header}>
                    {OurServicesPageData?.intro?.Header}
                  </h1>
                  <p className={`${styles.text}`}>
                    {OurServicesPageData?.intro?.description}
                  </p>
                </div>
                <div
                  className={`${styles.bottom} w-100 d-flex justify-content-center`}
                >
                  <img
                    loading="lazy"
                    src={
                      !!OurServicesPageData?.intro?.image
                        ? process.env.REACT_APP_CLOUDFLARE_URL +
                          OurServicesPageData?.intro?.image
                        : ""
                    }
                    alt="img"
                  />
                </div>
              </div>
            </div>
          )}

          {!!OurServicesPageData?.our_services?.show && (
            <div className={styles.ourServicesBody}>
              <div
                className={`container-fluid ${styles.body} mt-5 d-flex flex-column justify-content-around align-items-center text-center`}
              >
                <div
                  className={`row w-50 ${styles.top} flex-column align-items-center justify-content-center`}
                >
                  <p className={`${styles.ourServicesTag}`}>
                    {OurServicesPageData?.our_services?.tag}
                  </p>
                  <h3 className={styles.header}>
                    {OurServicesPageData?.our_services?.header}
                  </h3>
                </div>

                <div className={`row w-100 ${styles.bottom}`}>
                  {!!OurServicesPageData?.our_services?.services &&
                    OurServicesPageData?.our_services?.services?.map(
                      (value, index) => (
                        <div
                          key={index}
                          className={`${index === 7 ? "col-8" : "col-4"}`}
                        >
                          <div
                            className={`${styles.cardSize} w-100 d-flex flex-row align-items-start`}
                          >
                            <img
                              loading="lazy"
                              className={styles.image}
                              src={
                                !!value?.icon
                                  ? process.env.REACT_APP_CLOUDFLARE_URL +
                                    value?.icon
                                  : ""
                              }
                              alt="Web icon"
                            />
                            <div className="d-flex flex-column align-items-start pl-2 mb-3">
                              <Link
                                className={`${styles.servicesFontWeight}`}
                                to={!!value?.to ? value?.to : "#"}
                              >
                                {value?.title}
                              </Link>

                              {!!value?.list ? (
                                <>
                                  <div className="d-flex flex-row w-100 align-items-start">
                                    {value?.list?.map((value, index) => (
                                      <p
                                        key={index}
                                        className={`${styles.servicesFontSize}`}
                                      >
                                        <i className="bi bi-dot"></i>
                                        {value?.text}
                                      </p>
                                    ))}
                                    <p className={`${styles.servicesFont}`}>
                                      Learn More{" "}
                                      <i className="bi bi-arrow-right"></i>
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <p className={`${styles.servicesFont}`}>
                                  Learn More{" "}
                                  <i className="bi bi-arrow-right ml-1 align-middle"></i>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          )}

          {!!OurServicesPageData?.portfolio?.show && (
            <Portfolio untag portfolioData={OurServicesPageData?.portfolio} />
          )}

          {!!OurServicesPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={OurServicesPageData?.testimonials}
              design={{
                background: "#53389E",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#E9D7FE",
                brand: "#FFFFFF",
              }}
            />
          )}

          {!!OurServicesPageData?.faq?.show && (
            <FAQs faqData={OurServicesPageData?.faq} />
          )}

          {!!OurServicesPageData?.contact?.show && (
            <Contact data={OurServicesPageData?.contact} />
          )}
        */}
        </>
      )}
    </>
  );
}

export default OurServices;
