import {
  WEBDEV_SERVICES_PAGE_LOADER,
  WEBDEV_SERVICES_PAGE_SUCCESS,
  WEBDEV_SERVICES_PAGE_FAILURE,
} from "../../constants/Services/WebDevServicesConstants";
import { getPage } from "../../../API/Api";

const success = (payload) => ({
  type: WEBDEV_SERVICES_PAGE_SUCCESS,
  payload,
});

const failure = (error) => ({
  type: WEBDEV_SERVICES_PAGE_FAILURE,
  error,
});

export const WebDevServicesData = () => (dispatch) => {
  dispatch({ type: WEBDEV_SERVICES_PAGE_LOADER });
  getPage("page", {
    filter: {
      Page_Name: {
        _eq: "Web Development Services",
      },
    },
    fields: "*.*.*.*.*.*.*",
  })
    .then((response) => {
      if (!!response?.data?.length) dispatch(success(response?.data[0]));
      else dispatch(failure("Something Went Wrong"));
    })
    .catch((err) => {
      dispatch(failure(err?.errors));
    });
};
