import { combineReducers } from "redux";
import { HomeReducer } from "./Home/HomeReducer";
import { OurServicesReducer } from "./Services/OurServicesReducer";
import { ContentServicesReducer } from "./Services/ContentServicesReducer";
import { SeoServicesReducer } from "./Services/SEOServicesReducer";
import { SmmServicesReducer } from "./Services/SMMServicesReducer";
import { UiUxServicesReducer } from "./Services/UiUxServicesReducer";
import { MadServicesReducer } from "./Services/MADServicesReducer";
import { PmServicesReducer } from "./Services/PMServicesReducer";
import { SdServicesReducer } from "./Services/SDServicesReducer";
import { SmServicesReducer } from "./Services/SMServicesReducer";
import { WebDevServicesReducer } from "./Services/WebDevServicesReducer";
import { NycSdServicesReducer } from "./City/NycSdServicesReducer";
import { NycSeoServicesReducer } from "./City/NycSeoServicesReducer";
import { NycUiUxServicesReducer } from "./City/NycUiUxServicesReducer";
import { NycWebDevServicesReducer } from "./City/NycWebDevServicesReducer";
import { TorontoSdServicesReducer } from "./City/TorontoSdServicesReducer";
import { TorontoSeoServicesReducer } from "./City/TorontoSeoServicesReducer";
import { TorontoUiUxServicesReducer } from "./City/TorontoUiUxServicesReducer";
import { TorontoWebDevServicesReducer } from "./City/TorontoWebDevServicesReducer";
import { IndustrySeoReducer } from "./Industries/IndsutrySeoReducer";
import { IndustryWebDevReducer } from "./Industries/IndustryWebDevReducer";
import { NoCodeLandingReducer } from "./NoCode/NoCodeLandingReducer";
import { NoCodeServicesReducer } from "./NoCode/NoCodeServicesReducer";
import { AboutUsReducer } from "./About Us/AboutUsReducer";
import { IoTReducer } from "./IoT/IoTReducer";
import { NavbarReducer } from "./Navbar/NavbarReducer";
import { FooterReducer } from "./Footer/FooterReducer";
import { BlogsReducer } from "./Blog/BlogsReducer";
import { SingleBlogReducer } from "./Blog/SingleBlogReducer";
import { RelatedBlogsReducer } from "./Blog/RelatedBlogsReducer";

const rootReducer = combineReducers({
  HomeReducer,
  OurServicesReducer,
  ContentServicesReducer,
  SeoServicesReducer,
  SmmServicesReducer,
  UiUxServicesReducer,
  MadServicesReducer,
  PmServicesReducer,
  SdServicesReducer,
  SmServicesReducer,
  WebDevServicesReducer,
  NycSdServicesReducer,
  NycSeoServicesReducer,
  NycUiUxServicesReducer,
  NycWebDevServicesReducer,
  TorontoSdServicesReducer,
  TorontoSeoServicesReducer,
  TorontoUiUxServicesReducer,
  TorontoWebDevServicesReducer,
  IndustrySeoReducer,
  IndustryWebDevReducer,
  NoCodeLandingReducer,
  NoCodeServicesReducer,
  AboutUsReducer,
  IoTReducer,
  NavbarReducer,
  FooterReducer,
  BlogsReducer,
  SingleBlogReducer,
  RelatedBlogsReducer,
});

export default rootReducer;
