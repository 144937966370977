import React from "react";
import { Link } from "react-router-dom";
import styles from "./portfolio.module.scss";

function Portfolio({ untag, portfolioData }) {
  return (
    <div
      className={`container-fluid ${styles.body} d-flex flex-column align-items-center`}
    >
      {!!untag ? (
        <></>
      ) : (
        <p className={`border text-center ${styles.portfolioTag}`}>
          {!!portfolioData?.tag && portfolioData?.tag}
        </p>
      )}
      <h3 className={styles.header}>
        {!!portfolioData?.header && portfolioData?.header}
      </h3>
      <div className={`row w-100 ${styles.spaceEvenly}`}>
        {!!portfolioData?.portfolios &&
          portfolioData?.portfolios?.map((value, index) => (
            <div className="col-md-4 d-flex justify-content-center" key={index}>
              <div className={`position-relative ${styles.portfolioCard}`}>
                <Link
                  to={!!value?.link ? value?.link : "#"}
                  target={!!value?.link ? "_blank" : "_self"}
                >
                  <img
                    loading="lazy"
                    src={`${process.env.REACT_APP_CLOUDFLARE_URL}${value?.image}`}
                    alt="portfolio"
                    className="w-100 h-100"
                  />
                </Link>
                <div
                  className={`${styles.bottomStrip} h-25 w-100 bg-white position-absolute fixed-bottom`}
                ></div>
                <div className="h-25 w-100 position-absolute fixed-bottom d-flex flex-column justify-content-center align-items-center">
                  <Link
                    className={`${styles.portfolioName}`}
                    to={!!value?.link ? value?.link : "#"}
                    target={!!value?.link ? "_blank" : "_self"}
                  >
                    {value?.header}
                  </Link>
                  <Link
                    className={`${styles.portfolioDesig}`}
                    to={!!value?.link ? value?.link : "#"}
                    target={!!value?.link ? "_blank" : "_self"}
                  >
                    {value?.title}
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Portfolio;
