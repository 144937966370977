import React, { useEffect } from "react";
import ContactA from "../../modules/contact/ContactA";
import Quote from "../../modules/contact/Quote";
import Technology from "../../modules/services/Technology";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import Portfolio from "../../modules/portfolio/Portfolio";
import Healthcare from "../../modules/healthcare/Healthcare";
import HealthcareIntro from "../../modules/healthcare/HealthcareIntro";
import FAQsB from "../../modules/faq/FAQsB";
import WhyHealthcare from "../../modules/healthcare/WhyHealthcare";
import { useDispatch, useSelector } from "react-redux";
import { IndustrySeoData } from "../../store/actions/Industries/IndustrySeoData";
import { Loader } from "../../extra/loader/Loader";
import Page404 from "../placeholders/404Page";
import { Helmet } from "react-helmet";

function IndustriesSeoServices() {
  const { isLoading, IndustrySeoPageData, errorMessage } = useSelector(
    (state) => state?.IndustrySeoReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(IndustrySeoData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "healthcareIntro":
        return (
          <>
            {!!IndustrySeoPageData?.healthcareIntro?.show && (
              <HealthcareIntro
                Intro={IndustrySeoPageData?.healthcareIntro}
                moreRow
              />
            )}
          </>
        );

      case "healthcareBenefits":
        return (
          <>
            {!!IndustrySeoPageData?.healthcareBenefits?.show && (
              <Healthcare Benefits={IndustrySeoPageData?.healthcareBenefits} />
            )}
          </>
        );

      case "startemHealthcare":
        return (
          <>
            {!!IndustrySeoPageData?.startemHealthcare?.show && (
              <WhyHealthcare startem={IndustrySeoPageData?.startemHealthcare} />
            )}
          </>
        );

      case "faq":
        return (
          <>
            {!!IndustrySeoPageData?.faq?.show && (
              <FAQsB faqData={IndustrySeoPageData?.faq} />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!IndustrySeoPageData?.portfolio?.show && (
              <Portfolio portfolioData={IndustrySeoPageData?.portfolio} />
            )}
            <div style={{ marginBottom: "5vw" }}></div>
          </>
        );

      case "testimonials":
        return (
          <>
            {!!IndustrySeoPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={IndustrySeoPageData?.testimonials}
                design={{
                  background: "#101828",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#E9D7FE",
                  brand: "#FFFFFF",
                }}
                padding
              />
            )}

            <div style={{ marginBottom: "8vw" }}></div>
          </>
        );

      case "technology":
        return (
          <>
            {" "}
            {!!IndustrySeoPageData?.technology?.show && (
              <Technology
                technology={IndustrySeoPageData?.technology}
                containerClass={"body3"}
              />
            )}
          </>
        );

      case "contact":
        return (
          <>
            {!!IndustrySeoPageData?.contact?.show && (
              <Quote
                design={{
                  background: "#2D2D2D",
                  header: "#FFFFFF",
                  text: "#E9D7FE",
                  botton: "#7F56D9",
                  bottom: 1,
                  row: true,
                }}
                content={{
                  header:
                    !!IndustrySeoPageData?.contact?.qHeader &&
                    IndustrySeoPageData?.contact?.qHeader,
                  text:
                    !!IndustrySeoPageData?.contact?.qText &&
                    IndustrySeoPageData?.contact?.qText,
                  button:
                    !!IndustrySeoPageData?.contact?.qButton &&
                    IndustrySeoPageData?.contact?.qButton,
                }}
              />
            )}

            {!!IndustrySeoPageData?.contact?.show && (
              <ContactA Contact={IndustrySeoPageData?.contact} reverse />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>
              Healthcare SEO Services for Medical Facilities | Startem
            </title>
            <meta
              name="description"
              content="Startem is a leading healthcare SEO agency providing tailored SEO strategies for medical facilities worldwide. Our services include patient-centric research, on-page and off-page SEO, technical SEO, internal and backlinking, and search console optimization. With our individualized solutions, we help healthcare organizations improve their online presence and reach their business objectives. Contact us today to learn more about our healthcare SEO services."
            />
            <link
              rel="canonical"
              href="https://www.startem.io/industry-seo-services"
            />
          </Helmet>

          {!!IndustrySeoPageData?.sequence &&
            IndustrySeoPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*

          {!!IndustrySeoPageData?.healthcareIntro?.show && (
            <HealthcareIntro
              Intro={IndustrySeoPageData?.healthcareIntro}
              moreRow
            />
          )}

          {!!IndustrySeoPageData?.healthcareBenefits?.show && (
            <Healthcare Benefits={IndustrySeoPageData?.healthcareBenefits} />
          )}

          {!!IndustrySeoPageData?.startemHealthcare?.show && (
            <WhyHealthcare startem={IndustrySeoPageData?.startemHealthcare} />
          )}

          {!!IndustrySeoPageData?.faq?.show && (
            <FAQsB faqData={IndustrySeoPageData?.faq} />
          )}

          {!!IndustrySeoPageData?.portfolio?.show && (
            <Portfolio portfolioData={IndustrySeoPageData?.portfolio} />
          )}
          <div style={{ marginBottom: "5vw" }}></div>

          {!!IndustrySeoPageData?.portfolio?.show && (
            <TestimonialsB
              Testimonials={IndustrySeoPageData?.testimonials}
              design={{
                background: "#101828",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#E9D7FE",
                brand: "#FFFFFF",
              }}
              padding
            />
          )}

          <div style={{ marginBottom: "8vw" }}></div>

          {!!IndustrySeoPageData?.technology?.show && (
            <Technology
              technology={IndustrySeoPageData?.technology}
              containerClass={"body3"}
            />
          )}

          {!!IndustrySeoPageData?.contact?.show && (
            <Quote
              design={{
                background: "#53389E",
                header: "#FFFFFF",
                text: "#E9D7FE",
                botton: "#7F56D9",
                bottom: 1,
              }}
              content={{
                header:
                  !!IndustrySeoPageData?.contact?.qHeader &&
                  IndustrySeoPageData?.contact?.qHeader,
                text:
                  !!IndustrySeoPageData?.contact?.qText &&
                  IndustrySeoPageData?.contact?.qText,
                button:
                  !!IndustrySeoPageData?.contact?.qButton &&
                  IndustrySeoPageData?.contact?.qButton,
              }}
            />
          )}

          {!!IndustrySeoPageData?.contact?.show && (
            <ContactA Contact={IndustrySeoPageData?.contact} reverse />
          )}
        */}
        </>
      )}
    </>
  );
}

export default IndustriesSeoServices;
