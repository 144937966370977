import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../extra/loader/Loader";
import ContactA from "../../modules/contact/ContactA";
import Quote from "../../modules/contact/Quote";
import Portfolio from "../../modules/portfolio/Portfolio";
import Description from "../../modules/seo/Description";
import IncludedSeoB from "../../modules/seo/IncludedSeoB";
import IntroSeo from "../../modules/seo/IntroSeo";
import Seo from "../../modules/seo/Seo";
import TargetedSeo from "../../modules/seo/TargetedSeo";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import { TorontoSdServicesData } from "../../store/actions/City/TorontoSdServicesData";
import Page404 from "../placeholders/404Page";

function TorontoSdServices() {
  const { isLoading, TorontoSdServicesPageData, errorMessage } = useSelector(
    (state) => state?.TorontoSdServicesReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(TorontoSdServicesData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "intro":
        return (
          <>
            {" "}
            {!!TorontoSdServicesPageData?.intro?.show && (
              <IntroSeo Intro={TorontoSdServicesPageData?.intro} />
            )}
          </>
        );

      case "our_services":
        return (
          <>
            {!!TorontoSdServicesPageData?.our_services?.show && (
              <IncludedSeoB
                Services={TorontoSdServicesPageData?.our_services}
              />
            )}
          </>
        );

      case "targeted_services":
        return (
          <>
            {" "}
            {!!TorontoSdServicesPageData?.targeted_services?.show && (
              <TargetedSeo
                Targeted={TorontoSdServicesPageData?.targeted_services}
              />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!TorontoSdServicesPageData?.portfolio?.show && (
              <Portfolio portfolioData={TorontoSdServicesPageData?.portfolio} />
            )}
          </>
        );

      case "description":
        return (
          <>
            {!!TorontoSdServicesPageData?.description?.show && (
              <Description
                Description={TorontoSdServicesPageData?.description}
                design={{
                  background: "#53389E",
                  header: "#FFFFFF",
                  text1: "#EFEDF0",
                  text2: "#EFEDF0",
                }}
                content={{
                  box1: "city-sd/nyc/box1.png",
                  box2: "city-sd/nyc/box2.png",
                }}
              />
            )}
          </>
        );

      case "importance":
        return (
          <>
            {(!!TorontoSdServicesPageData?.importance?.show ||
              !!TorontoSdServicesPageData?.feature?.show) && (
              <Seo
                Importance={TorontoSdServicesPageData?.importance}
                feature={{
                  design: {
                    background: "#101828",
                    text: "#FAF8FC",
                    content: "#FAF8FC",
                    header: "#FFFFFF",
                  },
                  structure: 4,
                }}
                Feature={TorontoSdServicesPageData?.feature}
              />
            )}
          </>
        );

      case "testimonials":
        return (
          <>
            {!!TorontoSdServicesPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={TorontoSdServicesPageData?.testimonials}
                design={{
                  background: "#E4E8EF",
                  tag: "#7F56D9",
                  border: "1.5px solid #7F56D9",
                  header: "#101828",
                  name: "#101828",
                  designation: "#383E4A",
                  brand: "#101828",
                }}
                padding
              />
            )}
          </>
        );

      case "contact":
        
        return (
          <>
            {!!TorontoSdServicesPageData?.contact?.show && (
              <Quote
                design={{
                  background: "#53389E",
                  header: "#F0EBFB",
                  text: "#FFFFFF",
                }}
                content={{
                  header:
                    !!TorontoSdServicesPageData?.contact?.qHeader &&
                    TorontoSdServicesPageData?.contact?.qHeader,
                  text:
                    !!TorontoSdServicesPageData?.contact?.qText &&
                    TorontoSdServicesPageData?.contact?.qText,
                  button:
                    !!TorontoSdServicesPageData?.contact?.qButton &&
                    TorontoSdServicesPageData?.contact?.qButton,
                }}
              />
            )}

            {!!TorontoSdServicesPageData?.contact?.show && (
              <ContactA Contact={TorontoSdServicesPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>
              Toronto Software Development Company | Custom Software Solutions |
              Startem
            </title>
            <meta
              name="description"
              content="Looking for targeted Toronto software development services? Startem offers custom software solutions, including product research, scoping, wire framing, development, and maintenance & support. Contact our Toronto-based software development experts for a free consultation today."
            />
            <link
              rel="canonical"
              href="https://www.startem.io/toronto-sd-services"
            />
          </Helmet>

          {!!TorontoSdServicesPageData?.sequence &&
            TorontoSdServicesPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*

          {!!TorontoSdServicesPageData?.intro?.show && (
            <IntroSeo Intro={TorontoSdServicesPageData?.intro} />
          )}

          {!!TorontoSdServicesPageData?.our_services?.show && (
            <IncludedSeoB Services={TorontoSdServicesPageData?.our_services} />
          )}

          {!!TorontoSdServicesPageData?.targeted_services?.show && (
            <TargetedSeo
              Targeted={TorontoSdServicesPageData?.targeted_services}
            />
          )}

          {!!TorontoSdServicesPageData?.portfolio?.show && (
            <Portfolio portfolioData={TorontoSdServicesPageData?.portfolio} />
          )}

          {!!TorontoSdServicesPageData?.description?.show && (
            <Description
              Description={TorontoSdServicesPageData?.description}
              design={{
                background: "#D0D5DD",
                header: "#121515",
                text1: "#121515",
                text2: "#121515",
              }}
              content={{
                box1: "city-sd/toronto/box1.png",
                box2: "city-sd/toronto/box2.png",
              }}
            />
          )}

          {(!!TorontoSdServicesPageData?.importance?.show ||
            !!TorontoSdServicesPageData?.feature?.show) && (
            <Seo
              Importance={TorontoSdServicesPageData?.importance}
              feature={{
                design: {
                  background: "#53389E",
                  text: "#FAF8FC",
                  content: "#FAF8FC",
                  header: "#FFFFFF",
                },
                structure: 4,
              }}
              Feature={TorontoSdServicesPageData?.feature}
            />
          )}

          {!!TorontoSdServicesPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={TorontoSdServicesPageData?.testimonials}
              design={{
                background: "#101828",
                tag: "#7F56D9",
                border: "1.5px solid #7F56D9",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#B9B9B9",
                brand: "#FFFFFF",
              }}
              padding
            />
          )}

          {!!TorontoSdServicesPageData?.contact?.show && (
            <Quote
              design={{
                background: "#F2F4F8",
                header: "#1F2739",
                text: "#101828",
              }}
              content={{
                header:
                  !!TorontoSdServicesPageData?.contact?.qHeader &&
                  TorontoSdServicesPageData?.contact?.qHeader,
                text:
                  !!TorontoSdServicesPageData?.contact?.qText &&
                  TorontoSdServicesPageData?.contact?.qText,
                button:
                  !!TorontoSdServicesPageData?.contact?.qButton &&
                  TorontoSdServicesPageData?.contact?.qButton,
              }}
            />
          )}

          {!!TorontoSdServicesPageData?.contact?.show && (
            <ContactA Contact={TorontoSdServicesPageData?.contact} />
          )}
        */}
        </>
      )}
    </>
  );
}

export default TorontoSdServices;
