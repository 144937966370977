import React from "react";
import styles from "./whyStreamA.module.scss";

function WhyStartemA({ WhyStartem, imageContainer }) {
  return (
    <div
      className={`w-100 ${styles.body} mt-5 mx-0 row ${
        imageContainer?.position === "right"
          ? "flex-row-reverse " + styles.paddingLeft
          : styles.paddingRight
      }`}
    >
      <div
        className={`col-md-6 ${
          imageContainer?.position === "right" ? "pr-0" : "pl-0"
        }`}
      >
        {!!WhyStartem?.image && (
          <img
            loading="lazy"
            className={`w-100 h-100 ${
              imageContainer?.position === "right"
                ? styles.imgBorder2
                : styles.imgBorder1
            }`}
            src={
              !!WhyStartem?.image
                ? process.env.REACT_APP_CLOUDFLARE_URL + WhyStartem?.image
                : ""
            }
            alt="img"
          />
        )}
      </div>
      <div
        className={`col-md col-12 ${
          imageContainer?.position === "right" ? "pl-0" : ""
        } ${styles.container} d-flex flex-column justify-content-center`}
      >
        <p className={`${styles.tag} border text-center`}>
          {!!WhyStartem?.Tag && WhyStartem?.Tag}
        </p>
        <h3 className={styles.header}>
          {!!WhyStartem?.Header && WhyStartem?.Header}
        </h3>

        {!!WhyStartem?.Features &&
          WhyStartem?.Features?.map((value, index) => (
            <div className={`${styles.box}`} key={index}>
              <hr
                className={`w-100 ${styles.line}`}
                style={{
                  backgroundColor: index === 0 ? "#7F56D9" : "#F2F4F7",
                }}
              />
              <div className="d-flex flex-row align-items-center">
                {!!value?.icon && (
                  <img
                    loading="lazy"
                    src={process.env.REACT_APP_CLOUDFLARE_URL + value?.icon}
                    alt="pic"
                    className={`${styles.imgSize} mr-3`}
                  />
                )}
                <p className={`${styles.text} mb-0`}>{value?.header}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default WhyStartemA;
