import {
  FOOTER_LOADER,
  FOOTER_SUCCESS,
  FOOTER_FAILURE,
} from "../../constants/Footer/FooterConstants";
import { getPage } from "../../../API/Api";

const success = (payload) => ({
  type: FOOTER_SUCCESS,
  payload,
});

const failure = (error) => ({
  type: FOOTER_FAILURE,
  error,
});

export const FooterDataList = () => (dispatch) => {
  dispatch({ type: FOOTER_LOADER });
  getPage("footer", {
    limit: -1,
  })
    .then((response) => {
      dispatch(success(response?.data[0]));
    })
    .catch((err) => {
      dispatch(failure(err?.errors));
    });
};
