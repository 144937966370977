import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styles from "./blogSingle.module.scss";
import Card from "../../modules/card/Card";
import { Loader } from "../../extra/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { SingleBlogData } from "../../store/actions/Blog/SingleBlogData";
import { BlogsDataList } from "../../store/actions/Blog/BlogsDataList";
import { RelatedBlogsData } from "../../store/actions/Blog/RelatedBlogsData";

function BlogSingle() {
  const { id } = useParams("id");

  const {
    isLoading: singleBlogLoader,
    SingleBlog,
    errorMessage: singleBlogError,
  } = useSelector((state) => state?.SingleBlogReducer);

  const {
    isLoading: BlogLoader,
    BlogsData,
    // errorMessage: BlogError,
  } = useSelector((state) => state?.BlogsReducer);

  const {
    // isLoading: relatedBlogLoader,
    RelatedBlogs,
    // errorMessage: relatedBlogError,
  } = useSelector((state) => state?.RelatedBlogsReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [id]);

  useEffect(() => {
    dispatch(SingleBlogData(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(BlogsDataList(5));
  }, [dispatch]);

  useEffect(() => {
    if (!singleBlogLoader && !!SingleBlog && !singleBlogError?.length)
      dispatch(RelatedBlogsData(id, SingleBlog?.category, 3));
  }, [SingleBlog, dispatch, id, singleBlogError?.length, singleBlogLoader]);

  return (
    <div className="d-flex flex-column align-items-center w-100">
      <div
        className={`container-fluid ${styles.body} d-flex flex-column align-items-center`}
      >
        {!!singleBlogLoader ? (
          <Loader />
        ) : (
          <>
            <div
              className={`${styles.top} w-100 d-flex flex-column align-items-center`}
            >
              <p className={`${styles.title}`}>
                {SingleBlog?.category}{" "}
                <span className="mx-2 d-inline-block">━</span>{" "}
                {SingleBlog?.author}
              </p>
              <h2 className={`${styles.header} text-center`}>
                {SingleBlog?.title}
              </h2>
              <div className={styles.imgBox}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    boxShadow: "1px 5px 15px -3px rgba(0,0,0,0.75)",
                  }}
                  loading="lazy"
                  src={
                    SingleBlog?.image
                      ? process.env.REACT_APP_CLOUDFLARE_URL + SingleBlog?.image
                      : ""
                  }
                  alt="blog thumb"
                />
              </div>
            </div>

            <div className={`${styles.mid}`}>
              <div
                dangerouslySetInnerHTML={{ __html: SingleBlog?.description }}
              ></div>
            </div>

            {!!RelatedBlogs?.length && (
              <div className="row justify-content-start my-3">
                <h2 className="col">Related Blogs</h2>
              </div>
            )}
            <div className={`row mx-0 ${styles.bottom} `}>
              {!!RelatedBlogs?.length &&
                RelatedBlogs?.map((value, index) => (
                  <div
                    key={index}
                    className={`${index < 2 ? "col-6" : "col-12"} mb-4`}
                  >
                    <Link to={`/blog/${value?.id}`}>
                      <div
                        className="position-relative"
                        style={{ height: index < 2 ? "30vw" : "50vw" }}
                      >
                        <img
                          loading="lazy"
                          className={`img-fluid ${index === 2 ? "w-100" : ""} `}
                          src={
                            value?.image
                              ? process.env.REACT_APP_CLOUDFLARE_URL +
                                value?.image
                              : ""
                          }
                          alt="portfolio thumb"
                        />
                        <p
                          className={`position-absolute text-center ${styles.title}`}
                        >
                          {value?.title}
                        </p>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>

      {!!BlogLoader ? (
        <Loader />
      ) : (
        !!BlogsData.length && (
          <>
            <div
              className="section border-top pb-0 text-dark"
              style={{
                marginTop: "6vw",
                width: "84%",
                marginBottom: "4vw",
              }}
            ></div>
            <Card header={"Latest Blogs"} blogs={BlogsData} />
          </>
        )
      )}
    </div>
  );
}

export default BlogSingle;
