import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { NavbarDataList } from "../../store/actions/Navbar/NavbarData";
import styles from "./navigationBarB.module.scss";

function NavigationbarB() {
  const [navbarMenu, setNavbarMenu] = useState(false);
  const [radius, setRadius] = useState(false);

  const { isLoading, NavbarData, errorMessage } = useSelector(
    (state) => state?.NavbarReducer
  );
  const dispatch = useDispatch();

  const ref = useRef();

  const location = useLocation();
  useEffect(() => {
    setNavbarMenu(false);
  }, [location]);

  useEffect(() => {
    dispatch(NavbarDataList());
  }, [dispatch]);

  const handleClick = (event) => {
    if (!ref?.current?.contains(event?.target)) {
      setNavbarMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("event", handleClick);
    };
  }, []);

  useEffect(() => {
    if (!navbarMenu) {
      setTimeout(() => {
        setRadius(navbarMenu);
      }, 700);
    } else {
      setRadius(navbarMenu);
    }
  }, [navbarMenu]);

  return (
    <>
      {!isLoading && (
        <div ref={ref} className={`${styles.body}`}>
          <div
            className={`${styles.menu} ${!!navbarMenu ? styles.menuOpen : ""}`}
          >
            <div
              className={`${styles.menuTop} ${
                !!radius ? styles.removeBottomRadius : ""
              } d-flex align-items-center justify-content-between`}
              style={{ backgroundColor: !!navbarMenu ? "#53389E" : "#7F56D9" }}
            >
              <Link to="/">
                <img
                  loading="lazy"
                  src={
                    !!NavbarData?.logo
                      ? process.env.REACT_APP_CLOUDFLARE_URL + NavbarData?.logo
                      : ""
                  }
                  alt="logo"
                  className={`${styles.menuTitle} h-100`}
                />
              </Link>

              <div
                className={`d-flex flex-row align-items-center ${styles.menuNavTop}`}
              >
                {!!NavbarData?.top_content &&
                  NavbarData?.top_content?.map((value, index) =>
                    !!value?.to ? (
                      <Link
                        key={index}
                        className={`${styles.lineLink}`}
                        to={value?.to}
                      >
                        {value?.text}
                      </Link>
                    ) : (
                      <p
                        key={index}
                        className={`my-0 ${styles.lineLink}`}
                        onClick={() => setNavbarMenu(true)}
                      >
                        {value?.text}
                      </p>
                    )
                  )}
              </div>

              <div className={`${styles.menuSide}`}>
                <Link className={`${styles.lineLink}`} to="#">
                  {NavbarData?.rightTag}
                </Link>
                <i
                  className={`bi bi-list ${styles.menuBar}`}
                  onClick={() => setNavbarMenu(true)}
                ></i>
              </div>
            </div>
            <div
              className={`${styles.menuContentWrap}`}
              onClick={() => setNavbarMenu(false)}
            >
              <div
                className={`${styles.menuContent} d-flex justify-content-center`}
                style={{
                  transform: `translate(0px, ${!!navbarMenu ? "0%" : "-100%"})`,
                  backgroundColor: !!navbarMenu ? "#53389E" : "#7F56D9",
                }}
              >
                <div className={`row justify-content-between`}>
                  <div
                    className={`col-md-3 d-flex align-items-end ${styles.menuTag}`}
                  >
                    <h3 className={`${styles.menuTagline}`}>Start'em</h3>
                  </div>

                  <div className="col-10 col-md-6">
                    <div className="w-100 row align-items-start justify-content-start">
                      <div
                        className={`${styles.column} ${styles.columnHider} col-12 col-md-6 d-flex flex-column`}
                      >
                        {!!NavbarData?.top_content &&
                          NavbarData?.top_content?.map(
                            (value, index) =>
                              !!value?.to && (
                                <Link
                                  key={index}
                                  className={`${styles.header}`}
                                  to={value?.to}
                                >
                                  {value?.text}
                                </Link>
                              )
                          )}
                      </div>

                      {!!NavbarData?.links &&
                        NavbarData?.links?.map((value, index) => (
                          <div
                            key={index}
                            className={`${styles.column} col-12 col-md-6 d-flex flex-column`}
                          >
                            <h4 className={`${styles.header}`}>
                              {value?.header}
                            </h4>
                            {!!value?.pages &&
                              value?.pages?.map((value2, index2) => (
                                <Link
                                  key={index2}
                                  className={`${styles.link}`}
                                  to={value2?.to}
                                >
                                  {value2?.name}
                                </Link>
                              ))}
                          </div>
                        ))}
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>

                  <div className="col-2 col-md-2">
                    <div
                      className={`${styles.menuBack} ${styles.unbutton}`}
                      onClick={() => setNavbarMenu(false)}
                    >
                      <svg
                        width="10"
                        height="182"
                        viewBox="0 0 10 121"
                        preserveAspectRatio="xMidYMin meet"
                        style={{ fill: "white" }}
                      >
                        <path d="M5 0 .5 9h9L5 0Zm.5 120.5V8h-1v113h1v-.5Z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NavigationbarB;
