import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../extra/loader/Loader";
import ContactA from "../../modules/contact/ContactA";
import Quote from "../../modules/contact/Quote";
import Portfolio from "../../modules/portfolio/Portfolio";
import Description from "../../modules/seo/Description";
import IncludedSeo from "../../modules/seo/IncludedSeo";
import IntroSeo from "../../modules/seo/IntroSeo";
import Seo from "../../modules/seo/Seo";
import TargetedSeo from "../../modules/seo/TargetedSeo";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import { TorontoSeoServicesData } from "../../store/actions/City/TorontoSeoServicesData";
import Page404 from "../placeholders/404Page";

function TorontoSeoServices() {
  const { isLoading, TorontoSeoServicesPageData, errorMessage } = useSelector(
    (state) => state?.TorontoSeoServicesReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(TorontoSeoServicesData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "intro":
        return (
          <>
            {" "}
            {!!TorontoSeoServicesPageData?.intro?.show && (
              <IntroSeo Intro={TorontoSeoServicesPageData?.intro} />
            )}
          </>
        );

      case "our_services":
        return (
          <>
            {!!TorontoSeoServicesPageData?.our_services?.show && (
              <IncludedSeo
                Services={TorontoSeoServicesPageData?.our_services}
              />
            )}
          </>
        );

      case "targeted_services":
        return (
          <>
            {" "}
            {!!TorontoSeoServicesPageData?.targeted_services?.show && (
              <TargetedSeo
                Targeted={TorontoSeoServicesPageData?.targeted_services}
              />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!TorontoSeoServicesPageData?.portfolio?.show && (
              <Portfolio
                portfolioData={TorontoSeoServicesPageData?.portfolio}
              />
            )}
          </>
        );

      case "description":
        return (
          <>
            {!!TorontoSeoServicesPageData?.description?.show && (
              <Description
                Description={TorontoSeoServicesPageData?.description}
                design={{
                  background: "#53389E",
                  header: "#FFFFFF",
                  text1: "#EFEDF0",
                  text2: "#EFEDF0",
                }}
                content={{
                  box1: "city-sd/nyc/box1.png",
                  box2: "city-sd/nyc/box2.png",
                }}
              />
            )}
          </>
        );

      case "importance":
        return (
          <>
            {(!!TorontoSeoServicesPageData?.importance?.show ||
              !!TorontoSeoServicesPageData?.feature?.show) && (
              <Seo
                Importance={TorontoSeoServicesPageData?.importance}
                feature={{
                  design: {
                    background: "#101828",
                    text: "#FAF8FC",
                    content: "#FAF8FC",
                    header: "#FFFFFF",
                  },
                  structure: 4,
                }}
                Feature={TorontoSeoServicesPageData?.feature}
              />
            )}
          </>
        );

      case "testimonials":
        return (
          <>
            {!!TorontoSeoServicesPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={TorontoSeoServicesPageData?.testimonials}
                design={{
                  background: "#E4E8EF",
                  tag: "#7F56D9",
                  border: "1.5px solid #7F56D9",
                  header: "#101828",
                  name: "#101828",
                  designation: "#383E4A",
                  brand: "#101828",
                }}
                padding
              />
            )}
          </>
        );

      case "contact":
        
        return (
          <>
            {!!TorontoSeoServicesPageData?.contact?.show && (
              <Quote
                design={{
                  background: "#53389E",
                  header: "#F0EBFB",
                  text: "#FFFFFF",
                }}
                content={{
                  header:
                    !!TorontoSeoServicesPageData?.contact?.qHeader &&
                    TorontoSeoServicesPageData?.contact?.qHeader,
                  text:
                    !!TorontoSeoServicesPageData?.contact?.qText &&
                    TorontoSeoServicesPageData?.contact?.qText,
                  button:
                    !!TorontoSeoServicesPageData?.contact?.qButton &&
                    TorontoSeoServicesPageData?.contact?.qButton,
                }}
              />
            )}

            {!!TorontoSeoServicesPageData?.contact?.show && (
              <ContactA Contact={TorontoSeoServicesPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>
              Boost Your Online Presence with Startem - Toronto's #1 SEO Company
            </title>
            <meta
              name="description"
              content="Startem is a leading Toronto-based SEO company that offers customized search engine optimization services to businesses of all sizes. Our team of SEO experts specializes in on-page and off-page optimization, consultation, reporting, and more. Get in touch with us today to improve your website's search engine ranking and online visibility."
            />
            <link
              rel="canonical"
              href="https://www.startem.io/toronto-seo-services"
            />
          </Helmet>

          {!!TorontoSeoServicesPageData?.sequence &&
            TorontoSeoServicesPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*
          {!!TorontoSeoServicesPageData?.intro?.show && (
            <IntroSeo Intro={TorontoSeoServicesPageData?.intro} />
          )}

          {!!TorontoSeoServicesPageData?.our_services?.show && (
            <IncludedSeo Services={TorontoSeoServicesPageData?.our_services} />
          )}

          {!!TorontoSeoServicesPageData?.targeted_services?.show && (
            <TargetedSeo
              Targeted={TorontoSeoServicesPageData?.targeted_services}
            />
          )}

          {!!TorontoSeoServicesPageData?.portfolio?.show && (
            <Portfolio portfolioData={TorontoSeoServicesPageData?.portfolio} />
          )}

          {!!TorontoSeoServicesPageData?.description?.show && (
            <Description
              Description={TorontoSeoServicesPageData?.description}
              design={{
                background: "#121515",
                header: "#FFFFFF",
                text1: "#EFEDF0",
                text2: "#EFEDF0",
              }}
              content={{
                box1: "city-seo/toronto/box1.png",
                box2: "city-seo/toronto/box2.png",
              }}
            />
          )}

          {(!!TorontoSeoServicesPageData?.importance?.show ||
            !!TorontoSeoServicesPageData?.feature?.show) && (
            <Seo
              Importance={TorontoSeoServicesPageData?.importance}
              feature={{
                design: {
                  background: "#53389E",
                  text: "#FAF8FC",
                  content: "#EFF1F4",
                  header: "#FFFFFF",
                  span: "#101828",
                },
                structure: 1,
              }}
              Feature={TorontoSeoServicesPageData?.feature}
            />
          )}

          {!!TorontoSeoServicesPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={TorontoSeoServicesPageData?.testimonials}
              design={{
                background: "#101828",
                tag: "#7F56D9",
                border: "1.5px solid #7F56D9",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#B9B9B9",
                brand: "#FFFFFF",
              }}
              padding
            />
          )}

          {!!TorontoSeoServicesPageData?.contact?.show && (
            <Quote
              design={{
                background: "#F2F4F8",
                header: "#1f2739",
                text: "#101828",
              }}
              content={{
                header:
                  !!TorontoSeoServicesPageData?.contact?.qHeader &&
                  TorontoSeoServicesPageData?.contact?.qHeader,
                text:
                  !!TorontoSeoServicesPageData?.contact?.qText &&
                  TorontoSeoServicesPageData?.contact?.qText,
                button:
                  !!TorontoSeoServicesPageData?.contact?.qButton &&
                  TorontoSeoServicesPageData?.contact?.qButton,
              }}
            />
          )}

          {!!TorontoSeoServicesPageData?.contact?.show && (
            <ContactA Contact={TorontoSeoServicesPageData?.contact} />
          )}
        */}
        </>
      )}
    </>
  );
}

export default TorontoSeoServices;
