import React from "react";
import { Link } from "react-router-dom";
import styles from "./ideas.module.scss";

function Ideas({ Intro }) {
  return (
    <div className={`container-fluid pr-0 ${styles.body}`}>
      <div className="row w-100 justify-content-between mx-0">
        <div className="col-md-6 col-12 px-0 d-flex flex-column justify-content-center align-items-start">
          <p className={`${styles.ideasTag} border text-center`}>
            {Intro?.Tag}
          </p>
          <h2 className={`${styles.heading}`}>{Intro?.Header}</h2>

          <div
            className={styles.list}
            dangerouslySetInnerHTML={{ __html: Intro?.text }}
          ></div>

          <div className="d-flex justify-content-start w-100">
            <button className={`btn ${styles.contactBtn}`}>
              {Intro?.button1}
            </button>
            <Link to="/about-us">
              <button className={`btn ${styles.aboutBtn}`}>
                {Intro?.button2}
              </button>
            </Link>
          </div>
        </div>
        <div className={`col-md-5 px-0 ${styles.imageBox}`}>
          <img
            // loading="lazy"
            data-src="lazy"
            className="w-100 h-100"
            src={`${process.env.REACT_APP_CLOUDFLARE_URL}${Intro?.image}`}
            alt="img"
            style={{ overflow: "visible" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Ideas;
