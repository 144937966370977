import React from "react";
import styles from "./applicationBenefits.module.scss";

function ApplicationBenefits({ IoT, content }) {
  return (
    <div className={`container-fluid ${styles.body}`}>
      <div className="w-100 d-flex flex-column align-items-center">
        <h1 className={styles.header}>
          {!!IoT?.header ? (
            IoT?.header
          ) : (
            <>
              Internet of Things in healthcare
              <br /> applications benefits and challenges
            </>
          )}
        </h1>
        {!!IoT?.benefits
          ? IoT?.benefits?.map((value, index) => (
              <div
                key={index}
                className={`row ${index % 2 === 1 ? "flex-row-reverse" : ""}`}
              >
                <div className={`col-6 ${styles.card}`}>
                  <div>
                    <img
                      loading="lazy"
                      className={styles.icon}
                      src={
                        !!value?.icon
                          ? process.env.REACT_APP_CLOUDFLARE_URL + value?.icon
                          : ""
                      }
                      alt="icon"
                    />
                  </div>
                  <h3 className={styles.heading}>{value?.title}</h3>
                  <p className={styles.text}>{value?.text}</p>
                </div>
                <div className="col-6 d-flex justify-content-center align-items-center">
                  <img
                    loading="lazy"
                    className="w-75"
                    src={
                      !!value?.image
                        ? process.env.REACT_APP_CLOUDFLARE_URL + value?.image
                        : ""
                    }
                    alt="img"
                  />
                </div>
              </div>
            ))
          : content?.map((value, index) => (
              <div
                key={index}
                className={`row ${index % 2 === 1 ? "flex-row-reverse" : ""}`}
              >
                <div className={`col-6 ${styles.card}`}>
                  <div>
                    <img
                      loading="lazy"
                      className={styles.icon}
                      src={require(`../../assets/images/services/IoT/${value?.icon}`)}
                      alt="icon"
                    />
                  </div>
                  <h3 className={styles.heading}>{value?.header}</h3>
                  <p className={styles.text}>{value?.content}</p>
                </div>
                <div className="col-6">
                  <img
                    loading="lazy"
                    className="w-100"
                    src={require(`../../assets/images/services/IoT/${value?.image}`)}
                    alt="img"
                    style={{
                      marginLeft: index % 2 === 1 ? "-17%" : "17%",
                    }}
                  />
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}

export default ApplicationBenefits;
