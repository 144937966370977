import {
  HOME_PAGE_LOADER,
  HOME_PAGE_SUCCESS,
  HOME_PAGE_FAILURE,
  HOME_PAGE_RESET,
} from "../../constants/Home/HomeConstants";

const initialState = {
  isLoading: false,
  HomePageData: {},
  errorMessage: "",
};

export const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOME_PAGE_LOADER:
      return { HomePageData: {}, errorMessage: "", isLoading: true };

    case HOME_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        HomePageData: action.payload,
        errorMessage: "",
      };

    case HOME_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case HOME_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
