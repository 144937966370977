import React, { useEffect, useState } from "react";
import styles from "./faq.module.scss";

function FAQs({ faq, faqData }) {
  const [collapsible, setCollapsible] = useState([]);
  const [limit, setLimit] = useState(3);

  useEffect(() => {
    if (!!faq) setCollapsible([...faq]);
  }, [faq]);

  useEffect(() => {
    if (!!faqData) {
      setCollapsible(
        faqData?.FAQs?.map((value) => ({
          Q: value?.question,
          A: value?.answer,
          collapse: "true",
        }))
      );
    }
  }, [faqData]);

  const handleCollapse = (index) => {
    const temp = [...collapsible];
    temp[index].collapse = !temp[index].collapse;
    setCollapsible([...temp]);
  };

  return (
    <>
      <div
        className={`container-fluid ${styles.body} d-flex flex-column justify-content-center`}
      >
        <div className={`d-flex ${styles.top} flex-column align-items-center`}>
          <p className={`border ${styles.faqTag} text-center faq-tag`}>
            {!!faqData?.tag && faqData?.tag}
          </p>
          <h2 className={`${styles.header}`}>
            {!!faqData?.header && faqData?.header}
          </h2>
          <p className={styles.text}>
            {!!faqData?.description && faqData?.description}
          </p>
        </div>

        <div className={`container-fluid px-0 ${styles.bottom}`}>
          {collapsible?.map(
            (val, index) =>
              index < limit && (
                <div
                  key={index}
                  className={`${styles.box} card-header p-0 bg-white`}
                  onClick={() => handleCollapse(index)}
                >
                  <div className="d-flex w-100 flex-row justify-content-between align-ietms-center">
                    <p className={`mb-0 ${styles.heading}`}>{val?.Q}</p>
                    <i
                      className={`bi ${
                        collapsible[index]?.collapse
                          ? "bi-plus-circle"
                          : "bi-dash-circle"
                      } ${styles.icon} align-middle`}
                    />
                  </div>

                  <p
                    className={`card-body pl-0 ${styles.text} ${
                      collapsible[index]?.collapse ? "collapse" : ""
                    } ml-0`}
                  >
                    {val?.A}
                  </p>
                </div>
              )
          )}
        </div>
        <div className="d-flex justify-content-center">
          {collapsible?.length > 3 && (
            <>
              {limit === 3 ? (
                <button
                  className={`btn border ${styles.loadMore}`}
                  onClick={() => setLimit(collapsible?.length)}
                >
                  SHOW MORE
                </button>
              ) : (
                <button
                  className={`btn border ${styles.loadMore}`}
                  onClick={() => setLimit(3)}
                >
                  SHOW LESS
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default FAQs;
