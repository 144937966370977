import React from "react";
import styles from "./connectA.module.scss";

function ConnectA({ ourServices, bottomBar }) {
  const bodyClass = (bottomBarClass) => {
    switch (bottomBarClass) {
      case "bottomBar1": {
        return styles.body1;
      }

      case "bottomBar2": {
        return styles.body2;
      }

      case "bottomBar3": {
        return styles.body3;
      }

      case "bottomBar4": {
        return styles.body4;
      }

      case "bottomBar5": {
        return styles.body5;
      }

      case "bottomBar6": {
        return styles.body6;
      }

      case "bottomBar7": {
        return styles.body7;
      }

      case "bottomBar8": {
        return styles.body8;
      }

      case "bottomBar9": {
        return styles.body9;
      }

      default: {
        return "";
      }
    }
  };

  return (
    <>
      {!!bottomBar?.structure && bottomBar?.structure === 1 ? (
        <div
          className={`${bodyClass(
            bottomBar?.design
          )} d-flex mt-5 justify-content-between align-items-center`}
        >
          <div className={`${styles.leftCol}`}>
            <h2 className={styles.header}>
              {!!ourServices?.heading && ourServices?.heading}
            </h2>
            <p className={styles.text}>
              {!!ourServices?.text && ourServices?.text}
            </p>
          </div>
          <div className="">
            <button className={`btn ${styles.btn}`}>
              {!!ourServices?.button && ourServices?.button}
            </button>
          </div>
        </div>
      ) : (
        <div
          className={`${bodyClass(
            bottomBar?.design
          )} d-flex flex-column justify-content-center align-items-center text-center`}
        >
          <h2 className={styles.header}>{ourServices?.heading}</h2>
          <p className={styles.text}>{ourServices?.text}</p>
          <button className={`btn ${styles.btn}`}>
            {!!ourServices?.button && ourServices?.button}
          </button>
        </div>
      )}
    </>
  );
}

export default ConnectA;
