import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../modules/footer/Footer";
import NavigationbarB from "../modules/navbar/NavigationbarB";

function Body() {
  return (
    <>
      <NavigationbarB />
      <Outlet />
      <Footer />
    </>
  );
}

export default Body;
