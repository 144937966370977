import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../extra/loader/Loader";
import Contact from "../../modules/contact/Contact";
import FAQs from "../../modules/faq/FAQs";
import IdeasA from "../../modules/ideas/IdeasA";
import Portfolio from "../../modules/portfolio/Portfolio";
import ProcessB from "../../modules/process/ProcessB";
import Services from "../../modules/services/Services";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import WhyStartemA from "../../modules/why startem/WhyStartemA";
import { SeoServicesData } from "../../store/actions/Services/SEOServicesData";
import Page404 from "../placeholders/404Page";

function SeoServices() {
  const { isLoading, SeoServicesPageData, errorMessage } = useSelector(
    (state) => state?.SeoServicesReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(SeoServicesData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "intro":
        return (
          <>
            {!!SeoServicesPageData?.intro?.show && (
              <IdeasA
                Intro={SeoServicesPageData?.intro}
                imageContainer={{
                  position: "left",
                }}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
                btmImg
              />
            )}
          </>
        );

      case "why_startem":
        return (
          <>
            {!!SeoServicesPageData?.why_startem?.show && (
              <WhyStartemA
                WhyStartem={SeoServicesPageData?.why_startem}
                imageContainer={{
                  position: "left",
                }}
              />
            )}
          </>
        );

      case "our_services":
        return (
          <>
            {!!SeoServicesPageData?.our_services?.show && (
              <Services
                ourServices={SeoServicesPageData?.our_services}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
              />
            )}
          </>
        );

      case "process":
        return (
          <>
            {!!SeoServicesPageData?.process?.show && (
              <ProcessB
                process={SeoServicesPageData?.process}
                bottomBar={{
                  structure: 1,
                  design: "bottomBar1",
                }}
              />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!SeoServicesPageData?.portfolio?.show && (
              <Portfolio portfolioData={SeoServicesPageData?.portfolio} />
            )}
          </>
        );

      case "testimonials":
        return (
          <>
            {!!SeoServicesPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={SeoServicesPageData?.testimonials}
                design={{
                  background: "#1D2939",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#96A2B2",
                  brand: "#FFFFFF",
                }}
              />
            )}
          </>
        );

      case "faq":
        return (
          <>
            {!!SeoServicesPageData?.faq?.show && (
              <FAQs faqData={SeoServicesPageData?.faq} />
            )}
          </>
        );

      case "contact":
        return (
          <>
            {!!SeoServicesPageData?.contact?.show && (
              <Contact data={SeoServicesPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>
              Turn Your Ideas into Reality with Startem's SEO Services
            </title>
            <meta
              name="description"
              content="Attract more business and outrank your competitors with Startem's SEO services. Our SEO audit, on-page and off-page optimization, technical SEO, and more will bring you to the limelight. Contact us today!"
            />
            <link rel="canonical" href="https://www.startem.io/seo-services" />
          </Helmet>

          {!!SeoServicesPageData?.sequence &&
            SeoServicesPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*

          {!!SeoServicesPageData?.intro?.show && (
            <IdeasA
              Intro={SeoServicesPageData?.intro}
              imageContainer={{
                objectFit: "cover",
                position: "right",
                right: "0",
              }}
              bottomBar={{ structure: 1, design: "bottomBar2" }}
              btmImg
              transform
            />
          )}

          {!!SeoServicesPageData?.why_startem?.show && (
            <WhyStartemA
              WhyStartem={SeoServicesPageData?.why_startem}
              imageContainer={{
                position: "right",
              }}
            />
          )}

          {!!SeoServicesPageData?.our_services?.show && (
            <Services
              ourServices={SeoServicesPageData?.our_services}
              bottomBar={{ structure: 1, design: "bottomBar2" }}
            />
          )}

          {!!SeoServicesPageData?.process?.show && (
            <ProcessB
              process={SeoServicesPageData?.process}
              bottomBar={{ structure: 1, design: "bottomBar2" }}
            />
          )}

          {!!SeoServicesPageData?.portfolio?.show && (
            <Portfolio portfolioData={SeoServicesPageData?.portfolio} />
          )}

          {!!SeoServicesPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={SeoServicesPageData?.testimonials}
              design={{
                background: "#F9FAFB",
                tag: "#7F56D9",
                border: "1.5px solid #7F56D9",
                header: "#101828",
                name: "#101828",
                designation: "#D0D5DD",
                brand: "#BDBDBD",
              }}
            />
          )}

          {!!SeoServicesPageData?.faq?.show && (
            <FAQs faqData={SeoServicesPageData?.faq} />
          )}

          {!!SeoServicesPageData?.contact?.show && (
            <Contact data={SeoServicesPageData?.contact} />
          )}
        */}
        </>
      )}
    </>
  );
}

export default SeoServices;
