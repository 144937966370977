import React, { useState } from "react";
import styles from "./stepProgressBarB.module.scss";

function StepsProgressBarB({ step1, step2 }) {
  const [step, setStep] = useState(0);

  return (
    <>
      <div className={`${styles.stepperWrapper1}`}>
        {step1 &&
          step1?.map((value, index) => (
            <div
              key={index}
              className={`${styles.stepperItem} ${step >= index + 1 ? "" : ""}`}
            >
              <div
                className={`${styles.stepCounter} ${
                  step >= index ? styles.completed : styles.completed
                }`}
              >
                <i
                  className={`bi ${
                    index < step || step === step1?.length - 1
                      ? `bi-circle-fill ${styles.dotIcon}`
                      : `bi-circle-fill ${styles.dotIcon}`
                  } text-white align-middle`}
                ></i>
              </div>
              <div className="w-75">
                <p
                  className={`text-center ${styles.title} mb-0 ${
                    step === index ? styles.active : ""
                  }`}
                >
                  {value?.title}
                </p>
              </div>
            </div>
          ))}
      </div>

      <div className={`${styles.stepperWrapper2}`}>
        {step2 &&
          step2?.map((value, index) => (
            <div
              key={index + step1.length}
              className={`${styles.stepperItem} ${
                step >= index + step1.length + 1 ? "" : ""
              }`}
            >
              <div
                className={`${styles.stepCounter} ${
                  step >= index + step1.length
                    ? styles.completed
                    : styles.completed
                }`}
              >
                <i
                  className={`bi ${
                    index + step1.length < step || step === step2?.length - 1
                      ? `bi-circle-fill ${styles.dotIcon}`
                      : `bi-circle-fill ${styles.dotIcon}`
                  } text-white align-middle`}
                ></i>
              </div>
              <div className="w-75">
                <p
                  className={`text-center ${styles.title} ${
                    step === index + step1.length ? styles.active : ""
                  }`}
                >
                  {value?.title}
                </p>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default StepsProgressBarB;
