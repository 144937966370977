import React from "react";
import styles from "./IdeasA.module.scss";

function IdeasA({
  imageContainer,
  bottomBar,
  Arrow,
  btmImg,
  transform,
  Intro,
}) {
  const bottomBody = (bottomClass) => {
    switch (bottomClass) {
      case "bottomBar1": {
        return styles.bottom1;
      }
      case "bottomBar2": {
        return styles.bottom2;
      }
      case "bottomBar3": {
        return styles.bottom3;
      }
      case "bottomBar4": {
        return styles.bottom4;
      }
      case "bottomBar5": {
        return styles.bottom5;
      }
      case "bottomBar6": {
        return styles.bottom6;
      }
      case "bottomBar7": {
        return styles.bottom7;
      }
      default: {
        return "";
      }
    }
  };

  return (
    <div className={`containerFluid px-0 position-relative`}>
      <div className="w-100 position-absolute d-flex flex-column">
        <img
          loading="lazy"
          alt="img"
          className="w-50"
          style={{ zIndex: -1 }}
          src={require("../../assets/images/line1.png")}
        />
        {!!Arrow ? (
          <img
            loading="lazy"
            alt="img"
            className={`position-relative ${styles.arrow}`}
            src={Arrow}
          />
        ) : (
          <>
            {!!btmImg && (
              <img
                loading="lazy"
                alt="img"
                className="w-100"
                style={{
                  zIndex: -1,
                  marginTop: "100px",
                  transform: transform ? "scaleX(-1)" : "",
                }}
                src={require("../../assets/images/line2.png")}
              />
            )}
          </>
        )}
      </div>
      <div className={`w-100 ${styles.body}`}>
        <div className={`w-100 row mx-0 ${styles.top}`}>
          <div
            className={`col-md-6 col-12 pl-0 d-flex flex-column justify-content-center ${styles.leftCol}`}
          >
            <p className={`${styles.tag} text-center`}>
              {!!Intro?.Tag && Intro?.Tag}
            </p>
            <h1
              className={`${styles.header}`}
              dangerouslySetInnerHTML={{ __html: Intro?.Header }}
            ></h1>
            <p className={`${styles.text}`}>
              {!!Intro?.description && Intro?.description}
            </p>
            <div>
              <button className={`btn ${styles.btn}`}>
                {!!Intro?.button1 && Intro?.button1}
              </button>
            </div>
          </div>
          <div
            className={`col-md-6 col-12 ${
              styles.rightCol
            } position-relative px-0 d-flex flex-column ${
              imageContainer?.position === "right"
                ? imageContainer?.formDirection === "end"
                  ? "align-items-end"
                  : "align-items-center"
                : "align-items-end"
            } `}
          >
            <div
              className={` position-absolute d-flex justify-content-center p-0 ${
                imageContainer?.position === "right"
                  ? !!imageContainer?.right
                    ? styles.rightZero
                    : styles.positionRight
                  : styles.positionLeft
              }`}
              style={{
                top: "50%",
                transform: "translateY(-50%)",
                width: imageContainer?.width || "70%",
                height: "110%",
              }}
            >
              {!!Intro?.image && (
                <>
                  <img
                    loading="lazy"
                    style={{
                      borderRadius: "1vw",
                      objectFit: imageContainer?.objectFit || "contain",
                    }}
                    className="p-0 w-100 h-100"
                    src={
                      Intro?.image
                        ? process.env.REACT_APP_CLOUDFLARE_URL + Intro?.image
                        : ""
                    }
                    alt="img"
                  />
                  <div
                    style={
                      !!imageContainer?.gradient
                        ? {
                            zIndex: "99999",
                            position: "absolute",
                            left: "50%",
                            height: "100%",
                            width: "50%",
                            background:
                              "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,0,0,0) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%)",
                          }
                        : {}
                    }
                  ></div>
                </>
              )}
            </div>
            <div className={styles.form}>
              <div
                className={`${styles.formBody} py-4 h-100 d-flex flex-column justify-content-center align-items-center`}
              >
                <img
                  loading="lazy"
                  src={require("../../assets/images/services/containing-services/startem-logo.png")}
                  alt="img"
                  className="mb-1"
                />

                <h3 className={styles.header}>Send us a proposal</h3>

                <div className={`form-group ${styles.formBox}`}>
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter your name"
                  />
                </div>

                <div className={`form-group ${styles.formBox}`}>
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter your email"
                  />
                </div>

                <div className={`form-group ${styles.formBox}`}>
                  <label>Message</label>
                  <textarea
                    className={`form-control ${styles.textArea}`}
                    id="messgae"
                    placeholder="Leave us a message..."
                  />
                </div>

                <button className={`btn ${styles.btn}`}>Send Proposal</button>
              </div>
            </div>
          </div>
        </div>

        {!!bottomBar?.structure && bottomBar?.structure === 1 ? (
          <div
            className={`w-100 ${bottomBody(
              bottomBar?.design
            )} text-center d-flex flex-column justify-content-center align-items-center`}
          >
            {!!Intro?.cHeader && (
              <h2 className={`${styles.header}`}>{Intro?.cHeader}</h2>
            )}

            {!!Intro?.cText && (
              <p className={`${styles.text} mt-2`}>{Intro?.cText}</p>
            )}
            <div>
              <button className={`btn ${styles.btn} mt-3`}>
                {!!Intro?.cButton && Intro?.cButton}
              </button>
            </div>
          </div>
        ) : (
          <div
            className={`w-100 ${bottomBody(
              bottomBar?.design
            )} d-flex flex-row justify-content-around align-items-center`}
          >
            <div className={styles.leftCol}>
              {Intro?.cHeader && (
                <h2 className={`${styles.header}`}>
                  {!!Intro?.cHeader && Intro?.cHeader}
                </h2>
              )}

              {Intro?.cText && (
                <p className={`${styles.text} mt-2`}>
                  {!!Intro?.cText && Intro?.cText}
                </p>
              )}
            </div>
            <div>
              <button className={`btn ${styles.btn}`}>
                {!!Intro?.cButton && Intro?.cButton}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default IdeasA;
