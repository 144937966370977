import React, { useEffect } from "react";
import Technology from "../../modules/services/Technology";
import Contact from "../../modules/contact/Contact";
import FAQs from "../../modules/faq/FAQs";
import Portfolio from "../../modules/portfolio/Portfolio";
import ProcessB from "../../modules/process/ProcessB";
import WhyStartemA from "../../modules/why startem/WhyStartemA";
import IdeasA from "../../modules/ideas/IdeasA";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import { useDispatch, useSelector } from "react-redux";
import { MadServicesData } from "../../store/actions/Services/MADServicesData";
import { Loader } from "../../extra/loader/Loader";
import Page404 from "../placeholders/404Page";
import { Helmet } from "react-helmet";

function MobAppDev() {
  const { isLoading, MadServicesPageData, errorMessage } = useSelector(
    (state) => state?.MadServicesReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(MadServicesData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "intro":
        return (
          <>
            {!!MadServicesPageData?.intro?.show && (
              <IdeasA
                Intro={MadServicesPageData?.intro}
                imageContainer={{
                  position: "left",
                }}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
                btmImg
              />
            )}
          </>
        );

      case "why_startem":
        return (
          <>
            {!!MadServicesPageData?.why_startem?.show && (
              <WhyStartemA
                WhyStartem={MadServicesPageData?.why_startem}
                imageContainer={{
                  position: "left",
                }}
              />
            )}
          </>
        );

      case "technology":
        return (
          <>
            {!!MadServicesPageData?.technology?.show && (
              <Technology
                technology={MadServicesPageData?.technology}
                containerClass={"body1"}
                bottomBar={{ structure: 2, design: "bottomBar7" }}
              />
            )}
          </>
        );

      case "process":
        return (
          <>
            {!!MadServicesPageData?.process?.show && (
              <ProcessB
                process={MadServicesPageData?.process}
                bottomBar={{
                  structure: 1,
                  design: "bottomBar1",
                }}
              />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!MadServicesPageData?.portfolio?.show && (
              <Portfolio portfolioData={MadServicesPageData?.portfolio} />
            )}
          </>
        );

      case "testimonials":
        return (
          <>
            {!!MadServicesPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={MadServicesPageData?.testimonials}
                design={{
                  background: "#1D2939",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#96A2B2",
                  brand: "#FFFFFF",
                }}
              />
            )}
          </>
        );

      case "faq":
        return (
          <>
            {!!MadServicesPageData?.faq?.show && (
              <FAQs faqData={MadServicesPageData?.faq} />
            )}
          </>
        );

      case "contact":
        return (
          <>
            {!!MadServicesPageData?.contact?.show && (
              <Contact data={MadServicesPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>
              Premium Mobile Development Services by Startem - Turn Your Ideas
              into Reality
            </title>
            <meta
              name="description"
              content="Looking for top-notch mobile development services? Startem is a startup studio that provides custom mobile app development services using a no-code development workflow and market-leading frameworks. Our process includes requirement analysis, wireframing, UI/UX designing, development, automated UAT, maintenance, and support. Contact us today to take your mobile app to the next level."
            />
            <link
              rel="canonical"
              href="https://www.startem.io/mobile-services"
            />
          </Helmet>

          {!!MadServicesPageData?.sequence &&
            MadServicesPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*
          {!!MadServicesPageData?.intro?.show && (
            <IdeasA
              Intro={MadServicesPageData?.intro}
              imageContainer={{
                position: "right",
                formDirection: "end",
                width: "95%",
              }}
              bottomBar={{ structure: 2, design: "bottomBar6" }}
            />
          )}

          {!!MadServicesPageData?.why_startem?.show && (
            <WhyStartemA
              WhyStartem={MadServicesPageData?.why_startem}
              imageContainer={{
                position: "right",
              }}
            />
          )}

          {!!MadServicesPageData?.technology?.show && (
            <Technology
              technology={MadServicesPageData?.technology}
              containerClass={"body1"}
              bottomBar={{ structure: 2, design: "bottomBar7" }}
            />
          )}

          {!!MadServicesPageData?.process?.show && (
            <ProcessB
              process={MadServicesPageData?.process}
              bottomBar={{ structure: 2, design: "bottomBar1" }}
            />
          )}

          {!!MadServicesPageData?.portfolio?.show && (
            <Portfolio portfolioData={MadServicesPageData?.portfolio} />
          )}

          {!!MadServicesPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={MadServicesPageData?.testimonials}
              design={{
                background: "#101828",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#C0C5D0",
                brand: "#FFFFFF",
              }}
            />
          )}

          {!!MadServicesPageData?.faq?.show && (
            <FAQs faqData={MadServicesPageData?.faq} />
          )}

          {!!MadServicesPageData?.contact?.show && (
            <Contact data={MadServicesPageData?.contact} />
          )}
        */}
        </>
      )}
    </>
  );
}

export default MobAppDev;
