import React, { useEffect, useState } from "react";
import Contact from "../../modules/contact/Contact";
import FAQs from "../../modules/faq/FAQs";
import Portfolio from "../../modules/portfolio/Portfolio";
import Technology from "../../modules/services/Technology";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import WhyStartemA from "../../modules/why startem/WhyStartemA";
import styles from "./noCodeServices.module.scss";
import StepsProgressBarB from "../../modules/step progress bar/StepsProgressBarB";
import { useDispatch, useSelector } from "react-redux";
import { NoCodeServicesData } from "../../store/actions/NoCode/NoCodeServicesData";
import { Loader } from "../../extra/loader/Loader";
import Page404 from "../placeholders/404Page";
import { Helmet } from "react-helmet";

function NoCodeServices() {
  const { isLoading, NoCodeServicesPageData, errorMessage } = useSelector(
    (state) => state?.NoCodeServicesReducer
  );
  const dispatch = useDispatch();

  const [step1, setStep1] = useState([]);
  const [step2, setStep2] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(NoCodeServicesData());
  }, [dispatch]);

  useEffect(() => {
    if (!!NoCodeServicesPageData?.process?.processes?.length) {
      NoCodeServicesPageData?.process?.processes?.forEach((element, index) => {
        if (index < 5) {
          if (index === 0) {
            setStep1([{ title: element?.title }]);
            setStep2([]);
          } else {
            setStep1((prev) => [...prev, { title: element?.title }]);
          }
        } else {
          setStep2((prev) => [...prev, { title: element?.title }]);
        }
      });
    }
  }, [NoCodeServicesPageData]);

  const RenderSequence = (key) => {
    switch (key) {
      case "noCode":
        return (
          <>
            {!!NoCodeServicesPageData?.noCode?.show && (
              <div
                className={`container-fluid ${styles.head} d-flex flex-column align-items-center`}
              >
                <div className={`${styles.top} position-relative w-100`}>
                  <div className={`row mx-0 ${styles.box}`}>
                    <div
                      className={`col-md-7 col-12 d-flex ${styles.leftCol} flex-column align-items-start h-100`}
                    >
                      <p className={`${styles.tag}`}>
                        {!!NoCodeServicesPageData?.noCode?.tag &&
                          NoCodeServicesPageData?.noCode?.tag}
                      </p>
                      <h1
                        className={`${styles.header}`}
                        dangerouslySetInnerHTML={{
                          __html: !!NoCodeServicesPageData?.noCode?.header
                            ? NoCodeServicesPageData?.noCode?.header
                            : "",
                        }}
                      ></h1>
                      <p
                        className={`${styles.text}`}
                        dangerouslySetInnerHTML={{
                          __html: !!NoCodeServicesPageData?.noCode?.about
                            ? NoCodeServicesPageData?.noCode?.about
                            : "",
                        }}
                      ></p>
                      <button className={`${styles.btn} text-uppercase`}>
                        {!!NoCodeServicesPageData?.noCode?.button &&
                          NoCodeServicesPageData?.noCode?.button}
                      </button>
                    </div>
                    <div className="col-md-5 px-0">
                      <img
                        loading="lazy"
                        className="w-100"
                        src={
                          !!NoCodeServicesPageData?.noCode?.image
                            ? process.env.REACT_APP_CLOUDFLARE_URL +
                              NoCodeServicesPageData?.noCode?.image
                            : ""
                        }
                        alt="img"
                      />
                    </div>
                  </div>
                  <div
                    className={`position-absolute ${styles.bottomBg} h-50 w-100`}
                  ></div>
                </div>
                <div
                  className={`${styles.bottom} w-100 d-flex flex-column position-relative align-items-center text-center`}
                >
                  <img
                    loading="lazy"
                    className={`${styles.img}`}
                    src={
                      !!NoCodeServicesPageData?.noCode?.fImage
                        ? process.env.REACT_APP_CLOUDFLARE_URL +
                          NoCodeServicesPageData?.noCode?.fImage
                        : ""
                    }
                    alt="img"
                  />
                  <h2 className={`${styles.header}`}>
                    {!!NoCodeServicesPageData?.noCode?.fHeader &&
                      NoCodeServicesPageData?.noCode?.fHeader}
                  </h2>
                  <p className={`${styles.text1}`}>
                    {!!NoCodeServicesPageData?.noCode?.fText1 &&
                      NoCodeServicesPageData?.noCode?.fText1}
                  </p>
                  <p className={`${styles.text2}`}>
                    {!!NoCodeServicesPageData?.noCode?.fText2 &&
                      NoCodeServicesPageData?.noCode?.fText2}
                  </p>
                  <button className={`${styles.btn} btn text-uppercase`}>
                    {!!NoCodeServicesPageData?.noCode?.fButton
                      ? NoCodeServicesPageData?.noCode?.fButton
                      : "Checkout BUZZY"}
                  </button>

                  <img
                    loading="lazy"
                    className={`${styles.line1} position-absolute`}
                    src={require("../../assets/images/services/no-code/services/line-2.png")}
                    alt="line"
                  />
                  <img
                    loading="lazy"
                    className={`${styles.line2} position-absolute`}
                    src={require("../../assets/images/services/no-code/services/line-1.png")}
                    alt="line"
                  />
                </div>
              </div>
            )}

            {!!NoCodeServicesPageData?.noCode?.show && (
              <div
                className={`container-fluid ${styles.work} d-flex flex-column align-items-center text-center`}
              >
                <p className={`${styles.tag}`}>
                  {!!NoCodeServicesPageData?.noCode?.wTag &&
                    NoCodeServicesPageData?.noCode?.wTag}
                </p>
                <h4 className={`${styles.header1}`}>
                  {!!NoCodeServicesPageData?.noCode?.wHeader &&
                    NoCodeServicesPageData?.noCode?.wHeader}
                </h4>

                <p className={`${styles.text}`} style={{ marginBottom: "5vw" }}>
                  {!!NoCodeServicesPageData?.noCode?.wAbout &&
                    NoCodeServicesPageData?.noCode?.wAbout}
                </p>
                <div className={`row w-100 mx-0 ${styles.container}`}>
                  {!!NoCodeServicesPageData?.noCode?.wList &&
                    NoCodeServicesPageData?.noCode?.wList?.map(
                      (value, index) => (
                        <div
                          key={index}
                          className={`col-md-3 col-6 d-flex flex-column align-items-center`}
                        >
                          <img
                            loading="lazy"
                            className={styles.icon}
                            src={
                              !!value?.icon
                                ? process.env.REACT_APP_CLOUDFLARE_URL +
                                  value?.icon
                                : ""
                            }
                            alt="icon"
                          />
                          <p className={`${styles.text}`}>{value?.text}</p>
                          <h5 className={`${styles.result}`}>
                            {value?.status}
                          </h5>
                        </div>
                      )
                    )}
                </div>
                <h3 className={`${styles.header2} mt-5`}>
                  {!!NoCodeServicesPageData?.noCode?.wResult &&
                    NoCodeServicesPageData?.noCode?.wResult}
                </h3>
              </div>
            )}
          </>
        );

      case "process":
        return (
          <>
            {!!NoCodeServicesPageData?.process?.show && (
              <StepsProgressBarB step1={step1} step2={step2} />
            )}
          </>
        );

      case "technology":
        return (
          <>
            {!!NoCodeServicesPageData?.technology?.show && (
              <div style={{ marginTop: "-12vw" }}>
                <Technology
                  width
                  containerClass={"body3"}
                  technology={NoCodeServicesPageData?.technology}
                  bottomBar={{
                    structure: 2,
                    design: "bottomBar9",
                  }}
                />
              </div>
            )}
          </>
        );

      case "why_startem":
        return (
          <>
            {!!NoCodeServicesPageData?.why_startem?.show && (
              <WhyStartemA
                WhyStartem={NoCodeServicesPageData?.why_startem}
                imageContainer={{
                  position: "left",
                }}
              />
            )}
            {!!NoCodeServicesPageData?.why_startem?.show && (
              <div className={`container-fluid ${styles.connect}`}>
                <div className={`${styles.body} w-100`}>
                  <div
                    className={`d-flex ${styles.innerBody} justify-content-between align-items-center`}
                  >
                    <div className={`pl-4 ${styles.leftCol}`}>
                      <h2 className={styles.header}>
                        {!!NoCodeServicesPageData?.why_startem?.lHeader &&
                          NoCodeServicesPageData?.why_startem?.lHeader}
                      </h2>
                    </div>
                    <div className={`pr-4 ${styles.rightCol}`}>
                      <button className={`btn ${styles.btn} text-uppercase`}>
                        {!!NoCodeServicesPageData?.why_startem?.button1 &&
                          NoCodeServicesPageData?.why_startem?.button1}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!NoCodeServicesPageData?.portfolio?.show && (
              <Portfolio portfolioData={NoCodeServicesPageData?.portfolio} />
            )}
          </>
        );

      case "testimonials":
        return (
          <>
            {!!NoCodeServicesPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={NoCodeServicesPageData?.testimonials}
                design={{
                  background: "#53389E",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#E9D7FE",
                  brand: "#FFFFFF",
                }}
              />
            )}
          </>
        );

      case "faq":
        return (
          <>
            {!!NoCodeServicesPageData?.faq?.show && (
              <FAQs faqData={NoCodeServicesPageData?.faq} />
            )}
          </>
        );

      case "contact":
        return (
          <>
            {!!NoCodeServicesPageData?.contact?.show && (
              <Contact data={NoCodeServicesPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>
              Startem + Buzzy: The Ultimate No-Code Solution for Product
              Development
            </title>
            <meta
              name="description"
              content="Join the no-code development bandwagon with Startem and Buzzy! Our partnership enables you to create functional websites, platforms, and mobile apps right from Figma without any coding skills. We offer product research, design, development, and deployment services with a technology stack that optimizes your user journey. Let us transform and innovate your way to the future!"
            />
            <link
              rel="canonical"
              href="https://www.startem.io/no-code-services"
            />
          </Helmet>
          {!!NoCodeServicesPageData?.sequence &&
            NoCodeServicesPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/**

          {!!NoCodeServicesPageData?.noCode?.show && (
            <div
              className={`container-fluid ${styles.head} d-flex flex-column align-items-center`}
            >
              <div className={`${styles.top} position-relative w-100`}>
                <div className={`row mx-0 ${styles.box}`}>
                  <div
                    className={`col-md-7 col-12 d-flex ${styles.leftCol} flex-column align-items-start h-100`}
                  >
                    <p className={`${styles.tag}`}>
                      {!!NoCodeServicesPageData?.noCode?.tag &&
                        NoCodeServicesPageData?.noCode?.tag}
                    </p>
                    <h1
                      className={`${styles.header}`}
                      dangerouslySetInnerHTML={{
                        __html: !!NoCodeServicesPageData?.noCode?.header
                          ? NoCodeServicesPageData?.noCode?.header
                          : "",
                      }}
                    ></h1>
                    <p
                      className={`${styles.text}`}
                      dangerouslySetInnerHTML={{
                        __html: !!NoCodeServicesPageData?.noCode?.about
                          ? NoCodeServicesPageData?.noCode?.about
                          : "",
                      }}
                    ></p>
                    <button className={`${styles.btn} text-uppercase`}>
                      {!!NoCodeServicesPageData?.noCode?.button &&
                        NoCodeServicesPageData?.noCode?.button}
                    </button>
                  </div>
                  <div className="col-md-5 px-0">
                    <img
                      loading="lazy"
                      className="w-100"
                      src={
                        !!NoCodeServicesPageData?.noCode?.image
                          ? process.env.REACT_APP_CLOUDFLARE_URL +
                            NoCodeServicesPageData?.noCode?.image
                          : ""
                      }
                      alt="img"
                    />
                  </div>
                </div>
                <div
                  className={`position-absolute ${styles.bottomBg} h-50 w-100`}
                ></div>
              </div>
              <div
                className={`${styles.bottom} w-100 d-flex flex-column position-relative align-items-center text-center`}
              >
                <img
                  loading="lazy"
                  className={`${styles.img}`}
                  src={
                    !!NoCodeServicesPageData?.noCode?.fImage
                      ? process.env.REACT_APP_CLOUDFLARE_URL +
                        NoCodeServicesPageData?.noCode?.fImage
                      : ""
                  }
                  alt="img"
                />
                <h2 className={`${styles.header}`}>
                  {!!NoCodeServicesPageData?.noCode?.fHeader &&
                    NoCodeServicesPageData?.noCode?.fHeader}
                </h2>
                <p className={`${styles.text1}`}>
                  {!!NoCodeServicesPageData?.noCode?.fText1 &&
                    NoCodeServicesPageData?.noCode?.fText1}
                </p>
                <p className={`${styles.text2}`}>
                  {!!NoCodeServicesPageData?.noCode?.fText2 &&
                    NoCodeServicesPageData?.noCode?.fText2}
                </p>
                <button className={`${styles.btn} btn text-uppercase`}>
                  {!!NoCodeServicesPageData?.noCode?.fButton
                    ? NoCodeServicesPageData?.noCode?.fButton
                    : "Checkout BUZZY"}
                </button>

                <img
                  loading="lazy"
                  className={`${styles.line1} position-absolute`}
                  src={require("../../assets/images/services/no-code/services/line-2.png")}
                  alt="line"
                />
                <img
                  loading="lazy"
                  className={`${styles.line2} position-absolute`}
                  src={require("../../assets/images/services/no-code/services/line-1.png")}
                  alt="line"
                />
              </div>
            </div>
          )}

          {!!NoCodeServicesPageData?.noCode?.show && (
            <div
              className={`container-fluid ${styles.work} d-flex flex-column align-items-center text-center`}
            >
              <p className={`${styles.tag}`}>
                {!!NoCodeServicesPageData?.noCode?.wTag &&
                  NoCodeServicesPageData?.noCode?.wTag}
              </p>
              <h4 className={`${styles.header1}`}>
                {!!NoCodeServicesPageData?.noCode?.wHeader &&
                  NoCodeServicesPageData?.noCode?.wHeader}
              </h4>

              <p className={`${styles.text}`} style={{ marginBottom: "5vw" }}>
                {!!NoCodeServicesPageData?.noCode?.wAbout &&
                  NoCodeServicesPageData?.noCode?.wAbout}
              </p>
              <div className={`row w-100 mx-0 ${styles.container}`}>
                {!!NoCodeServicesPageData?.noCode?.wList &&
                  NoCodeServicesPageData?.noCode?.wList?.map((value, index) => (
                    <div
                      key={index}
                      className={`col-md-3 col-6 d-flex flex-column align-items-center`}
                    >
                      <img
                        loading="lazy"
                        className={styles.icon}
                        src={
                          !!value?.icon
                            ? process.env.REACT_APP_CLOUDFLARE_URL + value?.icon
                            : ""
                        }
                        alt="icon"
                      />
                      <p className={`${styles.text}`}>{value?.text}</p>
                      <h5 className={`${styles.result}`}>{value?.status}</h5>
                    </div>
                  ))}
              </div>
              <h3 className={`${styles.header2} mt-5`}>
                {!!NoCodeServicesPageData?.noCode?.wResult &&
                  NoCodeServicesPageData?.noCode?.wResult}
              </h3>
            </div>
          )}

          {!!NoCodeServicesPageData?.process?.show && (
            <StepsProgressBarB step1={step1} step2={step2} />
          )}
          {!!NoCodeServicesPageData?.technology?.show && (
            <div style={{ marginTop: "-12vw" }}>
              <Technology
                width
                containerClass={"body3"}
                technology={NoCodeServicesPageData?.technology}
                bottomBar={{
                  structure: 2,
                  design: "bottomBar9",
                }}
              />
            </div>
          )}
          {!!NoCodeServicesPageData?.why_startem?.show && (
            <WhyStartemA
              WhyStartem={NoCodeServicesPageData?.why_startem}
              imageContainer={{
                position: "left",
              }}
            />
          )}
          {!!NoCodeServicesPageData?.why_startem?.show && (
            <div className={`container-fluid ${styles.connect}`}>
              <div className={`${styles.body} w-100`}>
                <div
                  className={`d-flex ${styles.innerBody} justify-content-between align-items-center`}
                >
                  <div className={`pl-4 ${styles.leftCol}`}>
                    <h2 className={styles.header}>
                      {!!NoCodeServicesPageData?.why_startem?.lHeader &&
                        NoCodeServicesPageData?.why_startem?.lHeader}
                    </h2>
                  </div>
                  <div className={`pr-4 ${styles.rightCol}`}>
                    <button className={`btn ${styles.btn} text-uppercase`}>
                      {!!NoCodeServicesPageData?.why_startem?.button1 &&
                        NoCodeServicesPageData?.why_startem?.button1}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!!NoCodeServicesPageData?.portfolio?.show && (
            <Portfolio portfolioData={NoCodeServicesPageData?.portfolio} />
          )}
          {!!NoCodeServicesPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={NoCodeServicesPageData?.testimonials}
              design={{
                background: "#53389E",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#E9D7FE",
                brand: "#FFFFFF",
              }}
            />
          )}
          {!!NoCodeServicesPageData?.faq?.show && (
            <FAQs faqData={NoCodeServicesPageData?.faq} />
          )}
          {!!NoCodeServicesPageData?.contact?.show && (
            <Contact data={NoCodeServicesPageData?.contact} />
          )}
          */}
        </>
      )}
    </>
  );
}

export default NoCodeServices;
