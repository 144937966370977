import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../extra/loader/Loader";
import Contact from "../../modules/contact/Contact";
import FAQs from "../../modules/faq/FAQs";
import IdeasA from "../../modules/ideas/IdeasA";
import Portfolio from "../../modules/portfolio/Portfolio";
import ProcessB from "../../modules/process/ProcessB";
import Services from "../../modules/services/Services";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import WhyStartemA from "../../modules/why startem/WhyStartemA";
import { PmServicesData } from "../../store/actions/Services/PMServicesData";
import Page404 from "../placeholders/404Page";

function ProductManagementServices() {
  const { isLoading, PmServicesPageData, errorMessage } = useSelector(
    (state) => state?.PmServicesReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(PmServicesData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "intro":
        return (
          <>
            {!!PmServicesPageData?.intro?.show && (
              <IdeasA
                Intro={PmServicesPageData?.intro}
                imageContainer={{
                  position: "left",
                }}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
                btmImg
              />
            )}
          </>
        );

      case "why_startem":
        return (
          <>
            {!!PmServicesPageData?.why_startem?.show && (
              <WhyStartemA
                WhyStartem={PmServicesPageData?.why_startem}
                imageContainer={{
                  position: "left",
                }}
              />
            )}
          </>
        );

      case "our_services":
        return (
          <>
            {!!PmServicesPageData?.our_services?.show && (
              <Services
                ourServices={PmServicesPageData?.our_services}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
              />
            )}
          </>
        );

      case "process":
        return (
          <>
            {!!PmServicesPageData?.process?.show && (
              <ProcessB
                process={PmServicesPageData?.process}
                bottomBar={{
                  structure: 1,
                  design: "bottomBar1",
                }}
              />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!PmServicesPageData?.portfolio?.show && (
              <Portfolio portfolioData={PmServicesPageData?.portfolio} />
            )}
          </>
        );

      case "testimonials":
        return (
          <>
            {!!PmServicesPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={PmServicesPageData?.testimonials}
                design={{
                  background: "#1D2939",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#96A2B2",
                  brand: "#FFFFFF",
                }}
              />
            )}
          </>
        );

      case "faq":
        return (
          <>
            {!!PmServicesPageData?.faq?.show && (
              <FAQs faqData={PmServicesPageData?.faq} />
            )}
          </>
        );

      case "contact":
        return (
          <>
            {!!PmServicesPageData?.contact?.show && (
              <Contact data={PmServicesPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>
              Accelerate Your Product’s Success with Startem’s Product
              Management Services
            </title>
            <meta
              name="description"
              content="Need help turning your product idea into a successful reality? Startem offers top-notch product management services to help you build a viable product, accelerate market entry, and scale to success. Our services include result ownership, market research, portfolio management, business case, roadmaps for products, usability, and competitor research. Contact us today to transform and innovate your product towards perpetual value."
            />
            <link
              rel="canonical"
              href="https://www.startem.io/product-services"
            />
          </Helmet>

          {!!PmServicesPageData?.sequence &&
            PmServicesPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*

          {!!PmServicesPageData?.intro?.show && (
            <IdeasA
              Intro={PmServicesPageData?.intro}
              imageContainer={{
                position: "right",
                objectFit: "cover",
              }}
              bottomBar={{ structure: 1, design: "bottomBar2" }}
            />
          )}

          {!!PmServicesPageData?.why_startem?.show && (
            <WhyStartemA
              WhyStartem={PmServicesPageData?.why_startem}
              imageContainer={{
                position: "left",
              }}
            />
          )}

          {!!PmServicesPageData?.our_services?.show && (
            <Services
              ourServices={PmServicesPageData?.our_services}
              bottomBar={{ structure: 1, design: "bottomBar5" }}
            />
          )}

          {!!PmServicesPageData?.process?.show && (
            <ProcessB
              process={PmServicesPageData?.process}
              bottomBar={{ structure: 1, design: "bottomBar1" }}
            />
          )}

          {!!PmServicesPageData?.portfolio?.show && (
            <Portfolio portfolioData={PmServicesPageData?.portfolio} />
          )}

          {!!PmServicesPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={PmServicesPageData?.testimonials}
              design={{
                background: "#636F73",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#E9D7FE",
                brand: "#FFFFFF",
              }}
            />
          )}

          {!!PmServicesPageData?.faq?.show && (
            <FAQs faqData={PmServicesPageData?.faq} />
          )}

          {!!PmServicesPageData?.contact?.show && (
            <Contact data={PmServicesPageData?.contact} />
          )}
        */}
        </>
      )}
    </>
  );
}

export default ProductManagementServices;
