import React from "react";
import styles from "./healthcare.module.scss";
import pic from "../../assets/images/services/city-seo/industry/rectangle.png";

function Healthcare({ Benefits }) {
  return (
    <div className="container-fluid px-0 w-100">
      <div className={`${styles.body} d-flex flex-column align-items-center`}>
        <h4 className={`${styles.header} text-center`}>
          {!!Benefits?.header && Benefits?.header}
        </h4>
        <p className={`${styles.text} text-center`}>
          {!!Benefits?.description && Benefits?.description}
        </p>
        <div className="row w-100">
          {!!Benefits?.list &&
            Benefits?.list?.map((value, index) => (
              <div key={index} className={`${styles.box} col-md-4 col-6 px-0`}>
                <div
                  key={index}
                  className={`${styles.card} d-flex flex-column align-items-start position-relative`}
                  style={{
                    backgroundColor: index === 5 ? "#272727" : "",
                    backgroundImage: index === 5 ? `url(${pic})` : "",
                  }}
                >
                  {index < 5 ? (
                    <>
                      <img
                        loading="lazy"
                        className={styles.img}
                        src={
                          !!value?.icon
                            ? process.env.REACT_APP_CLOUDFLARE_URL + value?.icon
                            : ""
                        }
                        alt="icon"
                      />
                      <h4 className={styles.heading}>{value?.title}</h4>
                      <p className={styles.content}>{value?.text}</p>
                    </>
                  ) : (
                    <>
                      <img
                        loading="lazy"
                        className={styles.img2}
                        src={
                          !!value?.icon
                            ? process.env.REACT_APP_CLOUDFLARE_URL + value?.icon
                            : ""
                        }
                        alt="icon"
                      />
                      <h4 className={styles.heading2}>{value?.title}</h4>
                      <p className={styles.content}>{value?.text}</p>
                      <div>
                        <button className={`${styles.btn} btn`}>
                          {value?.button}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Healthcare;
