import {
  OUR_SERVICES_PAGE_LOADER,
  OUR_SERVICES_PAGE_SUCCESS,
  OUR_SERVICES_PAGE_FAILURE,
} from "../../constants/Services/OurServicesConstants";
import { getPage } from "../../../API/Api";

const success = (payload) => ({
  type: OUR_SERVICES_PAGE_SUCCESS,
  payload,
});

const failure = (error) => ({
  type: OUR_SERVICES_PAGE_FAILURE,
  error,
});

export const OurServicesData = () => (dispatch) => {
  dispatch({ type: OUR_SERVICES_PAGE_LOADER });
  getPage("page", {
    filter: {
      Page_Name: {
        _eq: "Our Services",
      },
    },
    fields: "*.*.*.*.*.*.*",
  })
    .then((response) => {
      if (!!response?.data?.length) dispatch(success(response?.data[0]));
      else dispatch(failure("Something Went Wrong"));
    })
    .catch((err) => {
      dispatch(failure(err?.errors));
    });
};
