import {
  NYC_SD_SERVICES_PAGE_LOADER,
  NYC_SD_SERVICES_PAGE_SUCCESS,
  NYC_SD_SERVICES_PAGE_FAILURE,
  NYC_SD_SERVICES_PAGE_RESET,
} from "../../constants/City/NycSdServicesConstants";

const initialState = {
  isLoading: false,
  NycSdServicesPageData: {},
  errorMessage: "",
};

export const NycSdServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case NYC_SD_SERVICES_PAGE_LOADER:
      return { ...state, isLoading: true };

    case NYC_SD_SERVICES_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        NycSdServicesPageData: action.payload,
        errorMessage: "",
      };

    case NYC_SD_SERVICES_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case NYC_SD_SERVICES_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
