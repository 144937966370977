import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../extra/loader/Loader";
import Contact from "../../modules/contact/Contact";
import FAQs from "../../modules/faq/FAQs";
import IdeasA from "../../modules/ideas/IdeasA";
import Portfolio from "../../modules/portfolio/Portfolio";
import ProcessB from "../../modules/process/ProcessB";
import Services from "../../modules/services/Services";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import WhyStartemA from "../../modules/why startem/WhyStartemA";
import { UiUxServicesData } from "../../store/actions/Services/UiUxServicesData";
import Page404 from "../placeholders/404Page";

function UIUXServices() {
  const { isLoading, UiUxServicesPageData, errorMessage } = useSelector(
    (state) => state?.UiUxServicesReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(UiUxServicesData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "intro":
        return (
          <>
            {!!UiUxServicesPageData?.intro?.show && (
              <IdeasA
                Intro={UiUxServicesPageData?.intro}
                imageContainer={{
                  position: "left",
                }}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
                btmImg
              />
            )}
          </>
        );

      case "why_startem":
        return (
          <>
            {!!UiUxServicesPageData?.why_startem?.show && (
              <WhyStartemA
                WhyStartem={UiUxServicesPageData?.why_startem}
                imageContainer={{
                  position: "left",
                }}
              />
            )}
          </>
        );

      case "our_services":
        return (
          <>
            {!!UiUxServicesPageData?.our_services?.show && (
              <Services
                ourServices={UiUxServicesPageData?.our_services}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
              />
            )}
          </>
        );

      case "process":
        return (
          <>
            {!!UiUxServicesPageData?.process?.show && (
              <ProcessB
                process={UiUxServicesPageData?.process}
                bottomBar={{
                  structure: 1,
                  design: "bottomBar1",
                }}
              />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!UiUxServicesPageData?.portfolio?.show && (
              <Portfolio portfolioData={UiUxServicesPageData?.portfolio} />
            )}
          </>
        );

      case "testimonials":
        return (
          <>
            {!!UiUxServicesPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={UiUxServicesPageData?.testimonials}
                design={{
                  background: "#1D2939",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#96A2B2",
                  brand: "#FFFFFF",
                }}
              />
            )}
          </>
        );

      case "faq":
        return (
          <>
            {!!UiUxServicesPageData?.faq?.show && (
              <FAQs faqData={UiUxServicesPageData?.faq} />
            )}
          </>
        );

      case "contact":
        return (
          <>
            {!!UiUxServicesPageData?.contact?.show && (
              <Contact data={UiUxServicesPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>Professional UI/UX Design Services | Startem</title>
            <meta
              name="description"
              content="At Startem, we provide captivating UI/UX designs utilizing the most powerful tools available. Contact us today to turn your product ideas into reality and give your user journey a new, better face."
            />
            <link
              rel="canonical"
              href="https://www.startem.io/UI-UX-services"
            />
          </Helmet>

          {!!UiUxServicesPageData?.sequence &&
            UiUxServicesPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*
          {!!UiUxServicesPageData?.intro?.show && (
            <IdeasA
              Intro={UiUxServicesPageData?.intro}
              imageContainer={{
                position: "right",
                objectFit: "cover",
                gradient: true,
              }}
              bottomBar={{ structure: 2, design: "bottomBar4" }}
              btmImg
              transform
            />
          )}

          {!!UiUxServicesPageData?.why_startem?.show && (
            <WhyStartemA
              WhyStartem={UiUxServicesPageData?.why_startem}
              imageContainer={{
                path: "uiux-services/mockup.png",
                position: "left",
              }}
              heading={"Why Our UI UX Design Services?"}
            />
          )}

          {!!UiUxServicesPageData?.our_services?.show && (
            <Services
              ourServices={UiUxServicesPageData?.our_services}
              bottomBar={{ structure: 2, design: "bottomBar3" }}
            />
          )}

          {!!UiUxServicesPageData?.process?.show && (
            <ProcessB
              process={UiUxServicesPageData?.process}
              bottomBar={{ structure: 1, design: "bottomBar1" }}
            />
          )}

          {!!UiUxServicesPageData?.portfolio?.show && (
            <Portfolio portfolioData={UiUxServicesPageData?.portfolio} />
          )}

          {!!UiUxServicesPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={UiUxServicesPageData?.testimonials}
              design={{
                background: "#53389E",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#E9D7FE",
                brand: "#FFFFFF",
              }}
            />
          )}

          {!!UiUxServicesPageData?.faq?.show && (
            <FAQs faqData={UiUxServicesPageData?.faq} />
          )}

          {!!UiUxServicesPageData?.contact?.show && (
            <Contact data={UiUxServicesPageData?.contact} />
          )}
        */}
        </>
      )}
    </>
  );
}

export default UIUXServices;
