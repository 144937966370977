import {
  INDUSTRY_WEBDEV_PAGE_LOADER,
  INDUSTRY_WEBDEV_PAGE_SUCCESS,
  INDUSTRY_WEBDEV_PAGE_FAILURE,
  INDUSTRY_WEBDEV_PAGE_RESET,
} from "../../constants/Industries/IndustryWebDevConstants";

const initialState = {
  isLoading: false,
  IndustryWebDevPageData: {},
  errorMessage: "",
};

export const IndustryWebDevReducer = (state = initialState, action) => {
  switch (action.type) {
    case INDUSTRY_WEBDEV_PAGE_LOADER:
      return { ...state, isLoading: true };

    case INDUSTRY_WEBDEV_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        IndustryWebDevPageData: action.payload,
        errorMessage: "",
      };

    case INDUSTRY_WEBDEV_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case INDUSTRY_WEBDEV_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
