import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../extra/loader/Loader";
import Contact from "../../modules/contact/Contact";
import FAQs from "../../modules/faq/FAQs";
import IdeasA from "../../modules/ideas/IdeasA";
import Portfolio from "../../modules/portfolio/Portfolio";
import ProcessB from "../../modules/process/ProcessB";
import Services from "../../modules/services/Services";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import WhyStartemA from "../../modules/why startem/WhyStartemA";
import { SmServicesData } from "../../store/actions/Services/SMServicesData";
import Page404 from "../placeholders/404Page";

function SupportMaintenenceServices() {
  const { isLoading, SmServicesPageData, errorMessage } = useSelector(
    (state) => state?.SmServicesReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(SmServicesData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "intro":
        return (
          <>
            {!!SmServicesPageData?.intro?.show && (
              <IdeasA
                Intro={SmServicesPageData?.intro}
                imageContainer={{
                  position: "left",
                }}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
                btmImg
              />
            )}
          </>
        );

      case "why_startem":
        return (
          <>
            {!!SmServicesPageData?.why_startem?.show && (
              <WhyStartemA
                WhyStartem={SmServicesPageData?.why_startem}
                imageContainer={{
                  position: "left",
                }}
              />
            )}
          </>
        );

      case "our_services":
        return (
          <>
            {!!SmServicesPageData?.our_services?.show && (
              <Services
                ourServices={SmServicesPageData?.our_services}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
              />
            )}
          </>
        );

      case "process":
        return (
          <>
            {!!SmServicesPageData?.process?.show && (
              <ProcessB
                process={SmServicesPageData?.process}
                bottomBar={{
                  structure: 1,
                  design: "bottomBar1",
                }}
              />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!SmServicesPageData?.portfolio?.show && (
              <Portfolio portfolioData={SmServicesPageData?.portfolio} />
            )}
          </>
        );

      case "testimonials":
        return (
          <>
            {!!SmServicesPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={SmServicesPageData?.testimonials}
                design={{
                  background: "#1D2939",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#96A2B2",
                  brand: "#FFFFFF",
                }}
              />
            )}
          </>
        );

      case "faq":
        return (
          <>
            {!!SmServicesPageData?.faq?.show && (
              <FAQs faqData={SmServicesPageData?.faq} />
            )}
          </>
        );

      case "contact":
        return (
          <>
            {!!SmServicesPageData?.contact?.show && (
              <Contact data={SmServicesPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>IT Support Services for Your Product | Startem</title>
            <meta
              name="description"
              content="Get expert app management, help desk support, and evolution for your product with Startem's IT Support Services. Enjoy 24/7 app availability, innovative evolution, and reduced operational costs. Our services include L0, L1, L2, L3 support, application evolution, security and compliance management, and application performance management."
            />
            <link
              rel="canonical"
              href="https://www.startem.io/support-services"
            />
          </Helmet>

          {!!SmServicesPageData?.sequence &&
            SmServicesPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*

          {!!SmServicesPageData?.intro?.show && (
            <IdeasA
              Intro={SmServicesPageData?.intro}
              imageContainer={{
                position: "left",
                width: "90%",
              }}
              bottomBar={{ structure: 2, design: "bottomBar4" }}
            />
          )}

          {!!SmServicesPageData?.why_startem?.show && (
            <WhyStartemA
              WhyStartem={SmServicesPageData?.why_startem}
              imageContainer={{
                position: "left",
              }}
            />
          )}

          {!!SmServicesPageData?.our_services?.show && (
            <Services
              ourServices={SmServicesPageData?.our_services}
              bottomBar={{ structure: 2, design: "bottomBar6" }}
            />
          )}

          {!!SmServicesPageData?.our_services?.show && (
            <ProcessB
              process={SmServicesPageData?.our_services}
              bottomBar={{ structure: 2, design: "bottomBar3" }}
            />
          )}

          {!!SmServicesPageData?.our_services?.show && (
            <Portfolio portfolioData={SmServicesPageData?.our_services} />
          )}

          {!!SmServicesPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={SmServicesPageData?.testimonials}
              design={{
                background: "#7F56D9",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#E9D7FE",
                brand: "#FFFFFF",
              }}
            />
          )}

          {!!SmServicesPageData?.faq?.show && (
            <FAQs faqData={SmServicesPageData?.faq} />
          )}

          {!!SmServicesPageData?.contact?.show && (
            <Contact data={SmServicesPageData?.contact} />
          )}
        */}
        </>
      )}
    </>
  );
}

export default SupportMaintenenceServices;
