import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Contact from "../../modules/contact/Contact";
import FAQs from "../../modules/faq/FAQs";
import Ideas from "../../modules/ideas/Ideas";
import Portfolio from "../../modules/portfolio/Portfolio";
import Process from "../../modules/process/Process";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import styles from "./style.module.scss";
import { HomeData } from "../../store/actions/Home/HomeData";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../extra/loader/Loader";
import Page404 from "../placeholders/404Page";
import bgPattren from "../../assets/images/pattern.png";
import { Helmet } from "react-helmet";

function Home() {
  const { isLoading, HomePageData, errorMessage } = useSelector(
    (state) => state?.HomeReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(HomeData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "intro":
        return (
          !!HomePageData?.intro?.show && <Ideas Intro={HomePageData?.intro} />
        );

      case "why_startem":
        return (
          <div
            className={`container-fluid ${styles.startemBody} px-0 d-flex flex-column justify-content-center align-items-center`}
          >
            <div className="w-100 d-flex justify-content-center position-relative">
              <div
                className={`position-absolute w-100 ${styles.imageContainer}`}
              >
                <img
                  loading="lazy"
                  alt="img"
                  className="w-100 h-100"
                  src={bgPattren}
                />
              </div>
              <div
                className={`${styles.topBody} d-flex flex-column align-items-center text-center`}
              >
                <p className={`${styles.startemTag} border`}>
                  {HomePageData?.why_startem?.Tag}
                </p>
                <h2 className={`${styles.header}`}>
                  {HomePageData?.why_startem?.Header}
                </h2>
                <p className={styles.text}>
                  {HomePageData?.why_startem?.About}
                </p>
                <img
                  loading="lazy"
                  src={`${process.env.REACT_APP_CLOUDFLARE_URL}${HomePageData?.why_startem?.image}`}
                  alt="startem screen"
                  className={`border border-dark ${styles.imgBorder} h-100`}
                />
              </div>
            </div>

            <div
              className={`row w-100 ${styles.midBody} justify-content-around`}
            >
              {!!HomePageData?.why_startem?.Features &&
                HomePageData?.why_startem?.Features?.map((value, index) => (
                  <div
                    key={index}
                    className={`col-12 col-md-4 ${styles.startemTopBorder} ${
                      index === 1 ? styles.startemTopActive : ""
                    }`}
                  >
                    <div className={`${styles.header}`}>{value?.header}</div>
                    <p className={`${styles.text}`}>{value?.text}</p>
                  </div>
                ))}
            </div>

            <div className={`w-100 ${styles.bottom}`}>
              <div
                className={`${styles.bottomBody} w-100 mx-0 row justify-content-between align-items-center`}
              >
                <div
                  className={`col-12 col-md-9 ${styles.box} d-flex flex-column align-items-start`}
                >
                  <h3 className={`text-white ${styles.header}`}>
                    {HomePageData?.why_startem?.lHeader}
                  </h3>
                  <p className={`${styles.text} mb-0`}>
                    {HomePageData?.why_startem?.lText}
                  </p>
                </div>
                <div className="col-12 col-md-3 d-flex flex-row justify-content-center align-items-center">
                  <button className={`btn ${styles.btn1}`}>
                    {HomePageData?.why_startem?.button1}
                  </button>
                  <button className={`btn ${styles.btn2}`}>
                    {HomePageData?.why_startem?.button2}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );

      case "process":
        return <Process processData={HomePageData?.process} />;

      case "our_services":
        return (
          <div className={styles.ourServicesBody}>
            <div
              className={`container-fluid ${styles.body} d-flex flex-column justify-content-around align-items-center text-white text-center`}
            >
              <div
                className={`row w-100 ${styles.top} flex-column align-items-start justify-content-center`}
              >
                <p className={`border ${styles.ourServicesTag}`}>
                  {HomePageData?.our_services?.tag}
                </p>
                <h3 className={styles.header}>
                  {HomePageData?.our_services?.header}
                </h3>
              </div>

              <div className={`w-100 row ${styles.bottom}`}>
                {!!HomePageData?.our_services?.services &&
                  HomePageData?.our_services?.services?.map((value, index) => (
                    <div
                      key={index}
                      className={`${
                        index === 7 ? "col-md-8 col-6" : "col-md-4 col-6"
                      } ${styles.box} d-flex flex-column align-items-start`}
                    >
                      <div
                        className={`w-100 d-flex ${styles.unCenterIcon}`}
                        style={{ paddingLeft: `${index === 7 ? "40%" : "0"}` }}
                      >
                        <img
                          loading="lazy"
                          className={styles.image}
                          src={`${process.env.REACT_APP_CLOUDFLARE_URL}${value?.icon}`}
                          alt="Web icon"
                        />
                      </div>
                      <Link
                        className={`mt-3 ${styles.servicesFontWeight}`}
                        to={!!value?.to ? value?.to : "#"}
                      >
                        {value?.title}
                      </Link>
                      {value?.Service_id?.list === null ? (
                        <p className={`${styles.servicesFont} mt-2`}>
                          Learn More{" "}
                          <i className="bi bi-arrow-right align-middle"></i>
                        </p>
                      ) : (
                        <div
                          className={`d-flex ${styles.list} flex-row w-100 align-items-center justify-content-start`}
                        >
                          {!!value?.list &&
                            value?.list?.map((value, index) => (
                              <p
                                key={index}
                                className={`${styles.servicesFontSize}`}
                              >
                                <i className="bi bi-dot"></i>
                                {value?.text}
                              </p>
                            ))}

                          <p className={`${styles.servicesFont} m-0`}>
                            Learn More{" "}
                            <i className="bi bi-arrow-right align-middle"></i>
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        );

      case "startup":
        return (
          <div
            className={`container-fluid ${styles.processBody} w-100 d-flex flex-column justify-content-center align-items-center text-center`}
          >
            <p className={styles.processTag}>{HomePageData?.startup?.tag}</p>
            <h2 className={styles.header}>{HomePageData?.startup?.header}</h2>
            <div className="row w-100 justify-content-center">
              {HomePageData?.startup &&
                HomePageData?.startup?.processes?.map((value, index) => (
                  <div
                    className="w-100 h-100 col-md col-6 position-relative"
                    key={index}
                  >
                    <img
                      loading="lazy"
                      className="w-100 h-100"
                      src={`${process.env.REACT_APP_CLOUDFLARE_URL}${value?.image}`}
                      alt="img"
                    />
                    <p className={`position-absolute ${styles.transform}`}>
                      {value?.text}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        );

      case "portfolio":
        return <Portfolio portfolioData={HomePageData?.portfolio} />;

      case "testimonials":
        return (
          <TestimonialsB
            Testimonials={HomePageData?.testimonials}
            design={{
              background: "#53389E",
              tag: "#FFFFFF",
              border: "1.5px solid #FFFFFF",
              header: "#FFFFFF",
              name: "#FFFFFF",
              designation: "#E9D7FE",
              brand: "#FFFFFF",
            }}
          />
        );

      case "faq":
        return <FAQs faqData={HomePageData?.faq} />;

      case "contact":
        return <Contact data={HomePageData?.contact} />;

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>
              Startem - The Ultimate Software Boutique for Your Startup
            </title>
            <meta
              name="description"
              content="Startem is a startup accelerator that provides comprehensive services for web and mobile development, UI/UX designing, project and product management, SEO, and web content writing. With Startem, you can build exceptional teams and create digital solutions tailored to your needs, using the lean methodology. Let's start your startup journey with Startem!"
            />
          </Helmet>

          {!!HomePageData?.sequence &&
            HomePageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*
          {!!HomePageData?.intro?.show && <Ideas Intro={HomePageData?.intro} />}

          {!!HomePageData?.why_startem?.show && (
            <div
              className={`container-fluid ${styles.startemBody} px-0 d-flex flex-column justify-content-center align-items-center`}
            >
              <div className="w-100 d-flex justify-content-center position-relative">
                <div
                  className={`position-absolute w-100 ${styles.imageContainer}`}
                >
                  <img
                    loading="lazy"
                    alt="img"
                    className="w-100 h-100"
                    src={bgPattren}
                  />
                </div>
                <div
                  className={`${styles.topBody} d-flex flex-column align-items-center text-center`}
                >
                  <p className={`${styles.startemTag} border`}>
                    {HomePageData?.why_startem?.Tag}
                  </p>
                  <h2 className={`${styles.header}`}>
                    {HomePageData?.why_startem?.Header}
                  </h2>
                  <p className={styles.text}>
                    {HomePageData?.why_startem?.About}
                  </p>
                  <img
                    loading="lazy"
                    src={`${process.env.REACT_APP_CLOUDFLARE_URL}${HomePageData?.why_startem?.image}`}
                    alt="startem screen"
                    className={`border border-dark ${styles.imgBorder} h-100`}
                  />
                </div>
              </div>

              <div
                className={`row w-100 ${styles.midBody} justify-content-around`}
              >
                {!!HomePageData?.why_startem?.Features &&
                  HomePageData?.why_startem?.Features?.map((value, index) => (
                    <div
                      key={index}
                      className={`col-12 col-md-4 ${styles.startemTopBorder} ${
                        index === 1 ? styles.startemTopActive : ""
                      }`}
                    >
                      <div className={`${styles.header}`}>{value?.header}</div>
                      <p className={`${styles.text}`}>{value?.text}</p>
                    </div>
                  ))}
              </div>

              <div className={`w-100 ${styles.bottom}`}>
                <div
                  className={`${styles.bottomBody} w-100 mx-0 row justify-content-between align-items-center`}
                >
                  <div
                    className={`col-12 col-md-9 ${styles.box} d-flex flex-column align-items-start`}
                  >
                    <h3 className={`text-white ${styles.header}`}>
                      {HomePageData?.why_startem?.lHeader}
                    </h3>
                    <p className={`${styles.text} mb-0`}>
                      {HomePageData?.why_startem?.lText}
                    </p>
                  </div>
                  <div className="col-12 col-md-3 d-flex flex-row justify-content-center align-items-center">
                    <button className={`btn ${styles.btn1}`}>
                      {HomePageData?.why_startem?.button1}
                    </button>
                    <button className={`btn ${styles.btn2}`}>
                      {HomePageData?.why_startem?.button2}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!!HomePageData?.process?.show && (
            <Process processData={HomePageData?.process} />
          )}

          {!!HomePageData?.our_services?.show && (
            <div className={styles.ourServicesBody}>
              <div
                className={`container-fluid ${styles.body} d-flex flex-column justify-content-around align-items-center text-white text-center`}
              >
                <div
                  className={`row w-100 ${styles.top} flex-column align-items-start justify-content-center`}
                >
                  <p className={`border ${styles.ourServicesTag}`}>
                    {HomePageData?.our_services?.tag}
                  </p>
                  <h3 className={styles.header}>
                    {HomePageData?.our_services?.header}
                  </h3>
                </div>

                <div className={`w-100 row ${styles.bottom}`}>
                  {!!HomePageData?.our_services?.services &&
                    HomePageData?.our_services?.services?.map(
                      (value, index) => (
                        <div
                          key={index}
                          className={`${
                            index === 7 ? "col-md-8 col-6" : "col-md-4 col-6"
                          } ${styles.box} d-flex flex-column align-items-start`}
                        >
                          <div
                            className={`w-100 d-flex ${styles.unCenterIcon}`}
                            style={{
                              paddingLeft: `${index === 7 ? "40%" : "0"}`,
                            }}
                          >
                            <img
                              loading="lazy"
                              className={styles.image}
                              src={`${process.env.REACT_APP_CLOUDFLARE_URL}${value?.icon}`}
                              alt="Web icon"
                            />
                          </div>
                          <Link
                            className={`mt-3 ${styles.servicesFontWeight}`}
                            to={!!value?.to ? value?.to : "#"}
                          >
                            {value?.title}
                          </Link>
                          {value?.Service_id?.list === null ? (
                            <p className={`${styles.servicesFont} mt-2`}>
                              Learn More{" "}
                              <i className="bi bi-arrow-right align-middle"></i>
                            </p>
                          ) : (
                            <div
                              className={`d-flex ${styles.list} flex-row w-100 align-items-center justify-content-start`}
                            >
                              {!!value?.list &&
                                value?.list?.map((value, index) => (
                                  <p
                                    key={index}
                                    className={`${styles.servicesFontSize}`}
                                  >
                                    <i className="bi bi-dot"></i>
                                    {value?.text}
                                  </p>
                                ))}

                              <p className={`${styles.servicesFont} m-0`}>
                                Learn More{" "}
                                <i className="bi bi-arrow-right align-middle"></i>
                              </p>
                            </div>
                          )}
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          )}

          {!!HomePageData?.startup?.show && (
            <div
              className={`container-fluid ${styles.processBody} w-100 d-flex flex-column justify-content-center align-items-center text-center`}
            >
              <p className={styles.processTag}>{HomePageData?.startup?.tag}</p>
              <h2 className={styles.header}>{HomePageData?.startup?.header}</h2>
              <div className="row w-100 justify-content-center">
                {HomePageData?.startup &&
                  HomePageData?.startup?.processes?.map((value, index) => (
                    <div
                      className="w-100 h-100 col-md col-6 position-relative"
                      key={index}
                    >
                      <img
                        loading="lazy"
                        className="w-100 h-100"
                        src={`${process.env.REACT_APP_CLOUDFLARE_URL}${value?.image}`}
                        alt="img"
                      />
                      <p className={`position-absolute ${styles.transform}`}>
                        {value?.text}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          )}

          {!!HomePageData?.portfolio?.show && (
            <Portfolio portfolioData={HomePageData?.portfolio} />
          )}

          {!!HomePageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={HomePageData?.testimonials}
              design={{
                background: "#53389E",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#E9D7FE",
                brand: "#FFFFFF",
              }}
            />
          )}

          {!!HomePageData?.faq?.show && <FAQs faqData={HomePageData?.faq} />}

          {!!HomePageData?.contact?.show && (
            <Contact data={HomePageData?.contact} />
          )}
        */}
        </>
      )}
    </>
  );
}

export default Home;
