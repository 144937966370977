import React from "react";
import styles from "./introSeo.module.scss";

function IntroSeo({ Intro }) {
  return (
    <div className={`contianer-fluid ${styles.body}`}>
      <div
        className={`w-100 d-flex flex-column align-items-center text-center`}
      >
        <p className={styles.tag}>{!!Intro?.Tag && Intro?.Tag}</p>
        <h1
          className={styles.header}
          dangerouslySetInnerHTML={{
            __html: !!Intro?.Header ? Intro?.Header : "",
          }}
        ></h1>
        <p
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: !!Intro?.description ? Intro?.description : "",
          }}
        ></p>
        <div>
          <button className={`btn ${styles.btn}`}>
            {Intro?.button1 && Intro?.button1}
          </button>
        </div>
        {!!Intro?.image && (
          <img
            loading="lazy"
            className="w-100"
            src={
              !!Intro?.image
                ? process.env.REACT_APP_CLOUDFLARE_URL + Intro?.image
                : ""
            }
            alt="img"
          />
        )}
      </div>
    </div>
  );
}

export default IntroSeo;
