import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../extra/loader/Loader";
import Contact from "../../modules/contact/Contact";
import FAQs from "../../modules/faq/FAQs";
import Portfolio from "../../modules/portfolio/Portfolio";
import Price from "../../modules/services/Price";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import { NoCodeLandingData } from "../../store/actions/NoCode/NoCodeLandingData";
import Page404 from "../placeholders/404Page";
import styles from "./noCode.module.scss";

function NoCodeLanding() {
  const { isLoading, NoCodeLandingPageData, errorMessage } = useSelector(
    (state) => state?.NoCodeLandingReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(NoCodeLandingData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "noCode":
        return (
          <>
            {!!NoCodeLandingPageData?.noCode?.show && (
              <div
                className={`container-fluid px-0 ${styles.intro} d-flex flex-column align-items-center text-center`}
              >
                <p className={`${styles.tag}`}>
                  {!!NoCodeLandingPageData?.noCode?.tag &&
                    NoCodeLandingPageData?.noCode?.tag}
                </p>
                <h1 className={`${styles.header}`}>
                  {!!NoCodeLandingPageData?.noCode?.header &&
                    NoCodeLandingPageData?.noCode?.header}
                </h1>
                <p className={`${styles.text}`}>
                  {!!NoCodeLandingPageData?.noCode?.about &&
                    NoCodeLandingPageData?.noCode?.about}
                </p>
                <button className={`${styles.btn} btn`}>
                  {!!NoCodeLandingPageData?.noCode?.button &&
                    NoCodeLandingPageData?.noCode?.button}
                </button>

                <div className={`${styles.box} w-100 position-relative`}>
                  <img
                    loading="lazy"
                    src={require("../../assets/images/services/no-code/landing/bg-pattern.png")}
                    alt="bg"
                    className={`${styles.img1} w-100 position-absolute`}
                  />
                  <img
                    loading="lazy"
                    src={require("../../assets/images/services/no-code/landing/screen.png")}
                    alt="img"
                    className={`${styles.img2}`}
                  />
                </div>
              </div>
            )}
            {!!NoCodeLandingPageData?.noCode?.show && (
              <div
                className={`${styles.services} d-flex flex-column align-items-center text-center`}
              >
                <p className={`${styles.tag}`}>
                  {!!NoCodeLandingPageData?.noCode?.wTag &&
                    NoCodeLandingPageData?.noCode?.wTag}
                </p>
                <h3 className={`${styles.header}`}>
                  {!!NoCodeLandingPageData?.noCode?.wHeader &&
                    NoCodeLandingPageData?.noCode?.wHeader}
                </h3>
                <p className={`${styles.text}`}>
                  {!!NoCodeLandingPageData?.noCode?.wAbout &&
                    NoCodeLandingPageData?.noCode?.wAbout}
                </p>
                <div className={`${styles.container} w-100 row`}>
                  {!!NoCodeLandingPageData?.noCode?.wList &&
                    NoCodeLandingPageData?.noCode?.wList?.map(
                      (value, index) => (
                        <div
                          key={index}
                          className={`col-md-3 col-6 ${styles.card} d-flex flex-column align-items-center`}
                        >
                          <img
                            loading="lazy"
                            src={
                              !!value?.icon
                                ? process.env.REACT_APP_CLOUDFLARE_URL +
                                  value?.icon
                                : ""
                            }
                            className={`${styles.icon}`}
                            alt="icon"
                          />
                          <h5 className={`${styles.title}`}>{value?.text}</h5>
                          <p className={`${styles.content}`}>{value?.status}</p>
                        </div>
                      )
                    )}
                </div>
              </div>
            )}
          </>
        );

      case "technology":
        return (
          <>
            {!!NoCodeLandingPageData?.technology?.show && (
              <div className={`container-fluid ${styles.platform}`}>
                <div
                  className={`${styles.body} d-flex flex-column align-items-center`}
                >
                  <p className={`${styles.tag}`}>
                    {NoCodeLandingPageData?.technology?.tag}
                  </p>
                  <h1 className={`${styles.header}`}>
                    {NoCodeLandingPageData?.technology?.header}
                  </h1>
                  <div
                    className={`row ${styles.container} mx-0 align-items-center justify-content-center`}
                  >
                    {!!NoCodeLandingPageData?.technology?.list &&
                      NoCodeLandingPageData?.technology?.list?.map(
                        (value, index) => (
                          <div
                            className={`col-md-2 col-4 d-flex justify-content-center`}
                            key={index}
                          >
                            <img
                              loading="lazy"
                              src={
                                !!value?.icon
                                  ? process.env.REACT_APP_CLOUDFLARE_URL +
                                    value?.icon
                                  : ""
                              }
                              alt="platform"
                            />
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            )}
          </>
        );

      case "our_services":
        return (
          <>
            {!!NoCodeLandingPageData?.our_services?.show && (
              <div
                className={`container-fluid ${styles.noCode} d-flex flex-column align-items-center`}
              >
                <h1 className={`${styles.header}`}>
                  {!!NoCodeLandingPageData?.our_services?.header &&
                    NoCodeLandingPageData?.our_services?.header}
                </h1>
                <div className={`row w-100 justify-content-center mx-0`}>
                  {!!NoCodeLandingPageData?.our_services?.services &&
                    NoCodeLandingPageData?.our_services?.services?.map(
                      (value, index) => (
                        <div
                          key={index}
                          className={`col-md-4 col-6 ${styles.card}`}
                        >
                          <div className={`${styles.box} h-100`}>
                            <img
                              loading="lazy"
                              src={
                                value?.icon
                                  ? process.env.REACT_APP_CLOUDFLARE_URL +
                                    value?.icon
                                  : ""
                              }
                              alt="icon"
                            />
                            <h5 className={`${styles.title}`}>
                              {value?.title}
                            </h5>
                            <p className={`${styles.content}`}>{value?.text}</p>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
            )}
          </>
        );

      case "faq":
        return (
          <>
            {!!NoCodeLandingPageData?.faq?.show && (
              <FAQs faqData={NoCodeLandingPageData?.faq} />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!NoCodeLandingPageData?.portfolio?.show && (
              <Portfolio portfolioData={NoCodeLandingPageData?.portfolio} />
            )}
          </>
        );

      case "testimonials":
        return (
          <>
            {!!NoCodeLandingPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={NoCodeLandingPageData?.testimonials}
                design={{
                  background: "#53389E",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#E9D7FE",
                  brand: "#FFFFFF",
                }}
              />
            )}
          </>
        );

      case "subscription":
        return (
          <>
            {!!NoCodeLandingPageData?.subscription?.show && (
              <Price subscription={NoCodeLandingPageData?.subscription} />
            )}
          </>
        );

      case "contact":
        return (
          <>
            {!!NoCodeLandingPageData?.contact?.show && (
              <Contact data={NoCodeLandingPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>
              No-Code Development Services | Design, CMS, E-Commerce, Axure
              Prototyping
            </title>
            <meta
              name="description"
              content="Startem offers cost-effective and speedy no-code development services for websites, apps, and software. Our team utilizes no-code platforms like Buzzy and WordPress to create slim, sleek, and visually stunning designs that are easy to manage. Choose from our range of services including design, CMS, e-commerce, and Axure prototyping to get started today."
            />
            <link rel="canonical" href="https://www.startem.io/no-code" />
          </Helmet>

          {!!NoCodeLandingPageData?.sequence &&
            NoCodeLandingPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*

          {!!NoCodeLandingPageData?.noCode?.show && (
            <div
              className={`container-fluid px-0 ${styles.intro} d-flex flex-column align-items-center text-center`}
            >
              <p className={`${styles.tag}`}>
                {!!NoCodeLandingPageData?.noCode?.tag &&
                  NoCodeLandingPageData?.noCode?.tag}
              </p>
              <h1 className={`${styles.header}`}>
                {!!NoCodeLandingPageData?.noCode?.header &&
                  NoCodeLandingPageData?.noCode?.header}
              </h1>
              <p className={`${styles.text}`}>
                {!!NoCodeLandingPageData?.noCode?.about &&
                  NoCodeLandingPageData?.noCode?.about}
              </p>
              <button className={`${styles.btn} btn`}>
                {!!NoCodeLandingPageData?.noCode?.button &&
                  NoCodeLandingPageData?.noCode?.button}
              </button>

              <div className={`${styles.box} w-100 position-relative`}>
                <img
                  loading="lazy"
                  src={require("../../assets/images/services/no-code/landing/bg-pattern.png")}
                  alt="bg"
                  className={`${styles.img1} w-100 position-absolute`}
                />
                <img
                  loading="lazy"
                  src={require("../../assets/images/services/no-code/landing/screen.png")}
                  alt="img"
                  className={`${styles.img2}`}
                />
              </div>
            </div>
          )}

          {!!NoCodeLandingPageData?.noCode?.show && (
            <div
              className={`${styles.services} d-flex flex-column align-items-center text-center`}
            >
              <p className={`${styles.tag}`}>
                {!!NoCodeLandingPageData?.noCode?.wTag &&
                  NoCodeLandingPageData?.noCode?.wTag}
              </p>
              <h3 className={`${styles.header}`}>
                {!!NoCodeLandingPageData?.noCode?.wHeader &&
                  NoCodeLandingPageData?.noCode?.wHeader}
              </h3>
              <p className={`${styles.text}`}>
                {!!NoCodeLandingPageData?.noCode?.wAbout &&
                  NoCodeLandingPageData?.noCode?.wAbout}
              </p>
              <div className={`${styles.container} w-100 row`}>
                {!!NoCodeLandingPageData?.noCode?.wList &&
                  NoCodeLandingPageData?.noCode?.wList?.map((value, index) => (
                    <div
                      key={index}
                      className={`col-md-3 col-6 ${styles.card} d-flex flex-column align-items-center`}
                    >
                      <img
                        loading="lazy"
                        src={
                          !!value?.icon
                            ? process.env.REACT_APP_CLOUDFLARE_URL + value?.icon
                            : ""
                        }
                        className={`${styles.icon}`}
                        alt="icon"
                      />
                      <h5 className={`${styles.title}`}>{value?.text}</h5>
                      <p className={`${styles.content}`}>{value?.status}</p>
                    </div>
                  ))}
              </div>
            </div>
          )}

          {!!NoCodeLandingPageData?.technology?.show && (
            <div className={`container-fluid ${styles.platform}`}>
              <div
                className={`${styles.body} d-flex flex-column align-items-center`}
              >
                <p className={`${styles.tag}`}>
                  {NoCodeLandingPageData?.technology?.tag}
                </p>
                <h1 className={`${styles.header}`}>
                  {NoCodeLandingPageData?.technology?.header}
                </h1>
                <div
                  className={`row ${styles.container} mx-0 align-items-center justify-content-center`}
                >
                  {!!NoCodeLandingPageData?.technology?.list &&
                    NoCodeLandingPageData?.technology?.list?.map(
                      (value, index) => (
                        <div
                          className={`col-md-2 col-4 d-flex justify-content-center`}
                          key={index}
                        >
                          <img
                            loading="lazy"
                            src={
                              !!value?.icon
                                ? process.env.REACT_APP_CLOUDFLARE_URL +
                                  value?.icon
                                : ""
                            }
                            alt="platform"
                          />
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          )}

          {!!NoCodeLandingPageData?.our_services?.show && (
            <div
              className={`container-fluid ${styles.noCode} d-flex flex-column align-items-center`}
            >
              <h1 className={`${styles.header}`}>
                {!!NoCodeLandingPageData?.our_services?.header &&
                  NoCodeLandingPageData?.our_services?.header}
              </h1>
              <div className={`row w-100 justify-content-center mx-0`}>
                {!!NoCodeLandingPageData?.our_services?.services &&
                  NoCodeLandingPageData?.our_services?.services?.map(
                    (value, index) => (
                      <div
                        key={index}
                        className={`col-md-4 col-6 ${styles.card}`}
                      >
                        <div className={`${styles.box} h-100`}>
                          <img
                            loading="lazy"
                            src={
                              value?.icon
                                ? process.env.REACT_APP_CLOUDFLARE_URL +
                                  value?.icon
                                : ""
                            }
                            alt="icon"
                          />
                          <h5 className={`${styles.title}`}>{value?.title}</h5>
                          <p className={`${styles.content}`}>{value?.text}</p>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          )}

          {!!NoCodeLandingPageData?.faq?.show && (
            <FAQs faqData={NoCodeLandingPageData?.faq} />
          )}

          {!!NoCodeLandingPageData?.portfolio?.show && (
            <Portfolio portfolioData={NoCodeLandingPageData?.portfolio} />
          )}

          {!!NoCodeLandingPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={NoCodeLandingPageData?.testimonials}
              design={{
                background: "#53389E",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#E9D7FE",
                brand: "#FFFFFF",
              }}
            />
          )}

          {!!NoCodeLandingPageData?.subscription?.show && (
            <Price subscription={NoCodeLandingPageData?.subscription} />
          )}

          {!!NoCodeLandingPageData?.contact?.show && (
            <Contact data={NoCodeLandingPageData?.contact} />
          )}
        */}
        </>
      )}
    </>
  );
}

export default NoCodeLanding;
