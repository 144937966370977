import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../extra/loader/Loader";
import Contact from "../../modules/contact/Contact";
import Portfolio from "../../modules/portfolio/Portfolio";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import { AboutUsData } from "../../store/actions/About Us/AboutUsData";
import Page404 from "../placeholders/404Page";
import styles from "./aboutUs.module.scss";

function AboutUs() {
  const { isLoading, AboutUsPageData, errorMessage } = useSelector(
    (state) => state?.AboutUsReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(AboutUsData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "about_us":
        return (
          !!AboutUsPageData?.about_us?.show && (
            <div
              className={`container-fluid ${styles.about} d-flex flex-column align-items-center`}
            >
              <p className={styles.tag}>{AboutUsPageData?.about_us?.tag}</p>
              <div className={`w-100 row ${styles.top}`}>
                <div className="col-md-7 col-12 px-0">
                  <h2 className={styles.header}>
                    {AboutUsPageData?.about_us?.header}
                  </h2>
                </div>
                <div className="col-md-5 col-12 px-md-0">
                  <p className={styles.content}>
                    {AboutUsPageData?.about_us?.description}
                  </p>
                </div>
              </div>

              <div
                className={`w-100 row mx-0 position-relative ${styles.bottom}`}
              >
                <div
                  className={`w-100 position-absolute row mx-0 ${styles.imgContainer}`}
                >
                  {!!AboutUsPageData?.about_us?.imagesList &&
                    AboutUsPageData?.about_us?.imagesList?.map(
                      (value, index) => (
                        <div
                          key={index}
                          className="col-3"
                          style={{
                            paddingLeft: "2vw",
                            paddingRight: "2vw",
                            paddingTop: index % 2 === 1 ? "5vw" : "",
                            paddingBottom: index % 2 === 0 ? "5vw" : "",
                          }}
                        >
                          <img
                            loading="lazy"
                            className="h-100 w-100"
                            src={
                              !!value?.image
                                ? process.env.REACT_APP_CLOUDFLARE_URL +
                                  value?.image
                                : ""
                            }
                            alt="img"
                          />
                        </div>
                      )
                    )}
                </div>
                <div
                  className={`col-5 ${styles.leftCol} position-relative d-flex flex-column align-items-center`}
                >
                  <img
                    loading="lazy"
                    className={`position-absolute ${styles.line}`}
                    src={require("../../assets/images/services/about-us/line.png")}
                    alt="line"
                  />

                  <img
                    loading="lazy"
                    style={{ width: "80%" }}
                    src={
                      !!AboutUsPageData?.about_us?.image3
                        ? process.env.REACT_APP_CLOUDFLARE_URL +
                          AboutUsPageData?.about_us?.image3
                        : ""
                    }
                    alt="img"
                  />
                </div>
                <div className={`col-7`}>
                  <div
                    className={`${styles.rightCol} w-100 d-flex flex-column `}
                  >
                    <p className={styles.tag}>
                      {AboutUsPageData?.about_us?.sdTag}
                    </p>
                    <h3 className={styles.header}>
                      {AboutUsPageData?.about_us?.sdHeader}
                    </h3>
                    {!!AboutUsPageData?.about_us?.sdList &&
                      AboutUsPageData?.about_us?.sdList?.map((value, index) => (
                        <p key={index} className={styles.text}>
                          {value?.text}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )
        );

      case "description":
        return (
          <>
            <div style={{ marginTop: "10vw" }}></div>

            {!!AboutUsPageData?.description?.show && (
              <div className={`container-fluid ${styles.modus}`}>
                <div className="position-relative">
                  <div
                    className={`w-100 position-absolute ${styles.top} d-flex flex-column align-items-center text-center`}
                  >
                    <p className={styles.tag}>
                      {AboutUsPageData?.description?.tag}
                    </p>
                    <h1 className={styles.header}>
                      {AboutUsPageData?.description?.header}
                    </h1>
                    <p className={styles.text}>
                      {AboutUsPageData?.description?.text1}
                    </p>
                    <p className={styles.text}>
                      {AboutUsPageData?.description?.text2}
                    </p>
                  </div>
                  <div
                    className={`w-100 d-flex justify-content-center ${styles.bottom}`}
                  >
                    <img
                      loading="lazy"
                      src={
                        !!AboutUsPageData?.description?.image
                          ? process.env.REACT_APP_CLOUDFLARE_URL +
                            AboutUsPageData?.description?.image
                          : ""
                      }
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!AboutUsPageData?.portfolio?.show && (
              <Portfolio portfolioData={AboutUsPageData?.portfolio} />
            )}
            <div style={{ marginTop: "4vw" }}></div>
          </>
        );

      case "testimonials":
        return (
          <>
            {!!AboutUsPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={AboutUsPageData?.testimonials}
                design={{
                  background: "#53389E",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#E9D7FE",
                  brand: "#FFFFFF",
                }}
              />
            )}
          </>
        );

      case "contact":
        return (
          <>
            <div style={{ marginTop: "7vw" }}></div>
            {!!AboutUsPageData?.contact?.show && (
              <Contact data={AboutUsPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>
              Startem - Canada's Digital Transformation Agency | Turning Ideas
              into Reality
            </title>
            <meta
              name="description"
              content="Startem bridges the gap between creativity and innovation by providing cutting-edge solutions for businesses at every level of their digital evolution journey. Our Agile Methodology, PLG approach, and Startup launch ideals make us stand out from our competitors. Let us turn your ideas into reality with our SEO, web building, and content marketing services."
            />
            <link rel="canonical" href="https://www.startem.io/about-us" />
          </Helmet>

          {!!AboutUsPageData?.sequence &&
            AboutUsPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*

          {!!AboutUsPageData?.about_us?.show && (
            <div
              className={`container-fluid ${styles.about} d-flex flex-column align-items-center`}
            >
              <p className={styles.tag}>{AboutUsPageData?.about_us?.tag}</p>
              <div className={`w-100 row ${styles.top}`}>
                <div className="col-md-7 col-12 px-0">
                  <h2 className={styles.header}>
                    {AboutUsPageData?.about_us?.header}
                  </h2>
                </div>
                <div className="col-md-5 col-12 px-md-0">
                  <p className={styles.content}>
                    {AboutUsPageData?.about_us?.description}
                  </p>
                </div>
              </div>

              <div
                className={`w-100 row mx-0 position-relative ${styles.bottom}`}
              >
                <div
                  className={`w-100 position-absolute row mx-0 ${styles.imgContainer}`}
                >
                  {!!AboutUsPageData?.about_us?.imagesList &&
                    AboutUsPageData?.about_us?.imagesList?.map(
                      (value, index) => (
                        <div
                          key={index}
                          className="col-3"
                          style={{
                            paddingLeft: "2vw",
                            paddingRight: "2vw",
                            paddingTop: index % 2 === 1 ? "5vw" : "",
                            paddingBottom: index % 2 === 0 ? "5vw" : "",
                          }}
                        >
                          <img
                            loading="lazy"
                            className="h-100 w-100"
                            src={
                              !!value?.image
                                ? process.env.REACT_APP_CLOUDFLARE_URL +
                                  value?.image
                                : ""
                            }
                            alt="img"
                          />
                        </div>
                      )
                    )}
                </div>
                <div
                  className={`col-5 ${styles.leftCol} position-relative d-flex flex-column align-items-center`}
                >
                  <img
                    loading="lazy"
                    className={`position-absolute ${styles.line}`}
                    src={require("../../assets/images/services/about-us/line.png")}
                    alt="line"
                  />

                  <img
                    loading="lazy"
                    style={{ width: "80%" }}
                    src={
                      !!AboutUsPageData?.about_us?.image3
                        ? process.env.REACT_APP_CLOUDFLARE_URL +
                          AboutUsPageData?.about_us?.image3
                        : ""
                    }
                    alt="img"
                  />
                </div>
                <div className={`col-7`}>
                  <div
                    className={`${styles.rightCol} w-100 d-flex flex-column `}
                  >
                    <p className={styles.tag}>
                      {AboutUsPageData?.about_us?.sdTag}
                    </p>
                    <h3 className={styles.header}>
                      {AboutUsPageData?.about_us?.sdHeader}
                    </h3>
                    {!!AboutUsPageData?.about_us?.sdList &&
                      AboutUsPageData?.about_us?.sdList?.map((value, index) => (
                        <p key={index} className={styles.text}>
                          {value?.text}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div style={{ marginTop: "10vw" }}></div>

          {!!AboutUsPageData?.description?.show && (
            <div className={`container-fluid ${styles.modus}`}>
              <div className="position-relative">
                <div
                  className={`w-100 position-absolute ${styles.top} d-flex flex-column align-items-center text-center`}
                >
                  <p className={styles.tag}>
                    {AboutUsPageData?.description?.tag}
                  </p>
                  <h1 className={styles.header}>
                    {AboutUsPageData?.description?.header}
                  </h1>
                  <p className={styles.text}>
                    {AboutUsPageData?.description?.text1}
                  </p>
                  <p className={styles.text}>
                    {AboutUsPageData?.description?.text2}
                  </p>
                </div>
                <div
                  className={`w-100 d-flex justify-content-center ${styles.bottom}`}
                >
                  <img
                    loading="lazy"
                    src={
                      !!AboutUsPageData?.description?.image
                        ? process.env.REACT_APP_CLOUDFLARE_URL +
                          AboutUsPageData?.description?.image
                        : ""
                    }
                    alt="img"
                  />
                </div>
              </div>
            </div>
          )}

          {!!AboutUsPageData?.portfolio?.show && (
            <Portfolio portfolioData={AboutUsPageData?.portfolio} />
          )}
          <div style={{ marginTop: "4vw" }}></div>

          {!!AboutUsPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={AboutUsPageData?.testimonials}
              design={{
                background: "#53389E",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#E9D7FE",
                brand: "#FFFFFF",
              }}
            />
          )}

          <div style={{ marginTop: "7vw" }}></div>
          {!!AboutUsPageData?.contact?.show && (
            <Contact data={AboutUsPageData?.contact} />
          )}
          */}
        </>
      )}
    </>
  );
}

export default AboutUs;
