import {
  SINGLE_BLOG_LOADER,
  SINGLE_BLOG_SUCCESS,
  SINGLE_BLOG_FAILURE,
} from "../../constants/Blog/SingleBlogConstants";
import { getById } from "../../../API/Api";

const success = (payload) => ({
  type: SINGLE_BLOG_SUCCESS,
  payload,
});

const failure = (error) => ({
  type: SINGLE_BLOG_FAILURE,
  error,
});

export const SingleBlogData = (id) => (dispatch) => {
  dispatch({ type: SINGLE_BLOG_LOADER });
  getById("startem_blog", id)
    .then((response) => {
      dispatch(success(response?.data[0]));
    })
    .catch((err) => {
      dispatch(failure(err?.errors));
    });
};
