import React, { useEffect } from "react";
import Connect from "./ConnectA";
import styles from "./technology.module.scss";

function Technology({ technology, width, containerClass, bottomBar }) {
  const bodyClass = (containerClass) => {
    switch (containerClass) {
      case "body1":
        return styles.body1;
      case "body2":
        return styles.body2;
      case "body3":
        return styles.body3;
      default:
        return "";
    }
  };

  return (
    <div
      className={`container-fluid mt-5 pb-5 ${bodyClass(
        containerClass
      )} px-0 d-flex flex-column align-items-center`}
    >
      <div
        className={`w-100 d-flex flex-column align-items-center justify-content-center mb-5 ${styles.top}`}
      >
        <div className="d-flex flex-column align-items-center text-center">
          <p className={`border ${styles.processTag}`}>
            {!!technology?.tag && technology?.tag}
          </p>

          {!!technology?.header && (
            <h2 className={styles.heading}>{technology?.header}</h2>
          )}
        </div>
        <div
          className={`${
            !!width ? "" : "w-100"
          } mt-4 row mx-0 align-items-center justify-content-center ${
            styles.cardBox
          }`}
        >
          {!!technology?.list &&
            technology?.list?.map((value, index) => (
              <div
                key={index}
                className={`${styles.card} col-md col-3 d-flex flex-column align-items-center text-center`}
              >
                {!!value && (
                  <img
                    loading="lazy"
                    alt="img"
                    src={
                      !!value?.icon
                        ? process.env.REACT_APP_CLOUDFLARE_URL + value?.icon
                        : ""
                    }
                  />
                )}
              </div>
            ))}
        </div>
      </div>
      {!!bottomBar && (
        <Connect
          bottomBar={bottomBar}
          ourServices={{
            heading: technology?.heading,
            text: technology?.text,
            button: technology?.button,
          }}
        />
      )}
    </div>
  );
}

export default Technology;
