import React from "react";
import styles from "./contactA.module.scss";

function ContactA({ Contact, reverse }) {
  return (
    <div
      className={`${styles.body} d-flex ${
        !!reverse ? "flex-row-reverse" : "flex-row"
      }`}
    >
      <div className={`${styles.leftCol}`}>
        <h1 className={styles.header}>
          {!!Contact?.header && Contact?.header}
        </h1>
        <div
          className={`${styles.formBox} d-flex flex-row justify-content-between`}
        >
          <div className="form-group" style={{ width: "47%" }}>
            <label>{!!Contact?.first_name && Contact?.first_name}</label>
            <input
              type="text"
              className="form-control"
              id="fname"
              placeholder={!!Contact?.first_name ? Contact?.first_name : ""}
            />
          </div>
          <div className="form-group" style={{ width: "47%" }}>
            <label>{!!Contact?.last_name && Contact?.last_name}</label>
            <input
              type="text"
              className="form-control"
              id="lname"
              placeholder={!!Contact?.last_name ? Contact?.last_name : ""}
            />
          </div>
        </div>
        <div className={`${styles.formBox} form-group`}>
          <label>{!!Contact?.email && Contact?.email}</label>
          <input
            type="text"
            className="form-control"
            placeholder="you@startem.com"
          />
        </div>
        <div className={`${styles.formBox} form-group`}>
          <label>{!!Contact?.phone && Contact?.phone}</label>
          <input
            type="tel"
            className="form-control"
            id="number"
            placeholder="+1 (555) 000-0000"
          />
        </div>
        <div className={`${styles.formBox} form-group`}>
          <label>{!!Contact?.message && Contact?.message.split("|")[0]}</label>
          <textarea
            type="text"
            className="form-control"
            id="msg"
            placeholder={
              !!Contact?.message ? Contact?.message.split("|")[1] : ""
            }
            rows={4}
          />
        </div>
        <button className={`btn w-100 ${styles.btn}`}>
          {!!Contact?.submit && Contact?.submit}
        </button>
      </div>
      <div className={`${styles.rightCol} position-relative`}>
        {!!Contact?.tag && (
          <div
            className={`position-absolute w-100 d-flex justify-content-center align-items-center text-center ${styles.imageTop}`}
          >
            <h1 className={styles.text}>{!!Contact?.tag && Contact?.tag}</h1>
          </div>
        )}
        <img
          loading="lazy"
          src={
            !!Contact?.image
              ? process.env.REACT_APP_CLOUDFLARE_URL + Contact?.image
              : ""
          }
          className="w-100 h-100"
          alt="img"
        />
      </div>
    </div>
  );
}

export default ContactA;
