import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../extra/loader/Loader";
import Contact from "../../modules/contact/Contact";
import FAQs from "../../modules/faq/FAQs";
import IdeasA from "../../modules/ideas/IdeasA";
import Portfolio from "../../modules/portfolio/Portfolio";
import ProcessB from "../../modules/process/ProcessB";
import Technology from "../../modules/services/Technology";
import TestimonialsB from "../../modules/testimonials/TestimonialsB";
import WhyStartemA from "../../modules/why startem/WhyStartemA";
import { SdServicesData } from "../../store/actions/Services/SDServicesData";
import Page404 from "../placeholders/404Page";

function SoftwareDev() {
  const { isLoading, SdServicesPageData, errorMessage } = useSelector(
    (state) => state?.SdServicesReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(SdServicesData());
  }, [dispatch]);

  const RenderSequence = (key) => {
    switch (key) {
      case "intro":
        return (
          <>
            {!!SdServicesPageData?.intro?.show && (
              <IdeasA
                Intro={SdServicesPageData?.intro}
                imageContainer={{
                  position: "left",
                }}
                bottomBar={{ structure: 1, design: "bottomBar1" }}
                btmImg
              />
            )}
          </>
        );

      case "why_startem":
        return (
          <>
            {!!SdServicesPageData?.why_startem?.show && (
              <WhyStartemA
                WhyStartem={SdServicesPageData?.why_startem}
                imageContainer={{
                  position: "left",
                }}
              />
            )}
          </>
        );

      case "technology":
        return (
          <>
            {!!SdServicesPageData?.technology?.show && (
              <Technology
                technology={SdServicesPageData?.technology}
                containerClass={"body1"}
                bottomBar={{ structure: 2, design: "bottomBar7" }}
              />
            )}
          </>
        );

      case "process":
        return (
          <>
            {!!SdServicesPageData?.process?.show && (
              <ProcessB
                process={SdServicesPageData?.process}
                bottomBar={{
                  structure: 1,
                  design: "bottomBar1",
                }}
              />
            )}
          </>
        );

      case "portfolio":
        return (
          <>
            {!!SdServicesPageData?.portfolio?.show && (
              <Portfolio portfolioData={SdServicesPageData?.portfolio} />
            )}
          </>
        );

      case "testimonials":
        return (
          <>
            {!!SdServicesPageData?.testimonials?.show && (
              <TestimonialsB
                Testimonials={SdServicesPageData?.testimonials}
                design={{
                  background: "#1D2939",
                  tag: "#FFFFFF",
                  border: "1.5px solid #FFFFFF",
                  header: "#FFFFFF",
                  name: "#FFFFFF",
                  designation: "#96A2B2",
                  brand: "#FFFFFF",
                }}
              />
            )}
          </>
        );

      case "faq":
        return (
          <>
            {!!SdServicesPageData?.faq?.show && (
              <FAQs faqData={SdServicesPageData?.faq} />
            )}
          </>
        );

      case "contact":
        return (
          <>
            {!!SdServicesPageData?.contact?.show && (
              <Contact data={SdServicesPageData?.contact} />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {!!isLoading ? (
        <Loader />
      ) : !!errorMessage?.length ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>Custom Software Development Services - Startem</title>
            <meta
              name="description"
              content="Looking for top-notch custom software development services? Startem employs clean code and market-leading frameworks to create blazingly fast and pixel-perfect products across all screen sizes and gadgets. Contact us now to turn your ideas into reality."
            />
            <link
              rel="canonical"
              href="https://www.startem.io/software-services"
            />
          </Helmet>

          {!!SdServicesPageData?.sequence &&
            SdServicesPageData?.sequence?.map((value, index) => (
              <div key={index} className="w-100">
                {RenderSequence(value?.key)}
              </div>
            ))}

          {/*
          {!!SdServicesPageData?.intro?.show && (
            <IdeasA
              Intro={SdServicesPageData?.intro}
              imageContainer={{
                position: "left",
                width: "90%",
              }}
              bottomBar={{ structure: 1, design: "bottomBar7" }}
            />
          )}

          {!!SdServicesPageData?.why_startem?.show && (
            <WhyStartemA
              WhyStartem={SdServicesPageData?.why_startem}
              imageContainer={{
                position: "left",
              }}
            />
          )}

          {!!SdServicesPageData?.technology?.show && (
            <Technology
              technology={SdServicesPageData?.technology}
              containerClass={"body2"}
              bottomBar={{ structure: 2, design: "bottomBar8" }}
            />
          )}

          {!!SdServicesPageData?.process?.show && (
            <ProcessB
              process={SdServicesPageData?.process}
              bottomBar={{ structure: 2, design: "bottomBar1" }}
            />
          )}

          {!!SdServicesPageData?.portfolio?.show && (
            <Portfolio portfolioData={SdServicesPageData?.portfolio} />
          )}

          {!!SdServicesPageData?.testimonials?.show && (
            <TestimonialsB
              Testimonials={SdServicesPageData?.testimonials}
              design={{
                background: "#101828",
                tag: "#FFFFFF",
                border: "1.5px solid #FFFFFF",
                header: "#FFFFFF",
                name: "#FFFFFF",
                designation: "#C0C5D0",
                brand: "#FFFFFF",
              }}
            />
          )}

          {!!SdServicesPageData?.faq?.show && (
            <FAQs faqData={SdServicesPageData?.faq} />
          )}

          {!!SdServicesPageData?.contact?.show && (
            <Contact data={SdServicesPageData?.contact} />
          )}
        */}
        </>
      )}
    </>
  );
}

export default SoftwareDev;
