import React from "react";
import styles from "./contact.module.scss";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton, InputGroup, Form } from "react-bootstrap";

function Contact({ data }) {
  return (
    <div
      className={`${styles.body} container-fluid d-flex flex-column justify-content-center align-items-center`}
    >
      <p className={`${styles.contactTag} border text-center`}>
        {!!data?.tag && data?.tag}
      </p>
      <h2 className={styles.header}>{!!data?.header && data?.header}</h2>
      <p className={styles.text}>{!!data?.description && data?.description}</p>
      <img
        loading="lazy"
        className={`w-100 h-100 ${styles.img}`}
        src={`${
          !!data?.image
            ? process.env.REACT_APP_CLOUDFLARE_URL + data?.image
            : ""
        }`}
        alt="img"
      />
      <div className="row w-100">
        {!!data?.connections &&
          data?.connections?.map((value, index) => (
            <div
              key={index}
              className={`col-12 col-md d-flex flex-column ${styles.box} ${
                index === 0 ? "pl-0" : ""
              } ${index === 2 ? "pr-0" : ""}`}
            >
              <div
                className={`${styles.featuredBg} h-100 w-100 d-flex flex-row`}
              >
                <div>
                  <img
                    loading="lazy"
                    src={
                      !!value?.icon
                        ? process.env.REACT_APP_CLOUDFLARE_URL + value?.icon
                        : ""
                    }
                    alt="featured icon"
                  />
                </div>
                <div className={`${styles.card} w-100`}>
                  <p className={`mb-1 ${styles.text1}`}>{value?.header}</p>
                  <p className={styles.text2}>{value?.text}</p>
                  <p className={styles.text3}>{value?.link}</p>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className={`row w-100 mt-4 px-0`}>
        <div className={`form-group col-md-2 pl-0 ${styles.removePadding}`}>
          <label className={styles.labelText}>
            {!!data?.first_name && data?.first_name}
          </label>
          <input
            type="email"
            className="form-control"
            id="exampleInputFname"
            aria-describedby="emailHelp"
            placeholder={!!data?.first_name && data?.first_name}
          />
        </div>

        <div className={`form-group col-md-2 ${styles.removePadding}`}>
          <label className={styles.labelText}>
            {!!data?.last_name && data?.last_name}
          </label>
          <input
            type="email"
            className="form-control"
            id="exampleInputLname"
            aria-describedby="emailHelp"
            placeholder={!!data?.last_name && data?.last_name}
          />
        </div>

        <div className={`form-group w-100 col-md ${styles.removePadding}`}>
          <label className={styles.labelText}>
            {!!data?.email && data?.email}
          </label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="you@company.com"
          />
        </div>

        <div className={`form-group col-md pr-0 ${styles.removePadding}`}>
          <label className={styles.labelText}>
            {!!data?.phone && data?.phone}
          </label>
          <InputGroup className={`mb-3 ${styles.inputGroup}`}>
            <DropdownButton
              variant="outline-secondary"
              title="US"
              id="input-group-dropdown-1"
            >
              <Dropdown.Item>PKR</Dropdown.Item>
              <Dropdown.Item>US</Dropdown.Item>
            </DropdownButton>
            <Form.Control
              aria-label="Text input with dropdown button"
              placeholder="+1 (555) 000-0000"
            />
          </InputGroup>
        </div>

        <div className="form-group col-12 px-0">
          <label className={styles.labelText}>
            {!!data?.message && data?.message?.split("|")[0]}
          </label>
          <textarea
            className={`form-control ${styles.textArea}`}
            id="exampleFormControlTextarea1"
            rows="4"
            placeholder={!!data?.message && data?.message?.split("|")[1]}
          ></textarea>
        </div>

        <div className="col-md-4 px-0">
          <div
            className={`form-check px-0 d-flex align-items-center ${styles.checkBoxGroup}`}
          >
            <input
              type="checkbox"
              id="exampleCheck1"
              className={`${styles.checkBoxIcon}`}
              aria-label="privacy and policy"
            />
            <label className={`form-check-label ${styles.checkBox}`}>
              {!!data?.privacy_policy && (
                <>
                  {data?.privacy_policy?.split("|")[0] + " "}
                  <Link
                    className={`${styles.checkBox} ${styles.underline}`}
                    to=""
                  >
                    {data?.privacy_policy?.split("|")[1]}
                  </Link>
                  .
                </>
              )}
            </label>
          </div>
          <button
            type="submit"
            className={`btn mt-3 p-0 ${styles.button} text-uppercase`}
          >
            {!!data?.submit && data?.submit}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Contact;
